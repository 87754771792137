// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import Publisheraddpreviewpage from '../Components/Publisheraddpreviewpage';

class Bookaddpreviewsection extends Actions{

    componentDidMount(){
        
        window.scrollTo(0, 0);
     }  
    render(){
        const contextValue = {
              viewPublisherPreview:this.viewPublisherPreview,
             all_books:this.state.publisherAddPreview,
             editModepublisherpreview:this.editModepublisherpreview,
             previewpublisherUpdate:this.previewpublisherUpdate,
             coupenpublisherActivated:this.coupenpublisherActivated,
             cancelPublisherEdit:this.cancelPublisherEdit,

             
             pricefetch:this.fetchprice,
             all_allprices:this.state.allprices,
        }

        return(
            <Provider value={contextValue}>
            <div className="addbooksss gray-bg ">
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">
                                  <Publisheraddpreviewpage id={this.props.match.params.publisherId}  loadingsss ={this.state.isLoading} loadingedits ={this.state.isLoading} />
                          
                              
                             </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Bookaddpreviewsection;