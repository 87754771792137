import React, { Component } from 'react'

import {Redirect, Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";

import {toast} from "react-toastify";

import Loader from 'react-loader-spinner';
import Axios from 'axios';
let BaseURL = 'https://reactbooks.com/api/';

class Admintrailer extends Component {
  
  state = {
    books: null,
    total: null,
    per_page: null,
    current_page: 1,
    
    msg: 'Please Wait'
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
    this.storedata();         
    window.scrollTo(0, 0);
    }

    storedata() {
        let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
        let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
        if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
            this.setState({
                  username: userstoragename,
                  userid: userstorageid,
           })} else { this.setState({ username: '',userid: '', }) }
         
       }


  makeHttpRequestWithPage = async pageNumber => {
    const response = await fetch(BaseURL+`pendingtrailers.php?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    //console.log(res.data);
    if(res.success === 'success'){ 
      this.setState({
         books: res.data,
         total: res.total,
         per_page: res.per_page,
         current_page: res.page,
      });
    }
    else{
      this.setState({
      books: null,
      msg: 'No Pending Payment'
    });
  
    }

  }

  publishsss = (idpublish) => {
    this.setState({ isLoading: true });
//console.log(idpublish);
return Axios.post(BaseURL+'adminapprovaltrailor.php',{
  idpublish:idpublish,
})
.then(function ({data}) {
      // console.log(data);
    if(data.success === 1){
           toast('Success');
           this.setState({ isLoading: false});
           this.makeHttpRequestWithPage(1);
       }
       else{
           this.setState({ isLoading: false });
           toast.error(data.msg, {
               position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
             });
           }
}.bind(this))
.catch(function (error) {
  //console.log(error);
  this.setState({ isLoading: false });
});              
      }
  render() {
 
       let books, renderPageNumbers;
       if (this.state.books !== null) {
         books = this.state.books.map(book => (
          <div key={book.video_id}>
           <div className="col-md-4 ">
          
            
           <div className="videossspagsss "  >
           {this.state.isLoading  ? 
                            <>
                                   <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                            </>
                         : 
         
           <button type="submit" onClick={() =>this.publishsss(book.video_id)} className="form-button" style={{float: 'right'}} > Publish id : {book.video_id} </button>
         
       }
                 <iframe id="ytplayer" type="text/html" className="videosss2"
                         src={`https://www.youtube.com/embed/`+book.video+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
                         <p> {book.title} </p>
                        
                        
                 </div>
                 </div>
           </div>
         ));
       }else{
        return (
          <div className="" style={{padding:'60px'}}>
          <div className="container">
             <div className="row">
             <div className="col-md-12">
          <div className="alert alert-light" role="alert">
          <h4 className="alert-heading"> {this.state.msg} </h4>
          <hr/>
          <ContentLoader  height={260} width={600} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                  <rect x="0" y="13" rx="4" ry="4" width="600" height="9" />
                  <rect x="0" y="29" rx="4" ry="4" width="200" height="8" />
                  <rect x="0" y="50" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="65" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="79" rx="4" ry="4" width="200" height="10" />
                  <rect x="0" y="99" rx="5" ry="5" width="600" height="300" />
              </ContentLoader>
             
      </div>
      </div>
      </div>
      </div>
      </div>
        )
       }
   
       const pageNumbers = [];
       if (this.state.total !== null) {
         for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
           pageNumbers.push(i);
         }
   
   
         renderPageNumbers = pageNumbers.map(number => {
           //console.log('current_page: '+this.state.page+' number : '+number);
           let classes = this.state.current_page != number ? '' : 'active';
   
           return (
             <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
           );
         });
       }
   
   
   
       return (
        <>
        <div className="pagsssqqq">
        <div className="container">
           <div className="row">

           <section className="breadcrumb-section">
    <h2 className="sr-only">Site Breadcrumb</h2>
    <div className="container">
      <div className="breadcrumb-contents">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
            <li className="breadcrumb-item active"> Pending Payment -  Book Trailers </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  </div>
  </div>
  </div>
   
          <div className="pagsss">
          <div className="container">
             <div className="row">
             <div className="col-md-12">
         <div className="section-title text-center mb-50 section-title--bordered">
                   <h2> Pending Payment -  Book Trailers </h2>
                 </div>
               
             {books}
             <div className="col-md-12">
         <div className="pagination">
           <span onClick={() => this.makeHttpRequestWithPage(1)}>&laquo;</span>
           {renderPageNumbers}
           <span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span>
         </div>
         </div>
         </div>
   
      
       </div>
       </div>
       </div>
       </>
     );
   }
   
   }

export default Admintrailer
