import React, { Component } from 'react'
import { EditorState,  convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
import {AppContext} from '../Context';


import Loader from 'react-loader-spinner'
class Bookaddpreviewpagetwo extends Component {
       
    static contextType = AppContext;

  constructor(props) {
    super(props)
    this.state = {
       file: null,
       showResults: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.desc)
        )
      ),
    }
    this.onChange = this.onChange.bind(this);
       this.resetFile = this.resetFile.bind(this);
       this.categoryMode = this.categoryMode.bind(this);
       this.packageselected = this.packageselected.bind(this);
  }

//image preview       
onChange(event) {
       this.setState({
             file: URL.createObjectURL(event.target.files[0])
       });
 }
 resetFile(event) {
        event.preventDefault();
        this.setState({ file: null });
      }
 
  //text editior
    onEditorStateChange = (editorState) => {
      this.setState({
        editorState
      });
    }
 
 //select option
 categoryMode(event) {
 //this.setState({value: event.target.value});
 if(event.target.value === 'other')
 { 
  //alert(event.target.value);
  this.setState({ showResults: true });
 
 }else{
  this.setState({ showResults: false });
 }
 
 } 
  
 componentDidMount(){
       this.setState({
         packagePlan: this.props.paymentbook,
     });

     this.context.pricefetch();
    
 }


 //radio button
 packageselected(event) {
   //alert(event.target.value);
   this.setState({
     packagePlan: event.target.value
   });
 }
 


  previewbookUpdate = (books_id) => {
        this.context.previewbookUpdate(books_id,this.props.useridsnnn,this.title.value,this.bookcover.files[0],this.description.value,this.price.value,this.purchaselink.value,this.category.value,this.descothers.value,this.websitelink.value,this.state.packagePlan);
   }

  render() {
       const { editorState } = this.state;
    return (
    
    <div className="col-md-12">
    <div className="col-md-12">
      
    <div className="qqqcentral-meta">
    <h5 style={{color: '#fd6500',marginBottom:'17px'}}> Edit Book </h5>
    <div className="central-meta3">

                    <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Title" required defaultValue={this.props.title}/>
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Book Cover Image  </label>
                     <input type="file" name="file" ref={(val) => this.bookcover = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                 </div>
                 {this.state.file && (
                   <div style={{ textAlign: "center" }}>
                        <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                       <button onClick={this.resetFile} class="removeqqq" >Remove File</button>
                   </div>
                   ) }
                    
                   <div className="form-group col-md-12 editor">
                   <label className="font-weight-bold"> Description <span className="adverts-form-required"> * </span> </label>
                       <Editor
                           editorState={editorState}
                           toolbarClassName="toolbarClassName"
                           wrapperClassName="wrapperClassName"
                           editorClassName="editorClassName"
                           onEditorStateChange={this.onEditorStateChange}
                           defaultEditorState={editorState}
                           
                       />
                      <textarea style={{display:'none'}}
     disabled ref={(val) => this.description = val}
     value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
   />
                   </div> 
 
               <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Price  </label>
                     <input type="text" name="price" ref={(val) => this.price = val} className="form-control" placeholder="Price" defaultValue={this.props.price} />
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Book Purchase Link  </label>
                     <input type="text" name="purchase" ref={(val) => this.purchaselink = val} className="form-control" placeholder="Book Purchase Link" defaultValue={this.props.purchaselink}/>
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Category  <span className="adverts-form-required"> * </span> </label>
                     <select  name="category" ref={(val) => this.category = val}  className="wqsss form-control" onClick={this.categoryMode} required defaultValue={this.props.category}>
                             
                             <option value=""> Choose the field </option>
                             <option>Fiction &amp; Literature</option>
                             <option>Romance</option>
                             <option>Science Fiction &amp; Fantasy</option>
                             <option>Comics &amp; Graphic Novels</option>
                             <option>Fiction - YA</option>
                             <option>Periodicals</option>
                             <option>Kids</option>
                             <option>Non-Fiction</option>
                             <option>Business &amp; Finance</option>
                             <option>Biography &amp; Memoir</option>
                             <option>Mystery &amp; Suspense</option>
                             <option value="other">Other</option>
                             
                           </select>
                           { this.state.showResults  ? 
                          <div>
                          <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} defaultValue={this.props.other} />
                          </div>
                           
                           : 
                           <div>
                          <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} style={{display:'none'}}/>
                          </div>
                          }
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Author Website  </label>
                     <input type="text" name="author" ref={(val) => this.websitelink = val} className="form-control" placeholder="Author Website" defaultValue={this.props.websitelink}/>
                 </div>     
                 <h3> Onetime Fee: Paypal / Credit Card Payment </h3>











                 {this.context.all_allprices.map((item, index) => ( 
                 <div className="col-md-12 gray-bg"  key={item.price_id}>
                    





                    <div className="col-md-6">

      
         
      <div className="radioqqq" >
      <h2>Best Package | Add Books, Book Trailers, and Twitter Feed.</h2>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">{item.package}</span>
                    <span className="duration"> 1 Year</span>
                  </div>
                  <span className="listing-duration"> Visible Permanent  </span>
                <span className="listing-duration3">
                <div className="col-md-12 boksqwssccc2">
		    <h1> 1) Book promotion through LinkedIn recommended group posts </h1>
		<b> What is LinkedIn recommended group post? </b>
		
		<p className="swsqsss">Group owners and managers can share valuable content with group members using recommended group posts. This is an opportunity to keep members informed about the latest conversations and important news in the community.</p>

		<b> Recommended posts on nine LinkedIn groups once in a month </b>
		<p className="swsqsss2">We will promote through the following LinkedIn groups - A total of <span className="colsss">2,65,000</span> group members will be notified about your book once in a month.</p>
		
      <ul>

		<li>i) Creative Designers and Writers(<span className="colsss">1,41,000</span>+ members will be notified) </li>
		<li>ii) Business Owners, Entrepreneurs &amp; Start-Ups(<span className="colsss">34,000</span>+  members will be notified) </li>
		<li>iii) Writers &amp; Authors(<span className="colsss">33,000</span>+  members will be notified) </li>
		<li>iv) Art Lovers &amp; Artistic Creations [Paintings | Artist] (<span className="colsss">11,000</span>+  members will be notified) </li>
		<li>v) Marketing &amp; Social Media for Writers, Entrepreneurs, and Businesses(<span className="colsss">14,000+ </span> members will be notified) </li>
		<li>vi) 3D Animation, VFX &amp; Games(<span className="colsss">11,000</span>+  members will be notified) </li>
		<li>vii) Designers - Web/Graphic Mobile App Development Wordpress (<span className="colsss">39,000</span>+  members will be notified) </li>
		<li>viii) International Business Consultants and Networkers(<span className="colsss">12,000</span>+  members will be notified) </li>
		<li>ix) Books, Authors, Writers, Publishers, Poets, Book Marketing, Book Promotion(3,000+  members will be notified) </li>
		
		</ul>
		
		 <h1> 2) Book promotion through Email marketing </h1>
		
		<p className="swsqsss2">We send emails to a list of over a million subscribers who were also book lovers.</p>
		
        <h1> 3) Book promotion through popular Facebook pages.</h1>
		
		<p className="swsqsss2">We will promote through the following Facebook Pages.</p>

      <ul>

		<li>i) Creative Designers and Writers(<span className="colsss">1,20,000</span>+ followers)</li>
		<li>ii) Crystalline Noble(5,000+ followers) </li>
		</ul>
		
		<h1> 4) Book promotion through Facebook group announcement </h1>
		
		<b> What is a facebook group announcement? </b>
		
		<p className="swsqsss">Facebook Group announcements are posts that appear at the top of your group in an announcements section.</p>

      
      <ul>

		<li>i) Authors, Artists, Book &amp; Art Promotion Designers and Writers(<span className="colsss">1,96,000</span>+ members)</li>
		<li>ii) World Artists, Paintings, Fine art, Water Color, Oil, Abstract Painting(<span className="colsss">65,000</span>+ members)   </li>
		<li>iii) Authors, Writers &amp; Bloggers(11,000+ members)    </li>
		
		</ul>
		
		   <h1> 5) Book promotion through Web Push Notification </h1>
		
		<b> What is Web Push Notification? </b>
		
		<p className="swsqsss">Web push notifications are notifications that can be sent to a user via desktop web and mobile web. ... Web push notifications are delivered on a user's desktop or mobile screen anytime they have their browser open — regardless of whether or not the user is on the website.</p>

      <ul>

		<li> i) Sending web push notification to over 10000 subscribers.</li>
		
		</ul>
		   
		   </div>






                </span>
      
          <input type="radio" className="radio" id="awesome-item-5"
          value="package"
          checked={this.state.packagePlan === "package"}
          onChange={this.packageselected}  />
        <label className="radio__label" htmlFor="awesome-item-5"> Active </label>
      </div>
           



     
          </div>











                    <div className="col-md-6" style={{padding:0}}>


                   
                    <div className="col-md-6" style={{padding:"0px 7px"}} >
        <div className="radioqqq">
                    <h2>Basic</h2>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.basic}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible one year  </span>
                   <span className="listing-duration">Listing</span>


         
            <input type="radio" className="radio" id="awesome-item-1"
            value="basic"
            checked={this.state.packagePlan === "basic"}
            onChange={this.packageselected}
             />
             <label className="radio__label" htmlFor="awesome-item-1"> Active </label>
          
        </div>
        </div>
      
        <div className="col-md-6" style={{padding:"0px 7px"}} >
          
        <div className="radioqqq">
        <h2>Standard</h2>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.standard}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible 2.5 years  </span>
                   <span className="listing-duration">Listing</span>
          
            <input type="radio" className="radio" id="awesome-item-2"
            value="standard"
            checked={this.state.packagePlan === "standard"}
            onChange={this.packageselected}
             />
            <label className="radio__label" htmlFor="awesome-item-2"> Active </label>
        </div>
        </div>
        
        
        <div className="col-md-6" style={{padding:"0px 7px"}}>
        <div className="radioqqq">
        <h2>Featured</h2>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.featured}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible 5 years </span>
                   <span className="listing-duration2">LinkedIn Group Recommendation, Facebook Page Posting, Tweets and Email Marketing</span>
         
            <input type="radio" className="radio" id="awesome-item-3"
            value="featured"
            name="featured"
            checked={this.state.packagePlan === "featured"}
            onChange={this.packageselected}  />
           <label className="radio__label" htmlFor="awesome-item-3"> Active </label>
        </div>
        </div>
         

                             
        <div className="col-md-6" style={{padding:"0px 7px"}} >
        <div className="radioqqq">
                    <h2>Recommended</h2>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.recommented}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible 10 years </span>
                   <span className="listing-duration2"> Listing, LinkedIn Group Recommendation(200000+ Subscribers), Facebook Page Posting(Page size 120000+ Followers), Tweets and Email Marketing (Audience size 500000+ Subscribers) </span>


         
            <input type="radio" className="radio" id="awesome-item-4"
            value="recommented"
            checked={this.state.packagePlan === "recommented"}
            onChange={this.packageselected}
             />
             <label className="radio__label" htmlFor="awesome-item-4"> Active </label>
          
        </div>
        </div>
       

                  </div>
                



             


                  
                  </div>


))}












                
          {this.props.loadingsssedits  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 
                 <div className="form-group col-sm-12 text-right">
                     <button type="submit" className="btn btn-primary2" onClick={() => this.previewbookUpdate(this.props.books_id)}> Continue </button>
                     <button onClick={() => this.context.cancelEdit(this.props.books_id)} className="btn btn-light canqqq">Cancel</button>
                 </div>

                }


    </div>
    </div>
    </div>
    </div>
    
    )
  }
}

export default Bookaddpreviewpagetwo
