// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import ViewBookedit from '../Components/ViewBookedit';

class Bookpreview extends Actions{
       componentDidMount(){
              this.storedata();
              window.scrollTo(0, 0);
            }  
            storedata() {
                let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
                let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
                if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
                    this.setState({
                          username: userstoragename,
                          userid: userstorageid,
                   })} else { this.setState({ username: '',userid: '', }) }
                  
               }
    
    render(){
        const contextValue = {
             viewBooksPreview:this.viewBooksPreview, // same payment code
             all_books:this.state.booksAddPreview,  // same payment code
             editModepreview:this.editModepreview,  // same payment code
             previewbookUpdateview:this.previewbookUpdateview,
             cancelEdit:this.cancelEdit,
        }

        return(
            <Provider value={contextValue}>
           
           <div className="addbooksssmmmq gray-bg ">
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">        
                      <ViewBookedit storageid ={this.state.userid} id={this.props.match.params.BookpreviewId}  loadingsss ={this.state.isLoading} />
                                  
                      </div>
            </div>
            </div>
            </div>
                        
            </Provider>
        );
    }
}
export default Bookpreview;