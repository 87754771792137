// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import Audiopreviewpage from '../Components/Audiopreviewpage';

class Audioaddpreviewsection extends Actions{

    componentDidMount(){
        
        window.scrollTo(0, 0);
     }  
    render(){
        const contextValue = {
            viewaudioPreview:this.viewaudioPreview, //perms select value from database
             all_audiobooks:this.state.audioAddPreview, // get data 

             editModeaudiopreview:this.editModeaudiopreview,
             previewaudioUpdate:this.previewaudioUpdate,
             coupenActivatedAudio:this.coupenActivatedAudio,
             audiocancelEdit:this.audiocancelEdit,

             
             pricefetch:this.fetchprice,
             all_allprices:this.state.allprices,
        }

        return(
            <Provider value={contextValue}>
            <div className="addbooksss gray-bg ">
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">
              <Audiopreviewpage id={this.props.match.params.audiobookPreviewId}  loadingsss ={this.state.isLoading} loadingedits ={this.state.isLoading} />
                                  
                        
                             </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Audioaddpreviewsection;