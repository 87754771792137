import React, { Component } from 'react';

import Loader from 'react-loader-spinner';

import {Link } from "react-router-dom"; 
import { toast } from "react-toastify";
import Axios from 'axios';


let BaseURL = 'https://reactbooks.com/api/';

class Password extends Component {
       
       constructor(props, ...args) {
              super(props, ...args);
              this.state = {
                isLoading: false
              };
            }
      
       changePassword = (event) => {
              this.setState({ isLoading: true });
          event.preventDefault();event.persist();
          return Axios.post(BaseURL+'password.php',{
              newpassword:this.newpassword.value,
              confirmpassword:this.confirmpassword.value,
              id:this.props.match.params.passkey,
          })
          .then(function ({data}) {
                 console.log(data);
              if(data.success === 1){
                     toast.success(data.msg, {
                            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                          });
                     this.setState({ isLoading: false });
                     event.target.reset();
                     this.props.history.push('/a8f2eec89c7b7c73866300abd1ee6bd2')
                 }
                 else{
                     this.setState({ isLoading: false });
                     toast.error(data.msg, {
                         position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                       });
                     }
          }.bind(this))
          .catch(function (error) {
            console.log(error);
            this.setState({ isLoading: false });
        });              
                }



    render(){
        return(
              <>
              <div className="pagsssqqq">
              <div className="container">
                 <div className="row">
    
                 <section className="breadcrumb-section">
          <h2 className="sr-only">Site Breadcrumb</h2>
          <div className="container">
            <div className="breadcrumb-contents">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
                  <li className="breadcrumb-item active"> Change Password </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
        </div>
        </div>
        </div>
              <div className="login-1nnn">
              <div className="container">
                 <div className="row">
                 <div className="col-md-2">
                        &nbsp;
              </div>
              <div className="col-md-6">
              <form onSubmit={this.changePassword} className="c-form">
              <div className="set-title"> <h5>Change Password</h5></div>
              <div>
              <label> New Password </label>
              <input type="password" name="newpassword" ref={(val) => this.newpassword = val} className="form-control" placeholder=" New Password " required />
		</div>
              <div>
              <label> Confirm Password </label>
              <input type="password" name="confirmpassword" ref={(val) => this.confirmpassword = val} className="form-control" placeholder=" Confirm Password " required />
		</div>


            
                  <div className="form-group col-md-12 text-right">
                  {this.state.isLoading ?
                          <>
                                 <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                          </>
                       : 
                      <button type="submit" className="form-button"> Save </button>
                  } 
                  </div>
              
          </form>  
          </div>
          </div>
          </div>
          </div>
          </>
        );
    }
}
export default Password;