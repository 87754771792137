
import React,{Component} from 'react';
import {AppContext} from '../Context';
import Loader from 'react-loader-spinner'
import userphotos from '../Img/sss.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let BaseURL = 'https://reactbooks.com/api/';
class Userprofile extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
              file: null, 
              isLoading: false,

         };
         
        
     }
     
 componentDidMount(){
       this.storedata();

         
 }

 storedata() {
     
       if (localStorage.getItem('tockenid')) {
              let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
              this.setState({
                     userid: userstorageid,
              })
          this.profileimagesfetch(userstorageid);

       } else { this.setState({ userid: '', }) }
         
      }


 profileimagesfetch = (userstorageid) => {
    this.context.profileimagesfetch(userstorageid);
 }



    userphotosAdd = (event) => {
      
       this.context.userphotosAdd(event,this.file.files[0],this.storageidsss.value);
       
      
   }
   resetFile = (event) => {
       event.preventDefault();
       this.setState({ file: null });
     }
//image preview       
onChange = (event)  => {
       this.setState({
             file: URL.createObjectURL(event.target.files[0])
       });
 }

editUploadMode =() =>{
       this.setState({ 
              isupload: true,
            });

}
    render(){   
        const {  loadingprofile} = this.props;
//        if (loadingprofile) {
//          return <><Loader type="ThreeDots" color="#00BFFF" height={100} width={100} /></>;
//        } 
            
      
        return (
            < >
       
       


        {this.context.all_profileimages.map((item, index) => (
              <div key={item.react_user_id} className="profilepageleftsss">
                     <h2> {item.user_name} </h2>
                     <div className="profilepageleftsss2"> 
                     {item.profile_image ?
                              <>
                     <img src={BaseURL+item.profile_image} alt="userprofile" className="profileimgsss" />
                     <button onClick={this.editUploadMode} className="btn btn-primary"> <FontAwesomeIcon icon={['fas','images']} className="iconsss2" /> Change </button>
                     
                     </>
                    : 
                    <>
                      {this.state.file ?
                        <div style={{ textAlign: "center" }}>
                             <img className="profileimgsss"  src={this.state.file} alt="UploadImage"/>
                            <button onClick={this.resetFile} className="btn btn-primary"> <FontAwesomeIcon icon={['fas','trash-alt']} className="iconsss2" />  Remove</button>
                        </div>
                       :
                       <>
                        <img src={userphotos} alt="userprofile" className="profileimgsss"/>
                        <button onClick={this.editUploadMode} className="btn btn-primary">  <FontAwesomeIcon icon={['fas','images']} className="iconsss2" /> Add Photo </button>
                        </>
                        }
                   
                    
                    </>
                     }
                  </div>
               </div>

                   ))}

                     {this.state.isupload === true ?
                       <>
                       <form onSubmit={this.userphotosAdd} className="central-meta2">
                             <div className="form-group col-md-12">
                                 



                               <input type="file" name="file" ref={(val) => this.file = val} className="form-control" placeholder=""  onChange={this.onChange} required/>
                                   <input type="hidden" name="storageidsss" ref={(val) => this.storageidsss = val} defaultValue={this.state.userid} />
                               </div>
                      
                      {/* Loading */}
                      {loadingprofile ?
                            <>
                                   <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                            </>
                         : 
                         <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary"> Save </button>
                      </div>
                      } 

                      </form>
                      </>

: null }


       
         
           
      
     
      
            
        </>  
        );
    }
}
export default Userprofile;