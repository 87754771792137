import React, { Component } from 'react';
import {NavLink,Link} from 'react-router-dom';
import logo from '../Img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Navbarmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: false
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState({isMenu: !this.state.isMenu});
};
    render(){
      let boxClass = ["main-menu menu-right menuq1"];
      if(this.state.isMenu) {
        boxClass.push('menuq2');
      }
        return(
          <header className="header-middle">
            <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3 col-sm-3">
            <div className=" logo">
            <NavLink exact activeClassName='is-active' to="/">
            <img src={logo} alt="logo" />
            {/* <img src={logo} alt="logo" style={{ width: '35px' }} /> */}
            </NavLink>
            <div className="book-shadow"></div>
            </div>
            </div>
            <div className="col-lg-9 col-sm-9">
            <nav className="navBar ">
            <span className="res5c" style={{ display: 'none' }} onClick={this.toggleClass} >
               <FontAwesomeIcon icon={['fas','bars']} />
                </span>
                {/* <div className={boxClass.join(' ')} onClick={this.toggleClass}>{this.state.isMenu ? "Remove a class" : "Add a class (click the box)"}<br />Read the tutorial <a href="https://www.automationfuel.com" target="_blank">here</a>.</div>        */}
          {/* //Solid <FontAwesomeIcon icon={['fas', 'bars']} />
          //Regular <FontAwesomeIcon icon={['far', 'check-box']} /> */}
                <ul className={boxClass.join(' ')}>
                    <li className="menu-item" onClick={this.toggleClass}><NavLink exact activeClassName='is-active' to="/">Home</NavLink></li>
                    <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/abe35d0f31ec0c0bd7a54de00d236f89/"> Post Your Book </NavLink></li>
                    <li className="menu-item has-children"><NavLink to="#">Books <FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink> 
                        <ul className="sub-menu">
                          <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/5b1e54a6f081ad9f8d2c352bc7ba8081/"> Recommended </NavLink></li>
                          <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/87c28f786a96f6b77a4d25e074942a54/"> Featured </NavLink></li>
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/4d6c54eec0adf53e101d08f85f86ff32/" onClick={this.toggleClass}> Standard </NavLink></li>
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/4657c4ce07e9a56c07559538097b54a4/" onClick={this.toggleClass}> Basic </NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-item has-children"><NavLink to="#"> Book Trailers <FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                        <ul className="sub-menu">
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/e1858682b461934eb88bbbe36f9c9773/" onClick={this.toggleClass}> Add Book Trailer </NavLink></li>
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/8a6048fc3f9c43ece54e1321ecdc2cbf/" onClick={this.toggleClass}> View Book Trailers </NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-item has-children"><NavLink to="#"> Publishers <FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                        <ul className="sub-menu">
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/b6e03f328feffa5a0ad181e7eb70e91d/" onClick={this.toggleClass}> Add Publisher </NavLink></li>
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/1ec46a3c476af55e505c6885aa43baf8/" onClick={this.toggleClass}> View Publishers </NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-item has-children" ><NavLink to="#"> Audio Books <FontAwesomeIcon icon={['fas','chevron-down']} /></NavLink>
                        <ul className="sub-menu">
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/8839644748c0ffe6e3c529161cb6c9f6/" onClick={this.toggleClass}> Add Audio Book </NavLink></li>
                          <li className="menu-item"><NavLink activeClassName='is-active' to="/cea82ab39bc7e63d6111a9f01129078d/" onClick={this.toggleClass}> View Audio Books </NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/02684cc6b6ea1811a064f475a5fd1d18/" onClick={this.toggleClass}> Authors </NavLink></li>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/886c6fa8cc060551c2e505ef4e7d224a/" onClick={this.toggleClass}> Contact </NavLink></li>
                    {/* <li className="menu-item"><NavLink to="/demo"> Demo </NavLink></li> */}
                </ul>
            </nav>
            </div>
            </div>
            </div>
            </header>
        );
    }
}
export default Navbarmenu;