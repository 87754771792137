import React, { Component } from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
//toastify include
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from './Img/logo.png';
//---------------------------------------------------------------------------------
//Include Pages
import Navbarmenu from './Components/Navbarmenu';
import Home from './Components/Home';
import Loginsection from './Components/Loginsection';
import Addbooksection from './Components/Addbooksection';
import Bookaddpreviewsection from './Components/Bookaddpreviewsection';
import Success from './Components/Success';
import Cancel from './Components/Cancel';
import Basicbooks from './Components/Basicbooks';
import Search from './Components/Search';
import Myaccountsection from './Components/Myaccountsection';
import Bookaddstripe from './Components/Bookaddstripe';
import Audiobooksection from './Components/Audiobooksection';
import Audioaddpreviewsection from './Components/Audioaddpreviewsection';
import Trailorsection from './Components/Trailorsection';
import Trailorpreviewsection from './Components/Trailorpreviewsection';
import Publishersection from './Components/Publishersection';
import Publisherpreviewsection from './Components/Publisherpreviewsection';
import Authordetails from './Components/Authordetails';
import Packsinglebook from './Components/Packsinglebook';
import Bookpreview from './Components/Bookpreview';
import Publisherpreview from './Components/Publisherpreview';
import Audiopreview from './Components/Audiopreview';
import Packview from './Components/Packview';
import Recommentedbooks from './Components/Recommentedbooks';
import Featuredbooks from './Components/Featuredbooks';
import Standardbooks from './Components/Standardbooks';
import Viewtrailers from './Components/Viewtrailers';
import Editviewtrailor from './Components/Editviewtrailor';
import Publishers from './Components/Publishers';
import Audiobooks from './Components/Audiobooks';
import Authors from './Components/Authors';
import Authorpackage from './Components/Authorpackage';
import PendingBooks from './Components/PendingBooks';
import PendingVideo from './Components/PendingVideo';
import PendingAudio from './Components/PendingAudio';
import PendingPublishers from './Components/PendingPublishers';
import Categories from './Components/Categories';
import Contmail from './Components/Contmail';
import Password from './Components/Password';
import Adminbooks from './Components/Adminbooks';
import Adminaudio from './Components/Adminaudio';
import Adminpublisher from './Components/Adminpublisher';
import Admintrailer from './Components/Admintrailer';
import Coupentwo from './Components/Coupentwo';




import menu from './Img/menu.svg';
import Demo from './Components/Demo';

import {NavLink, Link } from "react-router-dom"; 
//---------------------------------------------------------------------------------
//Fontawesome include
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas} from '@fortawesome/free-solid-svg-icons'
library.add(fas)
//----------------------------------------------------------------------------------



class App extends Component {
  constructor(){
    super();
    this.state = {
          displayMenu: false,
          searchsss: false,
        };
     this.showDropdownMenu = this.showDropdownMenu.bind(this);
     this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
   };
 searchMode = () => {
  this.setState({ 
    searchsss: true,
  });
}         
searchModeclose = () => {
  this.setState({ 
    searchsss: false,
  });
}  
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
    document.addEventListener('click', this.hideDropdownMenu);
    });
  }
  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }
  render() {
    return (
      <BrowserRouter basename="/">
      <div className="App">
      <div className="Appsss">
      <div  className="dropdown">
      <div className="container">
          <div className="row align-items-center">
      <div className="col-md-3 ">
            <div className="headsesssbutton" >
           <div className="button" onClick={this.showDropdownMenu}> 
           <img src={menu} alt="menubars" style={{maxWidth:'20px', marginRight: '7px'}} /> All Categories </div>
          { this.state.displayMenu ? (
          <ul>
            <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/FictionLiterature" innerRef={this.refCallback} > Fiction & Literature  </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Romance" innerRef={this.refCallback} >  Romance  </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Science" >   Science Fiction & Fantasy   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Comics" >   Comics & Graphic Novels   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/YA" >   Fiction - YA   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Periodicals" >   Periodicals   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Kids" >   Kids   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Non" >   Non-Fiction  </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Business" >   Business & Finance   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Biography" >   Biography & Memoir   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/Mystery" >   Mystery & Suspense   </Link></li>
         <li> <Link to="/af1b98adf7f686b84cd0b443e022b7a0/More" >  More Categories  </Link></li>
          </ul>
        ):
        (
          null
        )
        }
           </div>
          </div>
       <div className="col-md-4 ">
       {this.state.searchsss === true ? 
          <>
              <div className="input-groupsss ">
              <button className="btn search-btn" type="button">   Search </button>
                    <input type="text" className="form-control search-field" placeholder=" Search books, authors, trailers, publishers " />
                    <span className="input-group-btn">
                    </span>
              </div>
          </>
          :  
         <h2 className="premiumsssa1"> Discover books you'll love </h2>                  
        }
</div>
              <div className="col-md-5  zzzss">
              {this.state.searchsss === true ? 
                  <>
                    <FontAwesomeIcon icon={['fas','times']} className="searchiconsss" onClick={this.searchModeclose} />
                  </>
                    :  
                    <>
                    {/* <FontAwesomeIcon icon={['fas','search']} className="searchiconsss" onClick={this.searchMode} /> */}
                    <NavLink activeClassName='is-active' to="/13348442cc6a27032d2b4aa28b75a5d3/">   <FontAwesomeIcon icon={['fas','search']} className="searchiconsss" />    </NavLink> 
                    </>                     
              } | 
                 
                  <NavLink activeClassName='is-active' to="/a8f2eec89c7b7c73866300abd1ee6bd2/"> Login / Register </NavLink> | 
                  <NavLink activeClassName='is-active' to="/2383afa57c522277b6e31e96849610d5/"> My Account </NavLink> |
                  <NavLink activeClassName='is-active' to="/abe35d0f31ec0c0bd7a54de00d236f89/"> Promote Your Book </NavLink>
              </div>
            </div>
            </div>
            </div>
               <div className="clearfix"></div>
            <div className="container">
          <div className="row align-items-center">
            <Navbarmenu/>
       </div>
       </div>
       </div>
<div className="clearfix"></div>
       <ToastContainer />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/a8f2eec89c7b7c73866300abd1ee6bd2" component={Loginsection}/>
          <Route exact path="/abe35d0f31ec0c0bd7a54de00d236f89" component={Addbooksection}/>
          <Route exact path="/ae1ce6f4a83e70734418c6a77c9e984e/:bookPreviewId" component={Bookaddpreviewsection}/>
          <Route exact path="/b83845935b160ef9b990c0f98d490779/:audiobookPreviewId" component={Audioaddpreviewsection}/>
          <Route exact path="/260ca9dd8a4577fc00b7bd5810298076" component={Success}/>
          <Route exact path="/10aec35353f9c4096a71c38654c3d402" component={Cancel}/>
          <Route exact path="/4657c4ce07e9a56c07559538097b54a4" component={Basicbooks}/>
          <Route exact path="/13348442cc6a27032d2b4aa28b75a5d3" component={Search}/>
          <Route exact path="/demo" component={Demo}/>
          <Route exact path="/2383afa57c522277b6e31e96849610d5" component={Myaccountsection}/>
          <Route exact path="/8839644748c0ffe6e3c529161cb6c9f6" component={Audiobooksection}/>
          <Route exact path="/Bookaddstripe/:bookId/:paymentAmount/:title/:bookadd" component={Bookaddstripe}/>
          <Route exact path="/e1858682b461934eb88bbbe36f9c9773/" component={Trailorsection}/>
          <Route exact path="/be9d3adebe148e4137c0d82fc5fd0a38/:trailorPreviewId" component={Trailorpreviewsection}/>
          <Route exact path="/b6e03f328feffa5a0ad181e7eb70e91d/" component={Publishersection}/>
          <Route exact path="/011762c5e30adb043a868e07ca51ebdb/:publisherId" component={Publisherpreviewsection}/>
          <Route exact path="/a02a60ce620c6b2cec2cd014dea74008/:authordetailsId" component={Authordetails}/>
          <Route exact path="/23e6caa400cf92ed683e4ed0e72a2f1e/:packsingleId" component={Packsinglebook}/>
          <Route exact path="/3d3f3ef7fc59cb9e12b308a5b1de8cca/:BookpreviewId" component={Bookpreview}/>
          <Route exact path="/d9a471426a3fd97b4c33998bb6dfb376/:PublisherspreviewId" component={Publisherpreview}/>
          <Route exact path="/1ebfd8784d80307a0b3390fca61fba3b/:AudiopreviewId" component={Audiopreview}/>
          <Route exact path="/614bc74b853095ed79ed30ba57b30b64/:PackviewId" component={Packview}/>
          <Route exact path="/5b1e54a6f081ad9f8d2c352bc7ba8081/" component={Recommentedbooks}/>
          <Route exact path="/87c28f786a96f6b77a4d25e074942a54/" component={Featuredbooks}/>
          <Route exact path="/4d6c54eec0adf53e101d08f85f86ff32/" component={Standardbooks}/>
          <Route exact path="/8a6048fc3f9c43ece54e1321ecdc2cbf/" component={Viewtrailers}/>
          <Route exact path="/89e1d85c2ce6a02b5eb4461fbe47ddcd/:EdittrailorId" component={Editviewtrailor}/>
          <Route exact path="/1ec46a3c476af55e505c6885aa43baf8/" component={Publishers}/>
          <Route exact path="/cea82ab39bc7e63d6111a9f01129078d/" component={Audiobooks}/>
          <Route exact path="/02684cc6b6ea1811a064f475a5fd1d18/" component={Authors}/>
          <Route exact path="/102140f165c4b280244b44ecb79e4f4d/:AuthorpackageId" component={Authorpackage}/>
          <Route exact path="/0498ac26858e7605b2b05a27822d8dc7/" component={PendingBooks}/>
          <Route exact path="/e03d69e8150cdc8be84ef2a84a3c4787/" component={PendingVideo}/>
          <Route exact path="/a1cdcf914b45e998ca40eb06e936d209/" component={PendingAudio}/>
          <Route exact path="/dda076c4518609b5ea9f8218bf3dbf0a/" component={PendingPublishers}/>
          <Route exact path="/af1b98adf7f686b84cd0b443e022b7a0/:categoryId/" component={Categories}/>
          <Route exact path="/886c6fa8cc060551c2e505ef4e7d224a/" component={Contmail}/>
          <Route exact path="/dc647eb65e6711e155375218212b3964/:passkey" component={Password}/>
          <Route exact path="/2f15e32e91b58dcc91b4a803d130bc71" component={Adminbooks}/>
          <Route exact path="/c8aa8806f7c39bf71ca89a2b3810671b" component={Adminaudio}/>
          <Route exact path="/a00da0a6babd20eb235359d1a71b9c25" component={Adminpublisher}/>
          <Route exact path="/dca076032375d968e122ba528a2ffe64" component={Admintrailer}/>
          <Route exact path="/b08250ca6064abb24ac99da733d16ee8" component={Coupentwo}/>
        </Switch>
      </div>

      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
            <NavLink exact activeClassName='is-active' to="/">
            <img src={logo} alt="logo" />
            {/* <img src={logo} alt="logo" style={{ width: '35px' }} /> */}
            </NavLink>
              

            </div>
            <div className="col-md-3">
            <h3> Quick Links </h3>
              <ul>
              <li className="menu-item"><NavLink activeClassName='is-active' to="/abe35d0f31ec0c0bd7a54de00d236f89/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Promote Your Book </NavLink></li>
              <li className="menu-item"><NavLink activeClassName='is-active' to="/e1858682b461934eb88bbbe36f9c9773/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Add Book Trailer </NavLink></li>
              <li className="menu-item"><NavLink activeClassName='is-active' to="/b6e03f328feffa5a0ad181e7eb70e91d/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Add Publisher </NavLink></li>
              <li className="menu-item"><NavLink activeClassName='is-active' to="/8839644748c0ffe6e3c529161cb6c9f6/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Add Audio Book </NavLink></li>
              </ul>
            
            </div> 
            <div className="col-md-3">
            <h3> Useful Links </h3>
              <ul>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/5b1e54a6f081ad9f8d2c352bc7ba8081/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Recommended </NavLink></li>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/87c28f786a96f6b77a4d25e074942a54/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Featured </NavLink></li>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/4d6c54eec0adf53e101d08f85f86ff32/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Standard </NavLink></li>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/4657c4ce07e9a56c07559538097b54a4/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Basic </NavLink></li>
              </ul>
            
            </div>
            <div className="col-md-3">
            <h3> Contact Us </h3>
            <ul>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/886c6fa8cc060551c2e505ef4e7d224a/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Contact Form </NavLink></li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p><span className="white">Copyright © 2021 <Link to={'/'}  className="green"> React Books </Link>. All Rights Reserved.</span></p>
            </div>
          </div>
        </div>
      </div>

      </BrowserRouter>
    );
  }
}
export default App;