import React, { Component } from 'react';

class Success extends Component {
  




  render(){
    return (
      <div className="successs">
          <div className="container">
            <div className="row">
               <div className="col-md-12">
        <div className="container-fluid">
             <h3>Your Payment Process Checking. Your Ads Will Be Published Soon.....</h3>
              </div>
              </div>
              </div>
              </div>
              </div>
      
    );
  }
}

export default Success;