
import React,{Component} from 'react';
import {AppContext} from '../Context';

import Bookaddpreviewpagetwo from '../Components/Bookaddpreviewpagetwo';

import Loader from 'react-loader-spinner'
import ContentLoader from "react-content-loader" ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paypal from '../Img/Paypal.png';
import stripe from '../Img/stripe2.jpg';


let BaseURL = 'https://reactbooks.com/api/';
// Paypal Link
let Cancel = 'https://reactbooks.com/10aec35353f9c4096a71c38654c3d402';
let Success = 'https://reactbooks.com/260ca9dd8a4577fc00b7bd5810298076';
let PaymentListner = 'https://reactbooks.com/api/paypal/payment-listner.php';
// Live Account PayPal
let paypal_url = 'https://www.paypal.com/cgi-bin/webscr'; 
let paypal_email = 'alwinin@live.com';
//------------------------------------------
// Sandbox Account PayPal
//let paypal_url = 'https://www.sandbox.paypal.com/cgi-bin/webscr'; 
//let paypal_email = 'infooltobusines@gmail.com';
//-------------------------------------------------------------------------------------


class Bookaddpreviewpage extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
          
            isEditing:false,
            paynowpaypal: true,
            paynowstripe: false,
            activate: false,
            addClass: false,
         };
         
         this.packageselected = this.packageselected.bind(this);
     
    
     }

    

      componentDidMount(){
          this.viewBooksPreview();
          
        this.setState({
            packagePlan: 'paypals',
        });
          
    }
    viewBooksPreview = () => {
        this.context.viewBooksPreview(this.props.id);
    }
    coupenActivated = (books_id) =>{
        this.context.coupenActivated(books_id,this.coupenCode.value,this.coupenBookAmount.value,this.coupenBookId.value);
        
        
    }



    //radio button
    packageselected(event) {
      //alert(event.target.value);
      this.setState({
        packagePlan: event.target.value
      });
    }
    
            paypalMode = () => {
                this.setState({
                    paynowpaypal: true,
                    paynowstripe: false
                });

            }
            stripeMode = () => {
                this.setState({
                    paynowpaypal: false,
                    paynowstripe:true
                });

            }
            isActivate = () => {
                this.setState({
                    activate: true,
                });

            }


            toggle() {
                this.setState({addClass: !this.state.addClass});
              }


    render(){
        let boxClass = ["redmoesss"];
        if(this.state.addClass) {
          boxClass.push('redmoesssqqq');
        }

        let allUsers;
        let mainData;
      
       
        allUsers = this.context.all_books.map(({books_id,title,bookcover,description,price,purchaselink,category,other,websitelink,paymentbook,paymentamount,coupon_code,discount,isEditing}) => {
              //const regex = /(<([^>]+)>)/ig;
              //const descriptionviews = description.replace(regex, '');
            return isEditing === true ? (
                   <div key={books_id}>
                        
                           <div className="form-row">
                             <Bookaddpreviewpagetwo useridsnnn={this.props.useridmmm} desc={description} books_id={books_id} title={title} bookcover={bookcover} price={price} purchaselink={purchaselink} category={category} other={other} websitelink={websitelink} paymentamount={paymentamount} paymentbook={paymentbook} loadingsssedits ={this.props.loadingedits}/>
                            
             {/* {this.props.desc} */}
             </div>
 
            </div>
            ) :  (
               
                <div key={books_id}>
                <div className="col-md-8">

                <div className="central-metazzz">

    {/* ------------------------------ Loader start ----------------------------------------------------- */}
    
                    {this.props.loadingsss ? 
                    <><Loader type="TailSpin" color="#fd6500" height={67} width={67} style={{textAlign:"center"}} /></>
                    : 
                    <>
                          <button className="zzz" onClick={() => this.context.editModepreview(books_id)}>Edit</button>
                          <h2 className="create-post">{title}</h2>




                          <div className="col-md-4">
                          <img src={BaseURL+bookcover}  alt="" style={{ maxWidth: '177px',marginBottom:'37px',boxShadow: '0px 1px 11px #928f8f'}}/>
                          </div>
                          <div className="col-md-8">
                         <div className="zzz2">
                          <h3>  <FontAwesomeIcon icon={['fas','tags']} className="iconsss2" /> Category : <span>
                             {category === 'other' ?
                                <>{other}</>
                              :  <>{category}  </>}
                               </span></h3>
                         <hr />
                          <h3> <FontAwesomeIcon icon={['fas','money-bill']} className="iconsss2" /> Price : <span style={{color:"#ff4242"}} > {price}</span></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','link']} className="iconsss2" />  Book Purchase Link : <a href={purchaselink} rel="noopener noreferrer" target="_blank"> {purchaselink} </a></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" />  Website Link  : <a href={websitelink} rel="noopener noreferrer" target="_blank"> {websitelink}</a></h3>

                          </div>
                          </div>
                          <div className="col-md-12">


                          <div  className={boxClass.join(' ')}>
		                  {/* {descriptionviews} */}
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                            </div>

                            {this.state.addClass ? 
                             <p className="redmoesssmmm" onClick={this.toggle.bind(this)}>  </p>
                             :
                             <p className="redmoesssmmm" onClick={this.toggle.bind(this)}> More </p>
                             }

                            

                            </div>



                                </>
                             }
                         
     {/* ------------------------------- Loader End ----------------------------------------------------- */}
                        
                        </div>
                        </div>
                        <div className="col-md-4">
                        <div className="central-metazzz">
                        {coupon_code !=='' ? 
                        <h4 className="text-warning2"> Coupon code actived </h4>
                        :  <h4 className="text-warning2"> Have a coupon code? </h4> 
                        }
                      
                        <div className="text-warning2s">
                            <input className="form-control" type="text" ref={(val) => this.coupenCode = val} defaultValue={coupon_code} onClick={this.isActivate} placeholder="Enter The Coupon Code"  />
                            <input type="hidden" ref={(val) => this.coupenBookAmount = val} defaultValue={paymentamount} id="price" />
                            
                            <input type="hidden" ref={(val) => this.coupenBookId = val} defaultValue={books_id} id="pageview" />

                            {coupon_code !=='' ? 
                            <h4 class="pull-right text-danger"> {discount} %Off</h4>
                        :  
                        <>
                        {this.state.activate === true ?
                        <button className="btn btn-primary" onClick={() => this.coupenActivated(books_id)}>Activate Code</button> 
                        
                        :
                        null

                        }
                         </>
                        }
                            
                        </div>

                        <div className="form-group zzz3">
                            <h6> Total Amount : <span> $ {paymentamount} </span> </h6>
                        </div>


                        <div className="zzz4" >
                        <div className="col-md-12">
                        <input type="radio" className="radiozzz5" id="paypalsqqq"
                           value="paypals" 
                           checked={this.state.packagePlan === "paypals"}
                           onChange={this.packageselected}  onClick={this.paypalMode}/>
                         <label className="radio-labelpay" htmlFor="paypalsqqq"> <img src={paypal} alt="paypal" /> </label>
                         </div>
                         <div className="clearfix"></div>
                         <hr />
                         <div className="clearfix"></div>
                         <div className="col-md-12">
                        <input type="radio" className="radiozzz5" id="stripsqqq"
                           value="strips" 
                           checked={this.state.packagePlan === "strips"}
                           onChange={this.packageselected}  onClick={this.stripeMode}/>
                         <label className="radio-labelpay" htmlFor="stripsqqq">
                              <img src={stripe} alt="stripe" style={{maxWidth: '33px',borderRadius: '3px',marginRight: '7px',marginBottom: '0px'}} /> Credit / Debit Card </label>
                         </div>
                          

                          </div>

                          <div className="clearfix"></div>



{/* ------------------------------------------------------------------------------------------ */}
            {/* --------------- PayPal and Stripe Payment Gateway ----------- */}
{/* ------------------------------------------------------------------------------------------ */}



                       {this.state.paynowpaypal === true ? 

                        <form action={paypal_url} method="post">
                            <input type="hidden" name="business" defaultValue={paypal_email} />
                            <input type="hidden" name="cmd" defaultValue="_xclick" />
                            <input type="hidden" name="item_name" defaultValue={title} />
                            <input type="hidden" name="item_number" defaultValue={books_id} />
                            <input type="hidden" name="amount" defaultValue={paymentamount} />
                            <input type="hidden" name="currency_code" defaultValue="USD" />
                            
                           {/* Specify URLs  */}
                            <input type='hidden' name='cancel_return' defaultValue={Cancel} />
                            <input type='hidden' name='return' defaultValue={Success} />
                            <input type='hidden' name='notify_url' defaultValue={PaymentListner} />
                            
                            
                         {/* payment button.  */}
                        
                         <input type="submit" name="submit" border="0" value="Pay Now" className="continueButton" />    
                               
                               
                                
                        </form> 

                         :
                        <>
                        {/* <Link to={"/Bookaddstripe/"+books_id}  > Stripe </Link> */}
                        
                        {/* <Link to={`/Bookaddstripe/${books_id}/${paymentamount}/${title}/bookadd`} className="continueButton"> Pay Now </Link> */}

                       {/* <a href={`/Bookaddstripe/${books_id}/${paymentamount}/${title}/bookadd`} className="continueButton"> Pay Now   </a> */}

                       <a href={BaseURL+`stripe/4741faa96e8c4302c2efcf49c47c2b74.php?1bda80f2be4d3658e0baa43fbe7ae8c1=`+books_id+`&e5e0376467de2afa1170bf7eb0206810=`+books_id} className="continueButton"> Pay Now   </a>




                        </>


                          }
       
                         </div>
                         </div>
                
                         </div>
                        
          
            );
        });

        if(this.context.all_books.length > 0){
            mainData = (
              <div>
                      {allUsers}
              </div>
                   
            );
        }
        else{
            mainData = (
                <div className="alert alert-light" role="alert">
                    <h4 className="alert-heading"> Please Wait </h4>
                    <hr/>
                    <ContentLoader  height={260} width={600} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
                </div>
            );
        }
        return (
            < >
           
                {/* <h3> Pass Value :{this.props.id} </h3> */}
                
                {mainData}
      
     
      
            
        </>  
        );
    }
}
export default Bookaddpreviewpage;