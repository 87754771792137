
import React,{Component} from 'react';
import {AppContext} from '../Context';


import Axios from 'axios';
import {Link } from "react-router-dom"; 
import Loader from 'react-loader-spinner'
import ContentLoader from "react-content-loader";
import { ToastContainer,toast} from "react-toastify";

let BaseURL = 'https://reactbooks.com/api/';

//let BaseNodeURL = 'https://192.168.0.130:4000/';

class Authorpackagetwo extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
          
            isEditing:false,
            isLogin:false,
            isloader:false,
         };
         
     }

      componentDidMount(){
          this.packPreviewsauthor();
          this.packPreviewsauthorvideo();
          
    }
    packPreviewsauthor = () => {
        this.context.packPreviewsauthor(this.props.id);
    }
    packPreviewsauthorvideo = () => {
        this.context.packPreviewsauthorvideo(this.props.id);
    }

    videoaddMode = () => {
        this.setState({ 
          isLogin: true,
          
        });
      
      }

      


      videopackadd = (event) => {
        this.setState({ isloader: true });
        event.preventDefault();
        event.persist();

        //const employee = {title:this.title.value,video:this.video.value,storageid:this.props.id }
        //Axios.post('https://192.168.0.130:4000/videopackadd', employee)
        
     Axios.post(BaseURL+'videopackadd.php',{
            title:this.title.value,
            video:this.video.value,
            storageid:this.props.id,
        })
    
    .then(res => { 
        //console.log(res.data);

        if(res.data.success === 1){
            this.setState({ isLogin: false});
            this.packPreviewsauthorvideo();
            event.target.reset();
            toast.success("Video add successfully ", {
                position: "top-center" })
                this.setState({ isloader: false});
        } 
    })
    .catch(err => {console.log(err); this.setState({ isloader: false });})
            
          }





          videopackdelete = (deleteids) => {
              
          Axios.post(BaseURL+'videopackdelete.php',{
              storageid:deleteids,
          })
      
      .then(res => { 
          //console.log(res.data.success)
          if(res.data.success === 1){
              this.setState({ isLogin: false });
              this.packPreviewsauthorvideo();
              toast.success("Video delete successfully ", {
                position: "top-center" })
             
          }
          
      })
      .catch(err => {console.log(err); })
  
        }













    render(){
        let allUsers;
        let mainData;
      
       
        allUsers = this.context.all_books.map(({bookspacks_id,books_id,userid,title,bookcover,description,price,purchaselink,category,other,websitelink,profile_image,user_name,isEditing}) => {
            return isEditing === true ? (
                 null
            ) :  (
               
                <div key={bookspacks_id} >
                <div className="col-md-6">

                <div className="central-metazzz">

    {/* ------------------------------ Loader start ----------------------------------------------------- */}
    
                    {this.props.loadingsss ? 
                    <><Loader type="TailSpin" color="#fd6500" height={67} width={67} style={{textAlign:"center"}} /></>
                    : 
                    <>
                   
                      
                          <h2 className="create-post">{title}</h2>




                          <div className="col-md-4">
                          <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                      <Link to={`/614bc74b853095ed79ed30ba57b30b64/${bookspacks_id}`} className="" >
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL+bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                            </Link>
                      </article>
                  
                    </section>
                        
                          </div>
                          <div className="col-md-8">
                         <div className="zzz2nnn">
                          <h3><span>
                             {category === 'other' ?
                                <>{other}</>
                              :  <>{category}  </>}
                               </span></h3>
                         
                          
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                           
                          </div>
                          </div>
                          <div className="col-md-12">
                        
                        
                            <Link to={`/614bc74b853095ed79ed30ba57b30b64/${bookspacks_id}`} className="single-btnmmm" > View Book  </Link>
                            </div>

                          

                                </>
                             }
                         
     {/* ------------------------------- Loader End ----------------------------------------------------- */}
                        
                        </div>
                        </div>
                        
                
                         </div>
                        
          
            );
        });
    
        if(this.context.all_books.length > 0){
            mainData = (
              <div>
                      {allUsers}
              </div>
                   
            );
        }
        else{
            mainData = (
                <div className="alert alert-light" role="alert">
                     <h4 className="alert-heading"> Please Wait </h4>
                    <hr/>
                    <ContentLoader  height={260} width={400} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
                </div>
            );
        }



    

        return (
            < >
           
                <ToastContainer/>


           <div className="col-md-2" style={{padding:'0px'}}>
              <div className="avatar-previewqqq">
              <img src={BaseURL+this.props.profileimages} className="yyyviewimgsss" alt="" />
              <h6> <span> {this.props.usernames} </span> </h6>
           </div>
      
              
                    
      
                </div>
                <div className="col-md-10">
                <div className="srfefdsgbfedv" >
                <div className="col-md-12">
             {this.props.storageid  == this.props.useridsss ?
               <>
                  <Link to={`/a02a60ce620c6b2cec2cd014dea74008/${this.props.bookspacksidsss}`} className="yyybuttons" > Add Details  </Link>
                  <Link to={`/23e6caa400cf92ed683e4ed0e72a2f1e/${this.props.bookspacksidsss}`} className="yyybuttons2" > Add Additional Books  </Link>

                  <h6 className="btn btn-primary" onClick={this.videoaddMode} > Add Video </h6>
                 
                </>
                  : null
             }

                <div className="clearfix"></div>

             {/* Register Mode */}
             {this.state.isLogin === true ?
              <>
                    <form onSubmit={this.videopackadd} className="central-meta">
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold"> Youtube video title  <span className="adverts-form-required"> * </span> </label>
                            <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Youtube video title " required />
                        </div>
                
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold"> Youtube video url <span className="adverts-form-required"> * </span> </label>
                            <input type="text" name="video" ref={(val) => this.video = val} className="form-control" placeholder=" Youtube video url " required />
                        </div>
                        {this.state.isloader === true  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss"/> </>
            : 
                        <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary"> Continue </button>
                      </div>
                         }
                    </form>
                  </>
                              : null }

                <div className="clearfix"></div>

               
            <div className="col-md-12 packvideosssnnn">

          

            {this.context.all_video.length > 0 ?
                <>

                
                {this.context.all_video.map((item, index) => (
                        <div key={item.packvideo_id }>
                            <div className="col-md-4 " style={{padding:0}} >
                            {this.props.storageid  == this.props.useridsss ?
               <>
               <button type="submit" className="btn btn-primary" onClick={() => this.videopackdelete(item.packvideo_id)}> Delete </button>
               
                </>
                  : null
             }
                          
                                <h3> {item.title} </h3>
                            <iframe id="ytplayer" type="text/html" className="videosss2"
                                    src={`https://www.youtube.com/embed/`+item.video+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                                    frameBorder="0" allowFullScreen></iframe> 
                            </div>
                        </div>

                ))}

                    </>
                  : null
             }


            </div>
          




            </div>

        
                     {mainData}
                </div>
                </div>
      
     
      
            
        </>  
        );
    }
}
export default Authorpackagetwo;