import React,{Component} from 'react';
import {AppContext} from '../Context';
import Loader from 'react-loader-spinner'

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from "react-toastify";
import Axios from 'axios';

let BaseURL = 'https://reactbooks.com/api/';
class Packsinglebook extends Component {
  static contextType = AppContext;
  constructor(props) {
       super(props);
       this.state = {
            file: null,
            editorState: EditorState.createEmpty(),
            showResults: false,
         };
       this.onChange = this.onChange.bind(this);
       this.resetFile = this.resetFile.bind(this);
       this.categoryMode = this.categoryMode.bind(this);


         //text editior
       const html = '';
       const contentBlock = htmlToDraft(html);
       if (contentBlock) {
         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
         const editorState = EditorState.createWithContent(contentState);
         this.state = {
           editorState,
         };
       }

     }
 //text editior
 onEditorStateChange = (editorState) => {
       this.setState({
         editorState
       });
     }

     componentDidMount(){
      
      window.scrollTo(0, 0);
   }

//select option
categoryMode(event) {
 //this.setState({value: event.target.value});
 if(event.target.value === 'other')
 { 
   //alert(event.target.value);
   this.setState({ showResults: true });

 }else{
   this.setState({ showResults: false });
 }

} 


    //image preview       
    onChange(event) {
        this.setState({
              file: URL.createObjectURL(event.target.files[0])
        });
  }
  resetFile(event) {
         event.preventDefault();
         this.setState({ file: null });
       }  
       

       authorbooksAddsingle = (event) => {
        this.setState({ isLoading: true });
    event.preventDefault();event.persist();
    const formData = new FormData();
    formData.append('packsingleId',this.props.match.params.packsingleId)
    formData.append('title',this.title.value)
    formData.append('avatar',this.file.files[0])
    formData.append('description',this.description.value)
    formData.append('price',this.price.value)
    formData.append('purchase',this.purchase.value)
    formData.append('category',this.category.value)
    formData.append('descothers',this.descothers.value)
    formData.append('author',this.author.value)
    
    return Axios.post(BaseURL+'authordetailssssingle.php',formData,{
        headers: {'content-type': 'multipart/form-data'}
    })
    .then(res => { 
        console.log(res.data);
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Nofilewassent' ){
            toast.error('No file was sent!');
            this.setState({ isLoading: false });
        }else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === 'successfully' ) {
            toast('update successfully')
            this.setState({ isLoading: false });
            event.target.reset();
            this.props.history.push('/')
        }

    })
    .catch(err => { toast.error(err.data);this.setState({ isLoading: false });})

            
          }
   

          render(){
              const { editorState } = this.state;
           
              return (
                     <div className=" addbooksss gray-bg ">
                      <div className="container">
                       <div className="row">
                         <div className="col-md-1">&nbsp;</div>
                          <div className="col-md-10">
                              <div className="central-meta3">
                            <h3 className="aunasss" style={{float:'left'}}> Book Add </h3>
                            <form onSubmit={this.authorbooksAddsingle} className="central-meta">
                      
                      <div className="form-row">
                     
                         
                          <div className="form-group col-md-12">
                              <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                              <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Title" required />
                          </div>
                          <div className="form-group col-md-12">
                              <label className="font-weight-bold"> Book Cover Image <span className="adverts-form-required"> * </span> </label>
                              <input type="file" name="file" ref={(val) => this.file = val} className="form-control" placeholder="Title"  onChange={this.onChange} required/>
                          </div>
                          {this.state.file && (
                            <div style={{ textAlign: "center" }}>
                                 <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                                <button onClick={this.resetFile} className="removeqqq">Remove File</button>
                            </div>
                            )}
                            
                            <div className="form-group col-md-12 editor">
                            <label className="font-weight-bold"> Description <span className="adverts-form-required"> * </span> </label>
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange}
                                    
                                />
                               <textarea style={{display:'none'}}
              disabled ref={(val) => this.description = val}
              value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
            />
                            </div>
          
                          <div className="form-group col-md-12">
                              <label className="font-weight-bold"> Price  </label>
                              <input type="text" name="price" ref={(val) => this.price = val} className="form-control" placeholder="Price" />
                          </div>
                          <div className="form-group col-md-12">
                              <label className="font-weight-bold"> Book Purchase Link  </label>
                              <input type="text" name="purchase" ref={(val) => this.purchase = val} className="form-control" placeholder="Book Purchase Link" />
                          </div>
                          <div className="form-group col-md-12">
                              <label className="font-weight-bold"> Category  <span className="adverts-form-required"> * </span> </label>
                              <select  name="category" ref={(val) => this.category = val}  className="wqsss form-control" onClick={this.categoryMode} required>
                                      
                                      <option value=""> Choose the field </option>
                                      <option>Fiction &amp; Literature</option>
                                      <option>Romance</option>
                                      <option>Science Fiction &amp; Fantasy</option>
                                      <option>Comics &amp; Graphic Novels</option>
                                      <option>Fiction - YA</option>
                                      <option>Periodicals</option>
                                      <option>Kids</option>
                                      <option>Non-Fiction</option>
                                      <option>Business &amp; Finance</option>
                                      <option>Biography &amp; Memoir</option>
                                      <option>Mystery &amp; Suspense</option>
                                      <option value="other">Other</option>
                                      
                                    </select>
                                    { this.state.showResults ? 
                                   <div>
                                   <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} defaultValue="" />
                                   </div>
                                    
                                    : 
                                    <div>
                                   <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} style={{display:'none'}}/>
                                   </div>
                                   }
                          </div>
                          <div className="form-group col-md-12">
                              <label className="font-weight-bold"> Author Website  </label>
                              <input type="text" name="author" ref={(val) => this.author = val} className="form-control" placeholder="Author Website" />
                          </div>     
                        
    
                        
                 
                   {this.state.isLoading  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            :  

                  <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary2"> Continue </button>
                      </div>
          }
                  
                  </div>
                  
                  
                  
                  
                 
                  
              </form>  
              </div>
                  </div>
                  </div>
                  </div>
                  </div>
             
              );
          }
      }

export default Packsinglebook;
