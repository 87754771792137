import React, { Component } from 'react'

import { toast } from "react-toastify";
import Axios from 'axios';

import {Redirect, Link } from "react-router-dom"; 

import Loader from 'react-loader-spinner'
let BaseURL = 'https://reactbooks.com/api/';

class Editviewtrailor extends Component {
       constructor(props) {
              super(props);
              this.state = {
                   file: null,
                };
              } 
       componentDidMount(){

              this.trailorsfetch();
              window.scrollTo(0, 0);
      
              }    
      
              trailorsfetch = () => {
      
                  Axios.post(BaseURL+'trailorfetchsss.php',{
                      authordetailsId:this.props.match.params.EdittrailorId,
                  })
                  .then(function ({data}) {
                      if(data.success === 1){
                          this.setState({ 
                             titlesss:data.authorsss[0].title,
                             urlsss:data.authorsss[0].url,
                           });
                      }
                      }.bind(this))
                      .catch(function (error) {
                          console.log(error);
                      });
      
              }

    trailorsAddsingle = (event) => {
       this.setState({ isLoading: true });
   event.preventDefault();event.persist();

    Axios.post(BaseURL+'edittrailors.php',{
        title:this.title.value,
        video:this.video.value,
        viewid:this.props.match.params.EdittrailorId,
    })

   .then(res => { 
       event.target.reset();
       this.props.history.push('/8a6048fc3f9c43ece54e1321ecdc2cbf')

   })
   .catch(err => { toast.error(err.data);this.setState({ isLoading: false });})
         }


  render() {
    return (
        <div className="myaccounts gray-bg">
        <div className="container">
        <div className="row">
        <div className="pagsssqqq">
          <div className="container">
             <div className="row">
             <div className="col-md-12">
             <section className="breadcrumb-section">
      <h2 className="sr-only">Site Breadcrumb</h2>
      <div className="container">
        <div className="breadcrumb-contents">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
              <li className="breadcrumb-item active"> Edit Book Trailer </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
    </div>
    </div>
    </div>
    </div>
    <div className="col-md-12">
      <div className="col-md-1">&nbsp;</div>
    <div className="col-md-10 addbooksss" style={{paddingTop:'17px'}}>
    <form onSubmit={this.trailorsAddsingle} className="central-meta">
        
    <h3 style={{color: '#fd6500',marginBottom:'17px'}}> Edit Book Trailer </h3>
    <div className="central-meta">
        

                    <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Youtube video title  <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Youtube video title " required  defaultValue={this.state.titlesss}/>
                 </div>
               
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Youtube video url </label>
                     <input type="text" name="video" ref={(val) => this.video = val} className="form-control" placeholder=" Youtube video url " defaultValue={this.state.urlsss}/>
                 </div>
                 
               

                
          {this.state.isLoading  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 
                <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary2"> Continue </button>
                      </div>

                }


    </div>
    </form>  
    </div>
    </div>
    </div>
    </div>
    </div>
    
    )
  }
}

export default Editviewtrailor
