
import React,{Component} from 'react';
import {AppContext} from '../Context';

import Publishereditpreviewall from '../Components/Publishereditpreviewall';

import Loader from 'react-loader-spinner'
import ContentLoader from "react-content-loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userphotos from '../Img/sss.png';
let BaseURL = 'https://reactbooks.com/api/';

class Publishereditpreview extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
          
            isEditing:false,
         };
         
     
    
     }

    

      componentDidMount(){
          this.viewPublisherPreview();
          
    }
    viewPublisherPreview = () => {
        this.context.viewPublisherPreview(this.props.id);
    }




    render(){
        let allUsers;
        let mainData;
      
       
        allUsers = this.context.all_books.map(({publisher_id,userid,title,bookcover,description,email,phone,websitelink,user_name,profile_image,isEditing}) => {
            
            return isEditing === true ? (
                   <div key={publisher_id}>
                        
                           <div className="form-row">
                             <Publishereditpreviewall desc={description} books_id={publisher_id} title={title} bookcover={bookcover} email={email} phone={phone}  websitelink={websitelink} loadingsssedits ={this.props.loadingsss}/>
                            
             {/* {this.props.desc} */}
             </div>
 
            </div>
            ) :  (
               
                <div key={publisher_id}>
                <div className="col-md-12">

                <div className="central-metazzz">

    {/* ------------------------------ Loader start ----------------------------------------------------- */}
    
                    {this.props.loadingsss ? 
                    <><Loader type="TailSpin" color="#fd6500" height={67} width={67} style={{textAlign:"center"}} /></>
                    : 
                    <>
                    {this.props.storageid === userid ?
               <>
                          <button className="zzz" onClick={() => this.context.editModepublisherpreview(publisher_id)}>Edit</button>

                          </>
                  : null
             }
                          <h2 className="create-post">{title}</h2>




                          <div className="col-md-3">
                          <img src={BaseURL+bookcover}  alt="" style={{ maxWidth: '177px',marginBottom:'37px'}}/>
                          </div>
                          <div className="col-md-9">
                          <div className="col-md-9" style={{padding:'0px'}} >
                         <div className="zzz2">
                         
                          <h3> <FontAwesomeIcon icon={['fas','envelope']} className="iconsss2" />  Email : <span> {email}</span></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','phone-square-alt']} className="iconsss2" /> Phone : <span> {phone}</span></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" /> Website Link  : <a href={websitelink} rel="noopener noreferrer" target="_blank"> {websitelink}</a></h3>

                          </div>
                          </div>
                          <div className="col-md-3" style={{padding:'0px'}}>
                                <div className="yyyview">
                                {profile_image === '' ?
                                <img src={userphotos} alt="books" />
                                :
                                <img src={BaseURL + profile_image} alt="" />
                                }
                                <h3> {user_name} </h3>
                                </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                        
                          <div className="redmoesss">
                          <h4> <b>About publisher :</b> </h4>
		                  {/* {descriptionviews} */}
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                            </div>



                                </>
                             }
                         
     {/* ------------------------------- Loader End ----------------------------------------------------- */}
                        
                        </div>
                        </div>
                        
                
                         </div>
                        
          
            );
        });

        if(this.context.all_books.length > 0){
            mainData = (
              <div>
                      {allUsers}
              </div>
                   
            );
        }
        else{
            mainData = (
                <div className="alert alert-light" role="alert">
                    <h4 className="alert-heading"> Please Wait </h4>
                    <hr/>
                    <ContentLoader  height={260} width={400} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
                </div>
            );
        }
        return (
            < >
           
                {/* <h3> Pass Value :{this.props.id} </h3> */}
                
                {mainData}
      
     
      
            
        </>  
        );
    }
}
export default Publishereditpreview;