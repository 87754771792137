import React, { Component } from 'react'
import { EditorState,  convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
import {AppContext} from '../Context';


import Loader from 'react-loader-spinner'
class Audioaddpreviewpagetwo extends Component {
       
    static contextType = AppContext;

  constructor(props) {
    super(props)
    this.state = {
       file: null,
       showResults: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.desc)
        )
      ),
    }
    this.onChange = this.onChange.bind(this);
       this.resetFile = this.resetFile.bind(this);
  }

//image preview       
onChange(event) {
       this.setState({
             file: URL.createObjectURL(event.target.files[0])
       });
 }
 resetFile(event) {
        event.preventDefault();
        this.setState({ file: null });
      }
 
  //text editior
    onEditorStateChange = (editorState) => {
      this.setState({
        editorState
      });
    }
 
  
 componentDidMount(){

     this.context.pricefetch();
    
 }




 previewaudioUpdate = (audio_id) => {
        this.context.previewaudioUpdate(audio_id,this.title.value,this.written.value,this.narrated.value,this.bookcover.files[0],this.description.value,this.hrs.value,this.mins.value,this.price.value,this.purchaselink.value,this.websitelink.value);
   }

  render() {
       const { editorState } = this.state;
    return (
    
    <div className="col-md-12">
      <div className="col-md-1">&nbsp;</div>
    <div className="col-md-10">
    <div className="qqqcentral-meta">
    <h5 style={{color: '#fd6500',marginBottom:'17px'}}> Edit Audio Book </h5>
    <div className="central-meta3">

                    <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Title" required defaultValue={this.props.title}/>
                 </div>
                 <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Written by </label>
                          <input type="text" name="written" ref={(val) => this.written = val} className="form-control" placeholder="Written by" defaultValue={this.props.written} />
                      </div> 
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Narrated by  </label>
              <input type="text" name="narrated" ref={(val) => this.narrated = val} className="form-control" placeholder="Narrated by" defaultValue={this.props.narrated} />
                      </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Book Cover Image  </label>
                     <input type="file" name="file" ref={(val) => this.bookcover = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                 </div>
                 {this.state.file && (
                   <div style={{ textAlign: "center" }}>
                        <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                       <button onClick={this.resetFile} class="removeqqq" >Remove File</button>
                   </div>
                   ) }
                    
                   <div className="form-group col-md-12 editor">
                   <label className="font-weight-bold"> Description <span className="adverts-form-required"> * </span> </label>
                       <Editor
                           editorState={editorState}
                           toolbarClassName="toolbarClassName"
                           wrapperClassName="wrapperClassName"
                           editorClassName="editorClassName"
                           onEditorStateChange={this.onEditorStateChange}
                           defaultEditorState={editorState}
                           
                       />
                      <textarea style={{display:'none'}}
     disabled ref={(val) => this.description = val}
     value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
   />
                   </div> 
                   <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Audio Length  </label>
                          <div className="form-group col-md-6" style={{paddingLeft:0}}>
                             <input type="text" name="hrs" ref={(val) => this.hrs = val} className="form-control" placeholder="hrs" defaultValue={this.props.hrs} />
                            </div>
                            <div className="form-group col-md-6" style={{paddingRight:0,paddingLeft:'17px'}}>
                             <input type="text" name="mins" ref={(val) => this.mins = val} className="form-control" placeholder="mins" defaultValue={this.props.mins} />
                            </div> 


                      </div> 
 
               <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Price  </label>
                     <input type="text" name="price" ref={(val) => this.price = val} className="form-control" placeholder="Price" defaultValue={this.props.price} />
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Book Purchase Link  </label>
                     <input type="text" name="purchase" ref={(val) => this.purchaselink = val} className="form-control" placeholder="Book Purchase Link" defaultValue={this.props.purchaselink}/>
                 </div>
               
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Author Website  </label>
                     <input type="text" name="author" ref={(val) => this.websitelink = val} className="form-control" placeholder="Author Website" defaultValue={this.props.websitelink}/>
                 </div>     
              




               

                
          {this.props.loadingsssedits  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 
                 <div className="form-group col-sm-12 text-right">
                     <button type="submit" className="btn btn-primary2" onClick={() => this.previewaudioUpdate(this.props.audio_id)}> Continue </button>
                     <button onClick={() => this.context.audiocancelEdit(this.props.audio_id)} className="btn btn-light canqqq">Cancel</button>
                 </div>

                }


    </div>
    </div>
    </div>
    </div>
    
    )
  }
}

export default Audioaddpreviewpagetwo
