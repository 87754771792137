import React from 'react';
import Axios from 'axios';
import { toast } from "react-toastify";
//import { Link } from "react-router-dom";

let BaseURL = 'https://reactbooks.com/api/';
//let BaseURL = 'http://localhost/2020/reactbooks/';
//let BaseNodeURL = 'http://192.168.0.128:4000/';

class Actions extends React.Component{
    state = {
        booksAddPreview:[],
        audioAddPreview:[],
        trailorAddPreview:[],
        publisherAddPreview:[],
        counts:[],
        packs:[],
        profileimages:[],
        isLoading: false,
        recentAuthorLogin:[],
        allprices:[],
        recommentedlist:[],
        recommentedlistall:[],
        featuredlist:[],
        standardlist:[],
        standardtoplist:[],
        authorpackagelist:[],
        authorpack:[],
        publishersviewlist:[],
        trailorview:[],
        featuredviewall:[],
        audiobooksall:[],
        latestlist:[],
        packPreviewlist:[],
        basiclist:[],
        trailorviewbasiclist:[],
        packPreviewlistauthor:[],
        packbookslistvideo:[],
        twitterpack:[],
        booksallqqq:[],
        videopack:[],
        coulisss:[],
        amoulisss:[],
    }
// REGISTER
Register = (event,user_name,user_email,password,conformpassword,recaptcha_response) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    //console.log(recaptcha_response);
    Axios.post(BaseURL+'Register.php',{
        user_name:user_name,
        user_email:user_email,
        password:password,
        conformpassword:conformpassword,
        recaptcha_response:recaptcha_response,
    })
    .then(function ({data}) {
        if(data.success === 1){
            this.setState({ isLoading: false });
            toast('Create an account successfully');
            this.LoginMode();
            event.target.reset();
        }
        else{
            this.setState({ isLoading: false });
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }
    }.bind(this))
    .catch(function (error) {
        console.log(error);
        this.setState({ isLoading: false });
    });
}
// AUTO INSERT vistiorsCounts
autoInsertdata = () => {
    Axios.post(BaseURL+'autoinsert.php',)
    .then(function ({data}) {
    }.bind(this))
    .catch(function (error) {
        console.log(error);
    });
}
// FETCH vistiorsCounts FROM DATABASE
fetchVistiorsCounts = () => {
    Axios.get(BaseURL+'visitorcount.php')
    .then(({data}) => {
        if(data.success === 1){
            //console.log(data.counts);
            this.setState({
                counts:data.counts            
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
}
//  USER LOGIN
userLogin = (event,email,password) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    Axios.post(BaseURL+'login.php',{
        email:email,
        password:password
    })
    .then(function ({data}) {
        if(data.success === 1){
            //console.log(data.users[0].email);
            this.setState({ isLoading: false });
            localStorage.setItem('tockenusername',JSON.stringify(data.users[0].user_name));
            localStorage.setItem('tockenemail',JSON.stringify(data.users[0].email));
            localStorage.setItem('tockenid',JSON.stringify(data.users[0].react_user_id));         
            event.target.reset();
           toast.success('Welcome ' + data.users[0].user_name, {
            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });       
          this.props.history.push('/2383afa57c522277b6e31e96849610d5')
            // let userstoragemail = JSON.parse(localStorage.getItem('tockenusername'));
            // console.log(" view storage data id :" + userstoragemail);
        }
        else{
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
              this.setState({ isLoading: false });
        }
    }.bind(this))
    .catch(function (error) {
        //console.log(error);
        this.setState({ isLoading: false });
    });
}
//  Forget Password
ForgetMail = (event,user_email) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    Axios.post(BaseURL+'forgetpassword.php',{
        user_email:user_email,
    })
    .then(function ({data}) {
        console.log(data);
        if(data.success === 1){
           toast.success(data.msg,  {
            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
          event.target.reset();
          this.setState({ isLoading: false });
        }
        else{
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
              this.setState({ isLoading: false });
        }
    }.bind(this))
    .catch(function (error) { //console.log(error); 
        this.setState({ isLoading: false });
    });
}
// BOOKS ADD
booksAdd = (event,storageid,title,file,description,price,purchase,category,descothers,author,selector) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    const formData = new FormData();
    formData.append('storageid',storageid)
    formData.append('avatar',file)
    formData.append('title',title)
    formData.append('description',description)
    formData.append('price',price)
    formData.append('purchase',purchase)
    formData.append('category',category)
    formData.append('descothers',descothers)
    formData.append('author',author)
    formData.append('selector',selector)
    //console.log(file);
    //console.log(title);
    //console.log(title);
    return Axios.post(BaseURL+'add-books.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Nofilewassent' ){
            toast.error('No file was sent!');
            this.setState({ isLoading: false });
        }else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data[0].books_id !== '' ) {
            this.setState({
                books:res.data[0].books_id,
                isLoading: false
            });     
            //toast.success(res.data[0].books_id)
            //this.addbooksView(res.data[0].id)
            this.props.history.push('/ae1ce6f4a83e70734418c6a77c9e984e/'+res.data[0].books_id)
            event.target.reset();
        }
        //console.log(res.data[0].id);
        //window.location = 'http://localhost:3000/about/'
        //this.props.history.push('/champions/'+id)
    })
    .catch(err => {  toast.error(err.data); this.setState({ isLoading: false }); })   
}
// FETCH VIEW BOOKS FROM DATABASE
viewBooksPreview = (id) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'viewbooks.php',{
        addbooksid:id,
    })
    .then(({data}) => {
        //console.log(data.books[0].description);
        if(data.success === 1){
            this.setState({
                booksAddPreview:data.books,
                isBooksView : true,
                isLoading: false
            });
        }
    })
    .catch(error => { console.log(error); this.setState({ isLoading: false }); })
}
// ON EDIT MODE PREVIEW
editModepreview = (id) => {
//editModepreview = (id,descriptionviews,paymentbook) => {
    let editbooks = this.state.booksAddPreview.map(bookview => {
        if(bookview.books_id === id){      
            bookview.isEditing = true;
            return bookview;
        }
        bookview.isEditing = false;
        return bookview;      
    });
    this.setState({ editbooks});   
}
// UPDATE USER
previewbookUpdate = (id,useridsss,title,bookcover,description,price,purchaselink,category,descothers,websitelink,packagePlan) => {
    this.setState({ isLoading: true });
    const formData = new FormData();  
    formData.append('id',id)
    formData.append('useridsss',useridsss)
    formData.append('title',title)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('price',price)
    formData.append('purchaselink',purchaselink)
    formData.append('category',category)
    formData.append('descothers',descothers)
    formData.append('websitelink',websitelink)
    formData.append('packagePlan',packagePlan)
    return Axios.post(BaseURL+'updateupload.php',formData,{
        headers: {'content-type': 'multipart/form-data' }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === id  ) {                      
            toast.success('Edit Successfull')
            this.viewBooksPreview(res.data);
            let editbooks = this.state.booksview.map(bookview => {
                if(bookview.id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({editbooks,isLoading: false});
        }     
    })
    .catch(err => { toast.error(err.data); this.setState({ isLoading: false });
    })
}
// Coupen code
coupenActivated = (books_id,coupenCode,bookAmount,bookId) => {
    Axios.post(BaseURL+'get_discount.php',{
        coupenCode:coupenCode,
        bookAmount:bookAmount,
        bookId:bookId,
    })
    .then(({data}) => {
        if(data.success === 1){
            toast(data.msg);
            //event.target.reset();
            this.viewBooksPreview(books_id);
        }
        else{
            toast.error(data.msg, {
                position: "top-right",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }     
    })
    .catch(error => {
        console.log(error);
    })
}
 //CANCEL EDIT MODE
 cancelEdit = (id) => {
    let cancelBooks = this.state.booksAddPreview.map(books => {
        if(books.books_id === id){
            books.isEditing = false;
            return books;
        }
        return books       
    });
    this.setState({
        cancelBooks
    });
}
// Recent Login
fetchrecentlogin = () => {
    Axios.post(BaseURL+'recentlogin.php')
    .then(({data}) => {
        //console.log(data.latestLogin);
        if(data.success === 1){
            this.setState({
                recentAuthorLogin:data.latestLogin,
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
}
// price
fetchprice = () => {
    Axios.post(BaseURL+'price.php')
    .then(({data}) => {
        //console.log(data.priceslist[0].standard);
        if(data.success === 1){
            this.setState({
                allprices:data.priceslist,
                standard:data.priceslist[0].standard,
            });
        }
    })
    .catch(error => {
        console.log(error);
    })
}
// User Picture Add
userphotosAdd = (event,file,storageid) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    const formData = new FormData();
    formData.append('avatar',file)
    formData.append('storageid',storageid)
    return Axios.post(BaseURL+'add-userphotos.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
       // console.log(res.data.profileimages);
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!')
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.')
        } else if(res.data === 'Nofilewassent' ){
            toast.error('No file was sent!')
        }else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.')
        }else if(res.data.success === 1) {
            this.setState({
                profileimages:res.data.profileimages,
            });
            toast.success('Upload Success')
            event.target.reset();
        }
        this.setState({isLoading: false});
    })
    .catch(err => { 
        toast.error(err.data);
        this.setState({isLoading: false});
    })
}
// FETCH profileimages FROM DATABASE
fetchprofileimages = (storageid) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'profileimages.php',{
        storageid:storageid,
    })
    .then(({data}) => {
        if(data.success === 1){
            //console.log(data.profileimages);
            this.setState({
                profileimages:data.profileimages,
                isLoading: false
            });
        }
    })
    .catch(error => {
        console.log(error);
        this.setState({isLoading: false});
    })
}
//  Change Password
changePassword = (event,newpassword,confirmpassword,id) => {
    //console.log(newpassword);
    //console.log(confirmpassword);
    //console.log(id);
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    Axios.post(BaseURL+'changepassword.php',{
        newpassword:newpassword,
        confirmpassword:confirmpassword,
        id :id,
    })
    .then(function ({data}) {
        if(data.success === 1){
            this.setState({ isLoading: false });
            event.target.reset();
           toast.success('Change Password Successfully ', {
            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
        }
        else{
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
              this.setState({ isLoading: false });
        }
    }.bind(this))
    .catch(function (error) {
        this.setState({ isLoading: false });
    });
}
// Audio Book Add
audiobooksAdd = (event,storageid,title,written,narrated,file,description,hrs,mins,price,purchase,author,selector) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    const formData = new FormData();
    formData.append('storageid',storageid)
    formData.append('avatar',file)
    formData.append('title',title)
    formData.append('written',written)
    formData.append('narrated',narrated)
    formData.append('description',description)
    formData.append('hrs',hrs)
    formData.append('mins',mins)
    formData.append('price',price)
    formData.append('purchase',purchase)
    formData.append('author',author)
    formData.append('selector',selector)
    return Axios.post(BaseURL+'add-audio.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Nofilewassent' ){
            toast.error('No file was sent!');
            this.setState({ isLoading: false });
        }else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data[0].audio_id !== '' ) {
            this.setState({
                books:res.data[0].audio_id,
                isLoading: false
            });        
            this.props.history.push('/b83845935b160ef9b990c0f98d490779/'+res.data[0].audio_id)
            event.target.reset();
        }      
    })
    .catch(err => {  toast.error(err.data);       this.setState({ isLoading: false });
    })
}
// FETCH VIEW Audio Book FROM DATABASE
viewaudioPreview = (id) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'/viewaudio.php',{
        addbooksid:id,
    })
    .then(({data}) => {
        //console.log(data.audio);
        if(data.success === 1){
            this.setState({
                audioAddPreview:data.audio,
                isBooksView : true,
                isLoading: false
            });
        }
    })
    .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
    })
}
// ON EDIT MODE PREVIEW
editModeaudiopreview = (id) => {
    let editbooks = this.state.audioAddPreview.map(bookview => {
        if(bookview.audio_id === id){
            bookview.isEditing = true;
            return bookview;
        }
        bookview.isEditing = false;
        return bookview;
    });
    this.setState({editbooks});
}
// UPDATE USER AUDIO
previewaudioUpdate = (id,title,written,narrated,bookcover,description,hrs,mins,price,purchaselink,websitelink) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('id',id)
    formData.append('title',title)
    formData.append('written',written)
    formData.append('narrated',narrated)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('hrs',hrs)
    formData.append('mins',mins)
    formData.append('price',price)
    formData.append('purchaselink',purchaselink)
    formData.append('websitelink',websitelink)
    //console.log(bookcover);
    //console.log(title);
    //console.log(websitelink);
    return Axios.post(BaseURL+'updateuploadaudio.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === id  ) {
            toast.success('Edit Successfull')
            this.viewaudioPreview(res.data);
            let editbooks = this.state.audioAddPreview.map(bookview => {
                if(bookview.id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({
                editbooks,
                isLoading: false
            });
        }
    })
    .catch(err => { 
        toast.error(err.data);
        this.setState({ isLoading: false });
    })
}
//CANCEL EDIT MODE
audiocancelEdit = (id) => {
    let cancelBooks = this.state.audioAddPreview.map(books => {
        if(books.audio_id === id){
            books.isEditing = false;
            return books;
        }
        return books
    });
    this.setState({
        cancelBooks
    });
}
// Coupen code
coupenActivatedAudio = (books_id,coupenCode,bookAmount,bookId) => {
    Axios.post(BaseURL+'get_discountaudio.php',{
        coupenCode:coupenCode,
        bookAmount:bookAmount,
        bookId:bookId,
    })
    .then(({data}) => {
        if(data.success === 1){
            toast(data.msg);
            //event.target.reset();
            this.viewaudioPreview(books_id);
            let editbooks = this.state.audioAddPreview.map(bookview => {
                if(bookview.books_id === books_id){                  
                    bookview.isEditing = true;
                    return bookview;
                }
                bookview.isEditing = false;
                return bookview;          
            });
            this.setState({
                editbooks,
            });
        }
        else{
            toast.error(data.msg, {
                position: "top-right",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }      
    })
    .catch(error => {
        console.log(error);
    })
}
// Trailor Add
trailorsAdd = (event,storageid,title,video,selector) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    Axios.post(BaseURL+'traileraddsss.php',{
        storageid:storageid,
        title:title,
        video:video,
        selector:selector
    })
    .then(function ({data}) {
        //console.log(data.id)
        if(data.success === 1){
            this.setState({ 
                books:data.id,
                isLoading: false
            });      
            this.props.history.push('/be9d3adebe148e4137c0d82fc5fd0a38/'+data.id)
            event.target.reset();
        }
        else{
            this.setState({ isLoading: false });
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }
    }.bind(this))
    .catch(function (error) {
        console.log(error);
        this.setState({ isLoading: false });
    });
}
// FETCH VIEW Trailor FROM DATABASE
viewtrailorPreview = (id) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'/viewtrailor.php',{
        addbooksid:id,
    })
    .then(({data}) => {
        //console.log(data.trailor);
        if(data.success === 1){
            this.setState({
                trailorAddPreview:data.trailor,
                isBooksView : true,
                isLoading: false
            });
        }
    })
    .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
    })
}
// ON EDIT MODE PREVIEW trailer
editModetrailerpreview = (id) => {
    //editModepreview = (id,descriptionviews,paymentbook) => {     
        let editbooks = this.state.trailorAddPreview.map(bookview => {
            if(bookview.video_id === id){
                bookview.isEditing = true;
                return bookview;
            }
            bookview.isEditing = false;
            return bookview;         
        });
        this.setState({
            editbooks,
        });   
    }   
 // UPDATE USER TRAILOR
 previewtrailerUpdate = (id,title,video,selector) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'trailereditsss.php',{
        viewid:id,
        title:title,
        video:video,
        selector:selector
    })
    .then(function ({data}) {
       // console.log(data.id);
        if(data.success === 1){
            toast.success('Edit Successfull')
            this.viewtrailorPreview(data.id);
            let editbooks = this.state.trailorAddPreview.map(bookview => {
                if(bookview.video_id === data.id){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({
                editbooks,
                isLoading: false
            });
        }
        else{
            this.setState({ isLoading: false });
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }
    }.bind(this))
    .catch(function (error) {
        console.log(error);
        this.setState({ isLoading: false });
    });  
}
// Coupen code
coupenActivatedtrailor = (video_id,coupenCode,bookAmount) => {
    Axios.post(BaseURL+'get_discounttrailor.php',{
        coupenCode:coupenCode,
        bookAmount:bookAmount,
        bookId:video_id,
    })
    .then(({data}) => {
        if(data.success === 1){
            toast(data.msg);
            //event.target.reset();
            this.viewtrailorPreview(video_id);        
        }
        else{
            toast.error(data.msg, {
                position: "top-right",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }
    })
    .catch(error => {
        console.log(error);
    })
}
//CANCEL EDIT MODE
cancelTrailorEdit = (id) => {
    let cancelBooks = this.state.trailorAddPreview.map(books => {
        if(books.video_id === id){
            books.isEditing = false;
            return books;
        }
        return books    
    });
    this.setState({
        cancelBooks
    });
}
// PUBLISHER ADD
publishersAdd = (event,storageid,title,file,description,email,phone,author,selector) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    const formData = new FormData();
    formData.append('storageid',storageid)
    formData.append('avatar',file)
    formData.append('title',title)
    formData.append('description',description)
    formData.append('email',email)
    formData.append('phone',phone)
    formData.append('author',author)
    formData.append('selector',selector)
    return Axios.post(BaseURL+'publisheraddsss.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Nofilewassent' ){
            toast.error('No file was sent!');
            this.setState({ isLoading: false });
        }else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data[0].publisher_id  !== '' ) {
            this.setState({
                books:res.data[0].publisher_id ,
                isLoading: false
            });     
            this.props.history.push('/011762c5e30adb043a868e07ca51ebdb/'+res.data[0].publisher_id )
            event.target.reset();
        }
    })
    .catch(err => {  toast.error(err.data); this.setState({ isLoading: false });
    })   
}
// FETCH VIEW BOOKS FROM DATABASE
viewPublisherPreview = (id) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'viewpublishers.php',{
        addbooksid:id,
    })
    .then(({data}) => {
        //console.log(data.books[0].description);
        if(data.success === 1){
            this.setState({
                publisherAddPreview:data.publisher.reverse(),
                isBooksView : true,
                isLoading: false
            });
        }
    })
    .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
    })
}
// ON EDIT MODE PREVIEW
editModepublisherpreview = (id) => {
//editModepreview = (id,descriptionviews,paymentbook) => {
    let editbooks = this.state.publisherAddPreview.map(bookview => {
        if(bookview.publisher_id === id){
            bookview.isEditing = true;
            return bookview;
        }
        bookview.isEditing = false;
        return bookview;       
    });
    this.setState({
        editbooks,
        //desc:descriptionviews,
        //paymentPlans:paymentbook,
    }); 
}
// UPDATE USER
previewpublisherUpdate = (id,title,bookcover,description,email,phone,websitelink) => {
    this.setState({ isLoading: true });
    const formData = new FormData();   
    formData.append('id',id)
    formData.append('title',title)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('email',email)
    formData.append('phone',phone)
    formData.append('websitelink',websitelink)
    //console.log(websitelink);
    return Axios.post(BaseURL+'publisherupdate.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === id  ) {       
            toast.success('Edit Successfull')
            this.viewPublisherPreview(res.data);
            let editbooks = this.state.publisherAddPreview.map(bookview => {
                if(bookview.id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({
                editbooks,
                isLoading: false
            });
        }    
    })
    .catch(err => {   toast.error(err.data); this.setState({ isLoading: false }); })
}
// Coupen code
coupenpublisherActivated = (books_id,coupenCode,bookAmount,bookId) => {
    Axios.post(BaseURL+'get_discountpublisher.php',{
        coupenCode:coupenCode,
        bookAmount:bookAmount,
        bookId:bookId,
    })
    .then(({data}) => {
        if(data.success === 1){
            toast(data.msg);
            //event.target.reset();
            this.viewPublisherPreview(books_id);
        }
        else{
            toast.error(data.msg, {
                position: "top-right",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }  
    })
    .catch(error => {
        console.log(error);
    })
}
 //CANCEL EDIT MODE
 cancelPublisherEdit = (id) => {
    let cancelBooks = this.state.publisherAddPreview.map(books => {
        if(books.publisher_id === id){
            books.isEditing = false;
            return books;
        }
        return books     
    });
    this.setState({
        cancelBooks
    });
}
// book list
// FETCH vistiorsCounts FROM DATABASE
fetchbooklistPackage = () => {Axios.get(BaseURL+'package.php')
    .then(({data}) => {
       // console.log(data.package);
        if(data.success === 1){ this.setState({packs:data.package });       
        }
        this.fetchRecomentedbooks();
        this.fetchFeaturedbooks();
        this.fetchStandardtopbooks();
    }).catch(error => {console.log(error);})
}
// Recommended Books --- php --- (BaseURL+'recommented.php')
fetchRecomentedbooks = () => { Axios.get(BaseURL+'recommented.php')
    .then(({data}) => {//console.log(data.recommented);
        if(data.success === 1){ this.setState({ recommentedlist:data.recommented });       
    }
    }).catch(error => {console.log(error); })
}
// Featured Books --- php --- (BaseURL+'featured.php')
fetchFeaturedbooks = () => { Axios.get(BaseURL+'featured.php')
    .then(({data}) => {//console.log(data.featured);
        if(data.success === 1){ this.setState({ featuredlist:data.featured });
    }
    }).catch(error => {console.log(error); })
}
// Standard top Books --- php --- (BaseURL+'standardtop.php')
fetchStandardtopbooks = () => { Axios.get(BaseURL+'standardtop.php')
    .then(({data}) => {//console.log(data.standardtopsss);
        if(data.success === 1){ this.setState({ standardtoplist:data.standardtopsss });
    }
        this.fetchauthorpackage();
        this.fetchauthorpack();
        this.fetchLatestbooks();
    }).catch(error => {console.log(error); })
}
// Latest Books --- php --- (BaseURL+'latestbook.php')
fetchLatestbooks = () => { Axios.get(BaseURL+'latestbook.php')
    .then(({data}) => {//console.log(data.standard);
        if(data.success === 1){ this.setState({ latestlist:data.latestbook });   
    }
    }).catch(error => {console.log(error); })
}
// Author Pack Books --- php --- (BaseURL+'authorpackage.php')
fetchauthorpackage = () => { Axios.get(BaseURL+'authorpackage.php')
    .then(({data}) => {//console.log(data.authorpackage);
        if(data.success === 1){ this.setState({ authorpackagelist:data.authorpackage, 
            //authorpackagebooks:data.packagebooksid  --- php
        });
    }
    }).catch(error => {console.log(error); })
}
// Author Pack Books --- php --- (BaseURL+'authorpack.php')
fetchauthorpack = () => { Axios.get(BaseURL+'authorpack.php')
    .then(({data}) => { //console.log(data.packagebooksid);
        if(data.success === 1){ this.setState({ authorpack:data.authorpackall,
          username: data.authorpackall[0].user_name,
          authordetails: data.authordetails,
          profile_imagepack: data.authorpackall[0].profile_image,
          user_id: data.authorpackall[0].user_id,
          books_id: data.authorpackall[0].books_id,
          facebook: data.facebook,
          twitter: data.twitter,
          linkedin: data.linkedin,
        });
        this.fetchtwitter(data.packagebooksid);
        this.fetchtvideopack(data.packagebooksid);
        this.fetchRecomentedbooksall();
    }
    }).catch(error => {console.log(error); })
}
// twitter Books all --- php --- (BaseURL+'twitter.php')
fetchtwitter = (packsidsss) => { Axios.post(BaseURL+'twitter.php',{
    twitteridsss:packsidsss,
})
    .then(({data}) => {//console.log(data);
        if(data.success === 1){ this.setState({ twitterpack:data.twitterfeed });}
        
    }).catch(error => {console.log(error); })
}
// video Books all --- php --- (BaseURL+'video.php')
fetchtvideopack = (packsidsss) => { Axios.post(BaseURL+'video.php',{
    videoqqqidsss:packsidsss,
})
    .then(({data}) => {//console.log(data);
        if(data.success === 1){ this.setState({ videopack:data.packvideosss });
    }
    }).catch(error => {console.log(error); })
}
// Recommended Books all --- php --- (BaseURL+'recommentedall.php')
fetchRecomentedbooksall = () => { Axios.get(BaseURL+'recommentedall.php')
    .then(({data}) => {//console.log(data.recommentedall);
        if(data.success === 1){ this.setState({ recommentedlistall:data.recommentedall });
        this.fetchPublishersbooksall();
        this.fetchTrailorsall();
        this.fetchFeaturedbooksssall();
    }
    }).catch(error => {console.log(error); })
}
// Publishers Books all --- php --- (BaseURL+'publishersall.php')
fetchPublishersbooksall = () => { Axios.get(BaseURL+'publishersall.php')
    .then(({data}) => {//console.log(data.publishersview);
        if(data.success === 1){ this.setState({ publishersviewlist:data.publishersview });
        
    }
    }).catch(error => {console.log(error); })
}
// Book Trailors all --- php --- (BaseURL+'trailorsall.php')
fetchTrailorsall = () => { Axios.get(BaseURL+'trailorsall.php')
    .then(({data}) => {//console.log(data.trailorviewsss);
        if(data.success === 1){ this.setState({ trailorview:data.trailorviewsss });
        
    }
    }).catch(error => {console.log(error); })
}
// Featured Book all --- php --- (BaseURL+'featuredbooksall.php')
fetchFeaturedbooksssall = () => { Axios.get(BaseURL+'featuredbooksall.php')
    .then(({data}) => {//console.log(data.featuredviewsss);
        if(data.success === 1){ this.setState({ featuredviewall:data.featuredviewsss });
    }
    this.fetchAudiobooksssall();
    }).catch(error => {console.log(error); })
}
// Audio Book all --- php --- (BaseURL+'audiobooksall.php')
fetchAudiobooksssall = () => { Axios.get(BaseURL+'audiobooksall.php')
    .then(({data}) => {//console.log(data.audiobooksalllist);
        if(data.success === 1){ this.setState({ audiobooksall:data.audiobooksalllist });
        this.fetchqqqsssall();
    }
    }).catch(error => {console.log(error); })
}
// latest books --- php --- (BaseURL+'booksall.php')
fetchqqqsssall = () => { Axios.get(BaseURL+'booksall.php')
    .then(({data}) => {//console.log(data.audiobooksalllist);
        if(data.success === 1){ this.setState({ booksallqqq:data.booksalllistqqq });
        this.fetchstandardbooks();
    }
    }).catch(error => {console.log(error); })
}
// Standard Books --- php --- (BaseURL+'standard.php')
fetchstandardbooks = () => { Axios.get(BaseURL+'standard.php')
    .then(({data}) => {//console.log(data.standard);
        if(data.success === 1){ this.setState({ standardlist:data.standard });
        this.fetchbasicbooks();
        this.fetchbasicTrailors();
    }
    }).catch(error => {console.log(error); })
}
// Basic Books --- php --- (BaseURL+'basic.php')
fetchbasicbooks = () => { Axios.get(BaseURL+'basic.php')
    .then(({data}) => {//console.log(data.basic);
        if(data.success === 1){ this.setState({ basiclist:data.basic });
       
    }
    }).catch(error => {console.log(error); })
}
// Book Trailors all --- php --- (BaseURL+'trailorsallbasic.php')
fetchbasicTrailors = () => { Axios.get(BaseURL+'trailorsallbasic.php')
    .then(({data}) => {//console.log(data.trailorbasic);
        if(data.success === 1){ this.setState({ trailorviewbasiclist:data.trailorbasic });
    }
    }).catch(error => {console.log(error); })
}
// UPDATE USER VIEW
previewbookUpdateview = (id,title,bookcover,description,price,purchaselink,category,descothers,websitelink) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('id',id)
    formData.append('title',title)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('price',price)
    formData.append('purchaselink',purchaselink)
    formData.append('category',category)
    formData.append('descothers',descothers)
    formData.append('websitelink',websitelink)
    //console.log(websitelink);
    return Axios.post(BaseURL+'updateuploadview.php',formData,{
        headers: { 'content-type': 'multipart/form-data' }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === id  ) {
            toast.success('Edit Successfull')
            this.viewBooksPreview(res.data);
            let editbooks = this.state.booksview.map(bookview => {
                if(bookview.id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({ editbooks, isLoading: false });
        }
    })
    .catch(err => {  toast.error(err.data);this.setState({ isLoading: false }); })
}
// UPDATE USER Publisher
previewpublishereditlist = (id,title,bookcover,description,email,phone,websitelink) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('id',id)
    formData.append('title',title)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('email',email)
    formData.append('phone',phone)
    formData.append('websitelink',websitelink)
    //console.log(websitelink);
    return Axios.post(BaseURL+'publisherupdatelist.php',formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === id  ) {
            toast.success('Edit Successfull')
            this.viewPublisherPreview(res.data);
            let editbooks = this.state.publisherAddPreview.map(bookview => {
                if(bookview.id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({ editbooks,isLoading: false });
        }
    })
    .catch(err => { toast.error(err.data);this.setState({ isLoading: false });})
}
// UPDATE USER AUDIO
previewaudioUpdatelist = (id,title,written,narrated,bookcover,description,hrs,mins,price,purchaselink,websitelink) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('id',id)
    formData.append('title',title)
    formData.append('written',written)
    formData.append('narrated',narrated)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('hrs',hrs)
    formData.append('mins',mins)
    formData.append('price',price)
    formData.append('purchaselink',purchaselink)
    formData.append('websitelink',websitelink)
    return Axios.post(BaseURL+'updateuploadaudiolist.php',formData,{
        headers: { 'content-type': 'multipart/form-data'}
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === id  ) {
            toast.success('Edit Successfull')
            this.viewaudioPreview(res.data);
            let editbooks = this.state.audioAddPreview.map(bookview => {
                if(bookview.id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({
                editbooks,
                isLoading: false
            });
        }
    })
    .catch(err => { 
        toast.error(err.data);
        this.setState({ isLoading: false });
    })
}
// FETCH VIEW Package BOOKS FROM DATABASE
fetchpackPreview = (id) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'packview.php',{ addbooksid:id, })
    .then(({data}) => {
        if(data.success === 1){
            this.setState({ packPreviewlist:data.packbookslist,isBooksView : true,isLoading: false
            });
        }
    })
    .catch(error => {console.log(error); this.setState({ isLoading: false });})
}
// ON EDIT MODE  PACKAGE PREVIEW
editpackpreview = (id) => {
        let editbooks = this.state.packPreviewlist.map(bookview => {
            if(bookview.bookspacks_id === id){
                bookview.isEditing = true;
                return bookview;
            }
            bookview.isEditing = false;
            return bookview;
        });
        this.setState({ editbooks, });
    }
// UPDATE USER PACKAGE VIEW
previewpackUpdate = (bookspacks_id,title,bookcover,description,price,purchaselink,category,descothers,websitelink) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append('bookspacks_id',bookspacks_id)
    formData.append('title',title)
    formData.append('avatar',bookcover)
    formData.append('description',description)
    formData.append('price',price)
    formData.append('purchaselink',purchaselink)
    formData.append('category',category)
    formData.append('descothers',descothers)
    formData.append('websitelink',websitelink)
    //console.log(websitelink);
    return Axios.post(BaseURL+'updatepack.php',formData,{
        headers: { 'content-type': 'multipart/form-data' }
    })
    .then(res => { 
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === bookspacks_id  ) {
            toast.success('Edit Successfull')
            this.fetchpackPreview(res.data);
            let editbooks = this.state.packPreviewlist.map(bookview => {
                if(bookview.bookspacks_id === res.data){
                    bookview.isEditing = false;
                    return bookview;
                }
                bookview.isEditing = true;
                return bookview;
            });
            this.setState({ editbooks, isLoading: false });
        }
    })
    .catch(err => {  toast.error(err.data);this.setState({ isLoading: false }); })
}
//CANCEL EDIT MODE PACK
cancelEditpack = (id) => {
    let cancelBooks = this.state.packPreviewlist.map(books => {
        if(books.bookspacks_id === id){
            books.isEditing = false;
            return books;
        }
        return books 
    });
    this.setState({ cancelBooks});
}
// FETCH VIEW Package BOOKS FROM DATABASE
fetchpackPreviewsauthor = (id) => {
    this.setState({ isLoading: true });
    Axios.post(BaseURL+'packviewauthor.php',{ addbooksid:id, })
    .then(({data}) => {
        if(data.success === 1){
            //console.log(data);
            this.setState({ packPreviewlistauthor:data.packbookslistauthor,isBooksView : true,isLoading: false,
                username:data.packbookslistauthor[0].user_name,
                profileimage:data.packbookslistauthor[0].profile_image,
                userids:data.packbookslistauthor[0].userid,
                bookspacksid:data.packbookslistauthor[0].books_id,
            });
        }
    })
    .catch(error => {console.log(error); this.setState({ isLoading: false });})
}
// FETCH VIEW Package BOOKS FROM DATABASE
fetchpackPreviewsvideo = (id) => {
    Axios.post(BaseURL+'packviewauthorvideo.php',{ addbooksid:id, })
    .then(({data}) => {
        //console.log(data);
        if(data.success === 1){
            this.setState({ packbookslistvideo:data.packbookslistvideos
            });
        } else if(data.success === 0){
            this.setState({ packbookslistvideo:data.packbookslistvideos
            });
        }
    })
    .catch(error => {console.log(error); })
}
CoupencodeAdd = (event,coupenvalue,discount,emailcode) => {
    this.setState({ isLoading: true });
    event.preventDefault();event.persist();
     Axios.post(BaseURL+'coupen.php',{
        coupen:coupenvalue,
        discount:discount,
        emailcode:emailcode,
    })
    .then(function ({data}) {
       console.log(data);
    if(data.success === 1){
           toast(data.msg, {
            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
           this.fetchcoupenlist();
           this.setState({ isLoading: false });
           event.target.reset();
       }
       else{
           this.setState({ isLoading: false });
           toast.error(data.msg, {
               position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
             });
           }
}.bind(this))
.catch(function (error) {
  console.log(error);
  this.setState({ isLoading: false });
});              
      }

 fetchcoupenlist = () => {Axios.get(BaseURL+'coupenlistall.php')
      .then(({data}) => {
         // console.log(data.coupon);
          if(data.success === 1){ this.setState({coulisss:data.coupon });   }
        
      }).catch(error => {console.log(error);})
  }
  fetchamountlist = () => {Axios.get(BaseURL+'amountlistall.php')
      .then(({data}) => {
         // console.log(data.amount);
          if(data.success === 1){ this.setState({amoulisss:data.amount });   }
        
      }).catch(error => {console.log(error);})
  }
 Coupencodedelete = (hisss) => {
    this.setState({ isLoading: true });
     Axios.post(BaseURL+'coupendelete.php',{
        hisss:hisss,
    })
    .then(function ({data}) {
      // console.log(data);
    if(data.success === 1){
           toast(data.msg, {
            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
          this.fetchcoupenlist();
           this.setState({ isLoading: false });
       }
       else{
           this.setState({ isLoading: false });
           toast.error(data.msg, {
               position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
             });
           }
}.bind(this))
.catch(function (error) {
  //console.log(error);
  this.setState({ isLoading: false });
});              
      }

 AmountUpdate = (event,basic,standard,featured,recommented,packagelist,publishers,audio,videostandard,videofeatured,piceids) => {
    this.setState({ isLoading: true });
    event.preventDefault();event.persist();
     Axios.post(BaseURL+'amountupdate.php',{
        basic:basic,
        standard:standard,
        featured:featured,
        recommented:recommented,
        package:packagelist,
        publishers:publishers,
        audio:audio,
        videostandard:videostandard,
        videofeatured:videofeatured,
        piceids:piceids,
    })
    .then(function ({data}) {
       //console.log(data);
    if(data.success === 1){
        this.fetchamountlist();
           toast(data.msg, {
            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
          
           this.setState({ isLoading: false });
           event.target.reset();
       }
       else{
           this.setState({ isLoading: false });
           toast.error(data.msg, {
               position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
             });
           }
}.bind(this))
.catch(function (error) {
  //console.log(error);
  this.setState({ isLoading: false });
});              
      }










}

export default Actions;