import React,{Component} from 'react';
import {AppContext} from '../Context';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner'
class Publisheradd extends Component {
  static contextType = AppContext;
       constructor(props) {
              super(props);
              this.state = {
                   file: null,
                   editorState: EditorState.createEmpty(),
                   showResults: false,
                };
              this.onChange = this.onChange.bind(this);
              this.resetFile = this.resetFile.bind(this);
              this.packageselected = this.packageselected.bind(this);


                //text editior
              const html = '';
              const contentBlock = htmlToDraft(html);
              if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.state = {
                  editorState,
                };
              }

            }

           

       //image preview       
       onChange(event) {
             this.setState({
                   file: URL.createObjectURL(event.target.files[0])
             });
       }
       resetFile(event) {
              event.preventDefault();
              this.setState({ file: null });
            }
       publishersAdd = (event) => {
              this.context.publishersAdd(event,this.props.storageid,this.title.value,this.file.files[0],this.description.value,this.email.value,this.phone.value,this.author.value,this.state.packagePlan);
             
          }
        //text editior
          onEditorStateChange = (editorState) => {
            this.setState({
              editorState
            });
          }

   

            componentDidMount(){
              this.setState({
                packagePlan: 'publishers',
            });

            this.context.pricefetch();

            }


          //radio button
          packageselected(event) {
            //alert(event.target.value);
            this.setState({
              packagePlan: event.target.value
            });
          }
          
          
        
         


          render(){
            const { editorState } = this.state;
            
              return (
                  <form onSubmit={this.publishersAdd} className="central-meta">
                      
                  <div className="form-row">
                 
                     
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher Title <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Publisher Title" required />
                      </div>
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher logo or image  <span className="adverts-form-required"> * </span> </label>
                          <input type="file" name="file" ref={(val) => this.file = val} className="form-control" placeholder="Title"  onChange={this.onChange} required/>
                      </div>
                      {this.state.file && (
                        <div style={{ textAlign: "center" }}>
                             <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                            <button onClick={this.resetFile} className="removeqqq">Remove File</button>
                        </div>
                        )}
                        
                        <div className="form-group col-md-12 editor">
                        <label className="font-weight-bold"> About publisher  <span className="adverts-form-required"> * </span> </label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                
                            />
                           <textarea style={{display:'none'}}
          disabled ref={(val) => this.description = val}
          value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
        />
                        </div>
      
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher Email <span className="adverts-form-required"> * </span>  </label>
                          <input type="text" name="email" ref={(val) => this.email = val} className="form-control" placeholder=" Publisher Email " required />
                      </div>
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher phone(optional) </label>
                          <input type="text" name="phone" ref={(val) => this.phone = val} className="form-control" placeholder="Publisher phone" />
                      </div>
                      
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher website  </label>
                          <input type="text" name="author" ref={(val) => this.author = val} className="form-control" placeholder="Publisher Website" />
                      </div>     
                      <h3> Onetime Fee: Paypal / Credit Card Payment </h3>




                      {this.context.all_allprices.map((item, index) => ( 

                     <div className="col-md-12 gray-bg"  key={item.price_id}>
                    





                    <div className="col-md-6">
                      &nbsp;
     
                    </div>


                

                   
                    <div className="col-md-6" style={{padding:"0px 7px"}} >
        <div className="radioqqq">
        <div className="col-md-6" style={{padding:"0px 7px"}} >
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.publishers}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible one year  </span>
                   <span className="listing-duration">Listing</span>
                   </div>
                   <div className="col-md-6" style={{padding:"0px 7px"}} >
         
            <input type="radio" className="radio" id="awesome-item-1"
            value="publishers"
            checked={this.state.packagePlan === "publishers"}
            onChange={this.packageselected}
             />
             <label className="radio__label" htmlFor="awesome-item-1"> Active </label>
          
        </div>
        </div>
        </div>
      
     
                



             
                  {this.props.loadingadsss  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 

                  <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary2"> Continue </button>
                      </div>
                       }
                  
                  </div>
                   ))}
                  </div>
                  
              </form>  
             
              );
          }
      }

export default Publisheradd;
