import React, { Component } from 'react'

import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let BaseURL = 'https://reactbooks.com/api/';

class Publishers extends Component {
  
  state = {
    books: null,
    total: null,
    per_page: null,
    current_page: 1,
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
    window.scrollTo(0, 0);
  }


  makeHttpRequestWithPage = async pageNumber => {
    const response = await fetch(BaseURL+`publishers.php?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    console.log(res.data);
    this.setState({
       books: res.data,
       total: res.total,
       per_page: res.per_page,
       current_page: res.page,
    });
  }

  render() {
 
       let books, renderPageNumbers;
       if (this.state.books !== null) {
         books = this.state.books.map(book => (
          <div className="col-md-6" key={book.publisher_id}>
            <div className="quicskkssspubsss">
	
        <h2>{book.name} </h2>
        <div className="col-md-4">
          <img src={BaseURL+book.bookcover}  alt="" />
      </div>

  
  <div className="col-md-8 waestae2">
  <div className="zzz2">
   
  <h3>  <FontAwesomeIcon icon={['fas','envelope']} className="iconsss2" />  <span> {book.email} </span></h3>
      <hr />
    <h3>  <FontAwesomeIcon icon={['fas','phone-square-alt']} className="iconsss2" /> 
     <span> {book.phone ?
     book.phone
     :
     <span style={{color: '#d8d8d8'}}>
     Phone Number
     </span>
     }
     
     </span></h3>
      <hr />
        <h3> 
        <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" />  <span> 
          <a href={book.websitelink} title={book.websitelink} target="_blank">
            {book.websitelink ?
            book.websitelink
            :
            <span style={{color: '#d8d8d8'}}>
            Website Link
            </span>
            
            }
            
            
            </a>
          </span>
         </h3>
  
    </div>
    </div>
  <div className="col-md-12">
  <div className="waestae">
    <b>About publisher :</b>
  <div dangerouslySetInnerHTML={{ __html: book.description }} />
  </div>
    </div>
    <Link to={`/d9a471426a3fd97b4c33998bb6dfb376/${book.publisher_id }`} className="viewsss" > View More   </Link>
    </div>

           
           </div>
         ));
       } else{
        return (
          <div className="" style={{padding:'60px'}}>
          <div className="container">
             <div className="row">
             <div className="col-md-12">
          <div className="alert alert-light" role="alert">
          <h4 className="alert-heading"> Please Wait </h4>
          <hr/>
          <ContentLoader  height={260} width={600} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                  <rect x="0" y="13" rx="4" ry="4" width="600" height="9" />
                  <rect x="0" y="29" rx="4" ry="4" width="200" height="8" />
                  <rect x="0" y="50" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="65" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="79" rx="4" ry="4" width="200" height="10" />
                  <rect x="0" y="99" rx="5" ry="5" width="600" height="300" />
              </ContentLoader>
      </div>
      </div>
      </div>
      </div>
      </div>
        )
       }
   
       const pageNumbers = [];
       if (this.state.total !== null) {
         for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
           pageNumbers.push(i);
         }
   
   
         renderPageNumbers = pageNumbers.map(number => {
           console.log('current_page: '+this.state.page+' number : '+number);
           let classes = this.state.current_page != number ? '' : 'active';
   
           return (
             <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
           );
         });
       }
   
   
   
       return (


        <>
        <div className="pagsssqqq">
        <div className="container">
           <div className="row">

           <section className="breadcrumb-section">
    <h2 className="sr-only">Site Breadcrumb</h2>
    <div className="container">
      <div className="breadcrumb-contents">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
            <li className="breadcrumb-item active">  Publishers </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  </div>
  </div>
  </div>
   
   
          <div className="pagsss2">
          <div className="container">
             <div className="row">
             <div className="col-md-12">
             <div className="section-title4">
                   <h2> Publishers </h2>
                 </div>
               
             {books}
             <div className="col-md-12">
         <div className="pagination">
           <span onClick={() => this.makeHttpRequestWithPage(1)}>&laquo;</span>
           {renderPageNumbers}
           <span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span>
         </div>
         </div>
         </div>
   
      
       </div>
       </div>
       </div>
       </>
     );
   }
   
   }

export default Publishers
