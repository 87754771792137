import React, { Component } from 'react'
import { EditorState,  convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
import {AppContext} from '../Context';


import Loader from 'react-loader-spinner'
class Viewpackedit extends Component {
       
    static contextType = AppContext;

  constructor(props) {
    super(props)
    this.state = {
       file: null,
       showResults: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.desc)
        )
      ),
    }
    this.onChange = this.onChange.bind(this);
       this.resetFile = this.resetFile.bind(this);
       this.categoryMode = this.categoryMode.bind(this);
  }

//image preview       
onChange(event) {
       this.setState({
             file: URL.createObjectURL(event.target.files[0])
       });
 }
 resetFile(event) {
        event.preventDefault();
        this.setState({ file: null });
      }
 
  //text editior
    onEditorStateChange = (editorState) => {
      this.setState({
        editorState
      });
    }
 
 //select option
 categoryMode(event) {
 //this.setState({value: event.target.value});
 if(event.target.value === 'other')
 { 
  //alert(event.target.value);
  this.setState({ showResults: true });
 
 }else{
  this.setState({ showResults: false });
 }
 
 } 
  
 


  previewpackUpdate = (bookspacks_id) => {
        this.context.previewpackUpdate(bookspacks_id,this.title.value,this.bookcover.files[0],this.description.value,this.price.value,this.purchaselink.value,this.category.value,this.descothers.value,this.websitelink.value,this.state.packagePlan);
   }

  render() {
       const { editorState } = this.state;
    return (
    
    <div className="col-md-12">
      <div className="col-md-1">&nbsp;</div>
    <div className="col-md-10">
    <div className="addbooksss">
      
      <h3 style={{fontSize: '23px',color: '#f67d41'}}> Edit Book Information </h3>
    <div className="central-meta3">

                    <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Title" required defaultValue={this.props.title}/>
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Book Cover Image  </label>
                     <input type="file" name="file" ref={(val) => this.bookcover = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                 </div>
                 {this.state.file && (
                   <div style={{ textAlign: "center" }}>
                        <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                       <button onClick={this.resetFile}>Remove File</button>
                   </div>
                   ) }
                    
                   <div className="form-group col-md-12 editor">
                   <label className="font-weight-bold"> Description <span className="adverts-form-required"> * </span> </label>
                       <Editor
                           editorState={editorState}
                           toolbarClassName="toolbarClassName"
                           wrapperClassName="wrapperClassName"
                           editorClassName="editorClassName"
                           onEditorStateChange={this.onEditorStateChange}
                           defaultEditorState={editorState}
                           
                       />
                      <textarea style={{display:'none'}}
     disabled ref={(val) => this.description = val}
     value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
   />
                   </div> 
 
               <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Price  </label>
                     <input type="text" name="price" ref={(val) => this.price = val} className="form-control" placeholder="Price" defaultValue={this.props.price} />
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Book Purchase Link  </label>
                     <input type="text" name="purchase" ref={(val) => this.purchaselink = val} className="form-control" placeholder="Book Purchase Link" defaultValue={this.props.purchaselink}/>
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Category  <span className="adverts-form-required"> * </span> </label>
                     <select  name="category" ref={(val) => this.category = val}  className="wqsss form-control" onClick={this.categoryMode} required defaultValue={this.props.category}>
                             
                             <option value=""> Choose the field </option>
                             <option>Fiction &amp; Literature</option>
                             <option>Romance</option>
                             <option>Science Fiction &amp; Fantasy</option>
                             <option>Comics &amp; Graphic Novels</option>
                             <option>Fiction - YA</option>
                             <option>Periodicals</option>
                             <option>Kids</option>
                             <option>Non-Fiction</option>
                             <option>Business &amp; Finance</option>
                             <option>Biography &amp; Memoir</option>
                             <option>Mystery &amp; Suspense</option>
                             <option value="other">Other</option>
                             
                           </select>
                           { this.state.showResults  ? 
                          <div>
                          <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} defaultValue={this.props.other} />
                          </div>
                           
                           : 
                           <div>
                          <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} style={{display:'none'}} defaultValue={this.props.other}/>
                          </div>
                          }
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Author Website  </label>
                     <input type="text" name="author" ref={(val) => this.websitelink = val} className="form-control" placeholder="Author Website" defaultValue={this.props.websitelink}/>
                 </div>     
              

                
          {this.props.loadingsssedits  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 
                 <div className="form-group col-sm-12 text-right">
                     <button type="submit" className="btn btn-primary2" onClick={() => this.previewpackUpdate(this.props.bookspacks_id)}> Continue </button>
                     <button onClick={() => this.context.cancelEditpack(this.props.bookspacks_id)} className="btn btn-light canqqq">Cancel</button>
                 </div>

                }


    </div>
    </div>
    </div>
    </div>
    
    )
  }
}

export default Viewpackedit
