
import React, { Component } from 'react';
import {AppContext} from '../Context';

import Loader from 'react-loader-spinner'
class Loginpage extends Component{

       static contextType = AppContext;
       
   componentDidMount() {
        this.autoInsertdata();
      }
    
      autoInsertdata = () => {
        this.context.autoInsertdata();
    }


       userLogin = (event) => {
              this.context.userLogin(event,this.email.value,this.password.value);
          }
    render(){
        const {  loadinglogin} = this.props;
        return(
              
           <form onSubmit={this.userLogin} className="login-forms">
                <h1> Sign into your account </h1>
                
                <p> Welcome Back! Please sign in to your Account</p>
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold"> Email Address </label>
                        <input type="email" name="email" ref={(val) => this.email = val} className="form-control" placeholder="Email Address" required/>
                    </div>
                    <div className="form-group col-md-12">
                        <label className="font-weight-bold">Password</label>
                        <input type="password" name="password" ref={(val) => this.password = val} className="form-control" placeholder="Password" required/>
                    </div>
                    <div className="form-group col-md-12 text-right">
                    {loadinglogin ?
                            <>
                                   <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                            </>
                         : 
                        <button type="submit" className="form-button"> Login </button>
                    } 
                    </div>
                </div>
                
            </form>  
                  
        );
    }
}
export default Loginpage;