// this component for about page
import React, { Component } from 'react';

import {AppContext} from '../Context';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner'


const TEST_SITE_KEY = "6LcEuuQUAAAAAInHKqQcX9rlDiF8tgfDHTrfPHi7";
const DELAY = 1500;


class Registerpage extends Component{

   static contextType = AppContext;

   constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      recaptchaLoaded: false
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
  }

  handleChange = value => {
    //console.log("Captcha value:", value);
    this.setState({ value });
   
  };

  asyncScriptOnLoad = () => {
    this.setState({ recaptchaLoaded: true });
    //console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    
    this._reCaptchaRef.current.execute();
  };

    Register = (event) => {
        this.context.Register(event,this.username.value,this.useremail.value,this.password.value,this.conformpassword.value,this.recaptcha_response.value);
    }




    render(){
        const { value, load, recaptchaLoaded } = this.state || {};

        const {  loadingregister} = this.props;
        return(
            <form onSubmit={this.Register} className="login-forms">
                <h1> Create an account </h1>
            <div className="form-row">
           
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Full Name</label>
                    <input type="text" name="username" ref={(val) => this.username = val} className="form-control" placeholder="Your name here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Email Address</label>
                    <input type="email" name="useremail" ref={(val) => this.useremail = val} className="form-control" placeholder="Your email here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Password </label>
                    <input type="password" name="password" ref={(val) => this.password = val} className="form-control" placeholder="Your password here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Confirm Password </label>
                    <input type="password" name="conformpassword" ref={(val) => this.conformpassword = val} className="form-control" placeholder="Your password here..." required />
                </div>

                <input type="hidden" name="recaptcha_response" ref={(val) => this.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

                {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="dark"
            size="invisible"
            ref={this._reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}


                <div className="form-group col-md-12 text-right">
                {loadingregister ?
                            <>
                                   <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                            </>
                         : 
                    <button type="submit" className="form-button" disabled={!recaptchaLoaded}> Register </button>
                }
                </div>
            </div>
           
        </form>  
        );
    }
}
export default Registerpage;