import React,{Component} from 'react';
import {AppContext} from '../Context';
import Loader from 'react-loader-spinner'
class Trailoradd extends Component {
  static contextType = AppContext;
       constructor(props) {
              super(props);
              this.state = {
                   file: null,
                   showResults: false,
                };
              this.packageselected = this.packageselected.bind(this);



            }

           

            trailorsAdd = (event) => {
              this.context.trailorsAdd(event,this.props.storageid,this.title.value,this.video.value,this.state.packagePlan);
             
          }
    
            componentDidMount(){
              this.setState({
                packagePlan: 'standard',
            });

            this.context.pricefetch();

            }


          //radio button
          packageselected(event) {
            //alert(event.target.value);
            this.setState({
              packagePlan: event.target.value
            });
          }
          
          
        
         


          render(){
            
              return (
                  <form onSubmit={this.trailorsAdd} className="central-meta">
                      
                  <div className="form-row">
                 
                     
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Youtube video title <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Youtube video title" required />
                      </div>
                     <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Youtube video url <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="video" ref={(val) => this.video = val} className="form-control" placeholder="Youtube video url" required />
                      </div>
                    
                      <h3 style={{color: '#4caf50'}}> Onetime Fee: Paypal / Credit Card Payment </h3>




                      {this.context.all_allprices.map((item, index) => ( 

                     <div className="col-md-12 gray-bg"  key={item.price_id}>
                    



      
       

                    <div className="col-md-5" style={{padding:0}}>
                           &nbsp;
                           </div>
                    <div className="col-md-7" style={{padding:0}}>


                   
                    <div className="col-md-6" style={{padding:"0px 7px"}} >
        <div className="radioqqq">
                    <h2>Standard</h2>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.video_standard}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible one year  </span>
                   <span className="listing-duration">Listing</span>


         
            <input type="radio" className="radio" id="awesome-item-1"
            value="standard"
            checked={this.state.packagePlan === "standard"}
            onChange={this.packageselected}
             />
             <label className="radio__label" htmlFor="awesome-item-1"> Active </label>
          
        </div>
        </div>
      
        <div className="col-md-6" style={{padding:"0px 7px"}} >
          
        <div className="radioqqq">
        <h2>Featured</h2>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">{item.video_featured}</span>
                      <span className="duration"> 1 Year</span>
                    </div>
                    <span className="listing-duration"> Visible permanent </span>
                   <span className="listing-duration"> Listing and social sharing </span>
          
            <input type="radio" className="radio" id="awesome-item-2"
            value="featured"
            checked={this.state.packagePlan === "featured"}
            onChange={this.packageselected}
             />
            <label className="radio__label" htmlFor="awesome-item-2"> Active </label>
        </div>
        </div>
        
      
       

                  </div>



             
                  {this.props.loadingaddtrailorsss  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 

                  <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary2"> Continue </button>
                      </div>
                       }
                  
                  </div>
                   ))}
                  </div>
                  
              </form>  
             
              );
          }
      }

export default Trailoradd;
