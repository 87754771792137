import React, { Component } from 'react'

import {Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";
let BaseURL = 'https://reactbooks.com/api/';

class Categories extends Component {
  
  state = {
    books: null,
    total: null,
    per_page: null,
    current_page: 1,
    categorysss:null,
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
   // window.scrollTo(0, 0);

  }
  componentWillReceiveProps() {
       
              this.makeHttpRequestWithPage(1);
        
   
     }

  makeHttpRequestWithPage = async pageNumber => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const response = await fetch(BaseURL+`categorybooks.php?page=${pageNumber}&categorybook=${this.props.match.params.categoryId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    //console.log(res);
    this.setState({
       books: res.data,
       total: res.total,
       per_page: res.per_page,
       current_page: res.page,
    });
  }







  render() {
 
       let books, renderPageNumbers;
       if (this.state.books !== null) {
         books = this.state.books.map(book => (
          <div className="col-md-6" key= {book.package ==='successpack' ? book.bookspacks_id : book.books_id}>
              <div className="basccsss">
            

            {book.package ==='successpack' ? 
            <>
              <div className="col-md-4"  style={{padding:0}}>
              {/* packid:{book.bookspacks_id} <br />
              books_idpack:{book.books_idpack} <br />
              title --- {book.packtitle} */}
              
              <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                      <Link to={`/614bc74b853095ed79ed30ba57b30b64/${book.bookspacks_id}`} className="" >
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL+book.imagefront} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                            </Link>
                      </article>
                   
                     
                    </section>
                    </div>
                    <div className="col-md-8 pagsssdesccc">
          
                          <h3>{book.packtitle}</h3>
                          {book.categorypack === 'other' ?
                                  <h6>{book.otherpack}</h6>
                                :
                                <h6>{book.categorypack}</h6>
                                }
                          <div dangerouslySetInnerHTML={{ __html: book.descriptionpack }} />
                          
                        </div>
                        <Link to={`/614bc74b853095ed79ed30ba57b30b64/${book.bookspacks_id}`} className="single-btnmmm" > View Book  </Link>

            </>
            
            : null}


             {book.booksadds === 'successbook' ? 
             <>
           <div className="col-md-4"  style={{padding:0}}>
           <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                      <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${book.books_id}`} className="" >
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL+book.bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                            </Link>
                      </article>
                   
                     
                    </section>
            
           
             
            
           </div>
          
           <div className="col-md-8 pagsssdesccc">
          
            
             <h3>{book.title}</h3>
              {book.category === 'other' ?
                     <h6>{book.other}</h6>
                    :
                    <h6>{book.category}</h6>
                    }
             <div dangerouslySetInnerHTML={{ __html: book.description }} />
             
           </div>

           <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${book.books_id}`} className="single-btnmmm" > View Book  </Link>
           </>

           : null}



           </div>
           </div>
         ));
       } else{
        return (
          <div className="" style={{padding:'60px'}}>
          <div className="container">
             <div className="row">
             <div className="col-md-12">
          <div className="alert alert-light" role="alert">
          <h4 className="alert-heading"> Please Wait </h4>
          <hr/>
          <ContentLoader  height={260} width={600} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                  <rect x="0" y="13" rx="4" ry="4" width="600" height="9" />
                  <rect x="0" y="29" rx="4" ry="4" width="200" height="8" />
                  <rect x="0" y="50" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="65" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="79" rx="4" ry="4" width="200" height="10" />
                  <rect x="0" y="99" rx="5" ry="5" width="600" height="300" />
              </ContentLoader>
      </div>
      </div>
      </div>
      </div>
      </div>
        )
       }
   
       const pageNumbers = [];
       if (this.state.total !== null) {
         for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
           pageNumbers.push(i);
         }
   
   
         renderPageNumbers = pageNumbers.map(number => {
           //console.log('current_page: '+this.state.page+' number : '+number);
           let classes = this.state.current_page != number ? '' : 'active';
   
           return (
             <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
           );
         });
       }
   
   
   
       return (
        <>
        <div className="pagsssqqq">
        <div className="container">
           <div className="row">

           <section className="breadcrumb-section">
    <h2 className="sr-only">Site Breadcrumb</h2>
    <div className="container">
      <div className="breadcrumb-contents">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
            <li className="breadcrumb-item active"> 
            
          {this.props.match.params.categoryId === 'FictionLiterature' ? 'Fiction & Literature' : null  }
          {this.props.match.params.categoryId === 'Romance' ? 'Romance' : null  }
          {this.props.match.params.categoryId === 'Science' ? 'Science Fiction & Fantasy' : null  }
          {this.props.match.params.categoryId === 'Comics' ? 'Comics & Graphic Novels ' : null  }
          {this.props.match.params.categoryId === 'YA' ? 'Fiction - YA ' : null  }
          {this.props.match.params.categoryId === 'Periodicals' ? 'Periodicals ' : null  }
          {this.props.match.params.categoryId === 'Kids' ? 'Kids ' : null  }
          {this.props.match.params.categoryId === 'Non' ? 'Non-Fiction ' : null  }
          {this.props.match.params.categoryId === 'Business' ? 'Business & Finance ' : null  }
          {this.props.match.params.categoryId === 'Biography' ? ' Biography & Memoir ' : null  }
          {this.props.match.params.categoryId === 'Mystery' ? ' Mystery & Suspense ' : null  }
          {this.props.match.params.categoryId === 'More' ? ' More ' : null  }
   
           </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  </div>
  </div>
  </div>
   
          <div className="pagsss">
          <div className="container">
             <div className="row">
             <div className="col-md-12">
         <div className="section-title4">
                   <h2> 
          {this.props.match.params.categoryId === 'FictionLiterature' ? 'Fiction & Literature' : null  }
          {this.props.match.params.categoryId === 'Romance' ? 'Romance' : null  }
          {this.props.match.params.categoryId === 'Science' ? 'Science Fiction & Fantasy' : null  }
          {this.props.match.params.categoryId === 'Comics' ? 'Comics & Graphic Novels ' : null  }
          {this.props.match.params.categoryId === 'YA' ? 'Fiction - YA ' : null  }
          {this.props.match.params.categoryId === 'Periodicals' ? 'Periodicals ' : null  }
          {this.props.match.params.categoryId === 'Kids' ? 'Kids ' : null  }
          {this.props.match.params.categoryId === 'Non' ? 'Non-Fiction ' : null  }
          {this.props.match.params.categoryId === 'Business' ? 'Business & Finance ' : null  }
          {this.props.match.params.categoryId === 'Biography' ? ' Biography & Memoir ' : null  }
          {this.props.match.params.categoryId === 'Mystery' ? ' Mystery & Suspense ' : null  }
          {this.props.match.params.categoryId === 'More' ? ' More ' : null  }
                   </h2>
                 </div>
               
             {books}
             <div className="col-md-12">
         <div className="pagination">
           <span onClick={() => this.makeHttpRequestWithPage(1)}>&laquo;</span>
           {renderPageNumbers}
           <span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span>
         </div>
         </div>
         </div>
   
      
       </div>
       </div>
       </div>
       </>
     );
   }
   
   }

export default Categories
