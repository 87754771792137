import React from 'react';
import {Provider} from '../Context';
import Actions from '../Actions/Actions';
import Package from '../Components/Package';
class Home extends Actions { 
  componentDidMount(){
    this.storedata();
    
    window.scrollTo(0, 0);
 }
 storedata() {
     let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
     let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
     if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
         this.setState({
               username: userstoragename,
               userid: userstorageid,
        })} else { this.setState({ username: '',userid: '', }) }
    }
  render(){
    const contextValue = {
      //------- Pack -------------
            booklistPackage:this.fetchbooklistPackage,
            all_package:this.state.packs,
      //-------------- Recommented top section ---------------------
            all_recommentedlist:this.state.recommentedlist, 
            all_recommentedlistall:this.state.recommentedlistall, 
      //-------------- Featured top section ---------------------
            all_featuredlist:this.state.featuredlist,
            all_featuredviewall:this.state.featuredviewall,
    //-------------- Standard top section ---------------------
            all_standardlist:this.state.standardlist,
            all_standardtoplist:this.state.standardtoplist,
    //-------------- Author package top section ---------------------
            all_authorpackage:this.state.authorpackagelist,
  //-------------- Author package ---------------------
            all_authorpack:this.state.authorpack,
  //-------------- Publishers ---------------------
            all_publishersview:this.state.publishersviewlist,
  //-------------- Book Trailor ---------------------
            all_trailorview:this.state.trailorview,
            all_trailorviewbasiclist:this.state.trailorviewbasiclist,
     //-------------- Book Trailor ---------------------
            all_audiobooksall:this.state.audiobooksall,
      //-------------- Latest Books ---------------------
            all_latestlist:this.state.latestlist,
     //-------------- Basic Books ---------------------
            all_basicbooks:this.state.basiclist,
            all_booksqqq:this.state.booksallqqq,
            all_twitterpack:this.state.twitterpack,
            all_videopack:this.state.videopack,
  }
  const packvaluesss = {   //fetchauthorpack
    username : this.state.username,
    authordetails : this.state.authordetails,
    profile_imagepacks : this.state.profile_imagepack,
    user_id : this.state.user_id,
    books_id : this.state.books_id,
    facebook : this.state.facebook,
    twitter : this.state.twitter,
    linkedin : this.state.linkedin,
  }
    return (
      <Provider value={contextValue}>
       <div className="firstsectionsss">
        <Package packauthimgsss= {this.state.authorpackagebooks} packlistdetails={packvaluesss} storageid={this.state.userid} />
        </div>
      </Provider>
    );
  }
}
export default Home;