

import React,{Component} from 'react';

import {AppContext} from '../Context';

import Loader from 'react-loader-spinner'


import CounterInput from "react-counter-input";



class Coupentwo extends Component{

    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
        random: '',
        isLoading:false,
        checked:false,
        savesss:false,
        
       };
     }
     componentDidMount(){
      this.context.amountlist();
      
      this.context.coupenlist();
      window.scrollTo(0, 0);
      } 


    handleCheck= () => {
      this.setState({checked: !this.state.checked});
      //this.setState({checked: 'emailcheck'});
     
      }
   
     handleClick = () =>{

      //const min = 1;const max = 100; const rand = min + Math.random() * (max - min);
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
     
      let rand = [];
      for (let i = 0; i < 10; i++) {
        //const item = characters[i];
        //rand.push(<li key={item.i}>{item}</li>);
        rand.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
     
      this.setState({ random:rand});
      this.setState({ savesss:true});
      // let iAmAString = "Once upon a time, long long ago"; 
      // let iAmAlsoAString = iAmAString.replace(",", ""); 
      // console.log(iAmAlsoAString);
    }

    CoupencodeAdd = (event) => {
      this.context.CoupencodeAdd(event,this.coupenvalue.value,this.discount.value,this.emailcode.value);
  }
  Coupencodedelete = (hisss) => {
      this.context.Coupencodedelete(hisss);
  } 
  AmountUpdate = (event) => {
      this.context.AmountUpdate(event,this.basic.value,this.standard.value,this.featured.value,this.recommented.value,this.package.value,this.publishers.value,this.audio.value,this.videostandard.value,this.videofeatured.value,this.piceids.value);
  }


   

      
    
    render(){
      
      const {  loadingcoupen} = this.props;

        return(
               <div className="">
               <div className="container">
                  <div className="row">
                  <div className="col-md-12" >
                             {this.context.all_amoulisss.map((item, index) => (
                                <div key={item.price_id}>

                                <div className="col-md-12 coupengeneratesss5">
                               
              <form onSubmit={this.AmountUpdate} >
              <div className="form-row">
                 <h5> PAYMENT AMOUNT DETAILS </h5>
                     
                 <div className="form-group col-md-4">
                     <label className="font-weight-bold"> basic <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="basic" ref={(val) => this.basic = val} className="form-control" placeholder="basic" required defaultValue={item.basic} />
                 </div> 
                 <div className="form-group col-md-4">
                     <label className="font-weight-bold"> standard <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="standard" ref={(val) => this.standard = val} className="form-control" placeholder="standard" required defaultValue={item.standard} />
                 </div>
                 <div className="form-group col-md-4">
                     <label className="font-weight-bold"> featured <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="featured" ref={(val) => this.featured = val} className="form-control" placeholder="featured" required defaultValue={item.featured} />
                 </div>

                  <div className="form-group col-md-4">
                     <label className="font-weight-bold"> recommented <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="recommented" ref={(val) => this.recommented = val} className="form-control" placeholder="recommented" required defaultValue={item.recommented} />
                 </div>

                <div className="form-group col-md-4">
                     <label className="font-weight-bold"> package <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="package" ref={(val) => this.package = val} className="form-control" placeholder="package" required defaultValue={item.package} />
                 </div>

                <div className="form-group col-md-4">
                     <label className="font-weight-bold"> audio <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="audio" ref={(val) => this.audio = val} className="form-control" placeholder="audio" required defaultValue={item.audio} />
                 </div>
                <div className="form-group col-md-4">
                     <label className="font-weight-bold"> video_standard <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="videostandard" ref={(val) => this.videostandard = val} className="form-control" placeholder="video_standard" required defaultValue={item.video_standard} />
                 </div>
                <div className="form-group col-md-4">
                     <label className="font-weight-bold"> video_featured <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="videofeatured" ref={(val) => this.videofeatured = val} className="form-control" placeholder="video_featured" required defaultValue={item.video_featured} />
                 </div>
                <div className="form-group col-md-4">
                     <label className="font-weight-bold"> publishers <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="publishers" ref={(val) => this.publishers = val} className="form-control" placeholder="publishers" required defaultValue={item.publishers} />

                     <input type="hidden" name="piceids" ref={(val) => this.piceids = val} className="form-control" placeholder="price_id" required defaultValue={item.price_id} />
                 </div>





                 </div>
                  



                                {loadingcoupen  ? 
                                  <><Loader type="ThreeDots" className="loaderbuttonsss"/> </>
                                  : 
                                  <>
                                <button className="form-button form-buttonccc2"> Update	</button>
                                </>
                               
                                }

                                </form>
                                  </div>



                                  </div>
                                    ))}


              </div>



              <div className="col-md-12" >
                <h5> COUPEN CODE GENERATE </h5>
              </div>


                    <div className="col-md-6" style={{boxShadow: '6px 2px 7px #edf2f6'}}>
                    <div className="central-metacoupen">
                    <div className="coupengeneratesss"> {this.state.random}</div>
                    <button className="coupengeneratesss2" onClick={this.handleClick.bind(this)}>  Generate </button>
                    <h5> Discount : <span style={{color:'#fa6342'}} > {this.state.precentage}% </span> </h5>
                    <div className="coupengeneratesss3">
                     <CounterInput  min={0}  max={100} onCountChange={count => this.setState({ precentage:count })}  />
                     </div>

                     

                    <form onSubmit={this.CoupencodeAdd} >
                   
                     
                      {this.state.random !=='' ?

                      <input className="input-number" name="coupenvalue" ref={(val) => this.coupenvalue = val} type="hidden" defaultValue={this.state.random}  />

                      :null }
                      
                      <div className="coupengeneratesss4">
                       
                         
                          <input className="input-number" name="discount" ref={(val) => this.discount = val} type="hidden" defaultValue={this.state.precentage}  />
                          <h5> Active Email Code : <input type="checkbox"  name="emailcode" ref={(val) => this.emailcode = val} onChange={this.handleCheck} defaultChecked={this.state.checked} defaultValue={this.state.checked} />
                          </h5>
                      </div>
                      {loadingcoupen  ? 
                          <><Loader type="ThreeDots" className="loaderbuttonsss"/> </>
                          : 
                          <>
                         
                              <div className="form-group col-sm-12 text-right">
                              {this.state.savesss === true ? 
                                  <button type="submit" className="form-button form-buttonccc"> Save  </button>
                                  : 
                                 null
                                }
                              </div>
                              </>
                      }
                    </form>
                    </div>
				

      </div>


                           <div className="col-md-6" >
                             {this.context.all_coupensss.map((item, index) => (
                                <div key={item.coupon_id}>

                                <div className="col-md-12 coupengeneratesss5">
                                {item.email === 'valid' ? 
                                <h6> Coupon Code : {item.discount}% --- <span style={{color:'#62ab00'}} >  EMAIL </span> </h6>
                                :
                                <h6> Coupon Code : {item.discount}% </h6>
                                }

                                <h3> {item.coupon_code} </h3>

                               
                                
                                {loadingcoupen  ? 
                                  <><Loader type="ThreeDots" className="loaderbuttonsss"/> </>
                                  : 
                                  <>
                                  {/* <input type="text" name="hisss" ref={(val) => this.hisss = val} defaultValue={item.coupon_id} /> */}
                                <button className="form-button form-buttonccc2" onClick={() => this.Coupencodedelete(item.coupon_id)}> Delete id : {item.coupon_id}	</button>
                                </>
                               
                                }

                               
                                <div className="clearfix"></div>
                                  </div>


                                  <div className="clearfix"></div>
                                  </div>
                                    ))}


              </div>











      </div>
      </div>
      </div>
                      

           
        );
    }
}
export default Coupentwo;