// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import Publishereditpreview from '../Components/Publishereditpreview';

class Publisherpreview extends Actions{
    componentDidMount(){
        this.storedata();
        window.scrollTo(0, 0);
     }  
     storedata() {
        let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
        let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
        if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
            this.setState({
                  username: userstoragename,
                  userid: userstorageid,
           })} else { this.setState({ username: '',userid: '', }) }
          
       }
    
    render(){
        const contextValue = {
              viewPublisherPreview:this.viewPublisherPreview,
             all_books:this.state.publisherAddPreview,
             editModepublisherpreview:this.editModepublisherpreview,
             previewpublishereditlist:this.previewpublishereditlist,
             cancelPublisherEdit:this.cancelPublisherEdit,

             
             
        }

        return(
            <Provider value={contextValue}>
            <div className="addbooksss gray-bg ">
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">
                                  <Publishereditpreview storageid ={this.state.userid} id={this.props.match.params.PublisherspreviewId}  loadingsss ={this.state.isLoading}  />
                          
                              
                             </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Publisherpreview;