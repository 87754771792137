// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import Bookaddpreviewpage from '../Components/Bookaddpreviewpage';

class Bookaddpreviewsection extends Actions{

    componentDidMount(){
        this.storedata();
        window.scrollTo(0, 0);
      }  
      storedata() {
          let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
          let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
          if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
              this.setState({
                    username: userstoragename,
                    userid: userstorageid,
             })} else { this.setState({ username: '',userid: '', }) }
            
         }  
    render(){
        const contextValue = {
             viewBooksPreview:this.viewBooksPreview,
             all_books:this.state.booksAddPreview,
             editModepreview:this.editModepreview,
             previewbookUpdate:this.previewbookUpdate,
             coupenActivated:this.coupenActivated,
             cancelEdit:this.cancelEdit,

             
             pricefetch:this.fetchprice,
             all_allprices:this.state.allprices,
        }

        //console.log(this.props.match.params.viewId);
        //console.log(this.state.paymentPlans);
        return(
            <Provider value={contextValue}>
            <div className="addbooksss gray-bg ">
                {/* <h3> view id:{this.props.match.params.bookPreviewId} </h3> */}
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">
                                  
                                  <Bookaddpreviewpage id={this.props.match.params.bookPreviewId}  loadingsss ={this.state.isLoading} loadingedits ={this.state.isLoading} useridmmm ={this.state.userid}/>
                                  
                         {/* <Bookaddpreviewpage id={this.props.match.params.bookPreviewId} desc={this.state.desc} paymentbook ={this.state.paymentPlans}/>
                        */}
                                 
                              
                             </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Bookaddpreviewsection;