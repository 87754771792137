import React, { Component } from 'react'

import {Redirect, Link } from "react-router-dom"; 

import ContentLoader from "react-content-loader";

import {toast} from "react-toastify";
let BaseURL = 'https://reactbooks.com/api/';

class PendingBooks extends Component {
  
  
  state = {
    books: null,
    msg:'Please Wait'
  }

  componentDidMount() { 
     this.storedata();
    window.scrollTo(0, 0);
    setInterval(this.getData, 7000);
  }

  getData = () => {
    this.setState({
      msg: 'No Pending Payment .',
})


  }


  storedata() {
    let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
    let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
    if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
        this.setState({
              username: userstoragename,
              userid: userstorageid,
       })
      this.makeHttpRequestWithPage(userstorageid);
      } else { this.setState({ username: '',userid: '', }) }
      
   }

  makeHttpRequestWithPage = async pageNumber => {
    const response = await fetch(BaseURL+`pendingbooks2.php?page=${pageNumber}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    //console.log(res);
    if(res === 'Emptybooks' ){
      this.setState({
        books: null,
     });
    } else {
      this.setState({
       books: res.data,
    });

    }
    
  }

  render() {
 
       let books;
       if (this.state.books !== null) {
         books = this.state.books.map(book => (
          <div className="col-md-6" key={book.books_id}>
             <div className="basccsss">
           <div className="col-md-4" style={{padding:0}}>
             <img src={BaseURL+book.bookcover}  alt="" />
           </div>
           <div className="col-md-8 pagsssdesccc">
          
             <h3>{book.title}</h3>
              {book.category === 'other' ?
                     <h6>{book.other}</h6>
                    :
                    <h6>{book.category}</h6>
                    }
             <div dangerouslySetInnerHTML={{ __html: book.description }} />
             
           </div>
           <Link to={`/ae1ce6f4a83e70734418c6a77c9e984e/${book.books_id}`} className="single-btnmmm" >  continue payment processing...  </Link>
           </div>
           </div>
         ));
       }else{
        return (
          <div className="" style={{padding:'60px'}}>
          <div className="container">
             <div className="row">
             <div className="col-md-12">
          <div className="alert alert-light" role="alert">
          <h4 className="alert-heading"> {this.state.msg} </h4>
          <hr/>
          <ContentLoader  height={260} width={600} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                  <rect x="0" y="13" rx="4" ry="4" width="600" height="9" />
                  <rect x="0" y="29" rx="4" ry="4" width="200" height="8" />
                  <rect x="0" y="50" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="65" rx="4" ry="4" width="600" height="10" />
                  <rect x="0" y="79" rx="4" ry="4" width="200" height="10" />
                  <rect x="0" y="99" rx="5" ry="5" width="600" height="300" />
              </ContentLoader>
             
      </div>
      </div>
      </div>
      </div>
      </div>
        )
       }
   
   
   
       return (
        <>
        <div className="pagsssqqq">
        <div className="container">
           <div className="row">

           <section className="breadcrumb-section">
    <h2 className="sr-only">Site Breadcrumb</h2>
    <div className="container">
      <div className="breadcrumb-contents">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
            <li className="breadcrumb-item active"> Pending Payment - Books  </li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  </div>
  </div>
  </div>
   
          <div className="pagssspending">
          <div className="container">
             <div className="row">
             <div className="col-md-12">
         <div className=" section-title4 ">
                   <h2> Pending Payment - Books </h2>
                 </div>
               
             {books}
             
         </div>
   
      
       </div>
       </div>
       </div>
       </>
     );
   }
   
   }

export default PendingBooks
