
import React,{Component} from 'react';

import {AppContext} from '../Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { ToastContainer,toast} from "react-toastify";


let BaseURL = 'https://reactbooks.com/api/';

class Recentlogin extends Component{

    static contextType = AppContext;

    componentDidMount(){
        this.context.recentloginfetch();
   }

   addRegister = () => {
        toast("please login to add your photo", {
            position: "top-center" });
            this.props.Registermodechildsss();
   }

    render(){
        return(
               <div className="">
                    <ToastContainer />
                       <h1> Recent Login </h1>
                
                        <p> Next Time you login see your picture. To remove an account from this page click cross.</p>
                      
				<div className="user-log">
                 <div className="log-user">
					 <div className="col-md-8">
                         {this.context.all_recentLogin.map((item, index) => (
                             <div key={item.react_user_id}>
                               <div className="col-lg-6 col-md-4 col-sm-6">
								  <div className="user-log">
                                       <FontAwesomeIcon icon={['fas','user-lock']} className="iconsss2" />
                                       <img src={BaseURL+item.profile_image} alt="userprofile" />
										<span>{item.user_name}</span>
													
									</div>
								</div>
                             </div>
            ))}

                </div>	

                <div className="col-lg-4 col-md-4 col-sm-4">
					<div className="user-add" >
                    
						<div onClick={this.addRegister} ><FontAwesomeIcon icon={['fas','plus']} className="iconsss2"  style={{color:"#fd6500"}}/><span>Add Photo</span></div>	
                        
                        {/* <div onClick={this.props.Registermodechildsss}><FontAwesomeIcon icon={['fas','plus']} className="iconsss2"  style={{color:"#fd6500"}}/><span>Add Photo</span></div> */}
					</div>
                
				</div>


					</div>   
				
				</div>
				

      </div>
                      

           
        );
    }
}
export default Recentlogin;