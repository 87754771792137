// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import Audioprevieeditsss from '../Components/Audioprevieeditsss';

class Audiopreview extends Actions{
    componentDidMount(){
        this.storedata();
        window.scrollTo(0, 0);
     } 
     storedata() {
        let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
        let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
        if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
            this.setState({
                  username: userstoragename,
                  userid: userstorageid,
           })} else { this.setState({ username: '',userid: '', }) }
          
       }
    render(){
        const contextValue = {
            viewaudioPreview:this.viewaudioPreview, //perms select value from database
             all_audiobooks:this.state.audioAddPreview, // get data 

             editModeaudiopreview:this.editModeaudiopreview,
             previewaudioUpdatelist:this.previewaudioUpdatelist,
             audiocancelEdit:this.audiocancelEdit,

        }

        return(
            <Provider value={contextValue}>
            <div className="addbooksss gray-bg ">
                <div className="container">
                     <div className="row">
     
                        <div className="col-md-12">
                            <Audioprevieeditsss storageid ={this.state.userid} id={this.props.match.params.AudiopreviewId}  loadingsss ={this.state.isLoading}  />
                        </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Audiopreview;