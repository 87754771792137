
import React,{Component} from 'react';
import {AppContext} from '../Context';

import Audioeditpreviewlist from '../Components/Audioeditpreviewlist';

import Loader from 'react-loader-spinner'
import ContentLoader from "react-content-loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userphotos from '../Img/sss.png';
let BaseURL = 'https://reactbooks.com/api/';


class Audioprevieeditsss extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
          
            isEditing:false,
         };
    
     }

    

      componentDidMount(){
        window.scrollTo(0, 0);
          this.viewaudioPreview();
          
          
    }
    viewaudioPreview = () => {
        this.context.viewaudioPreview(this.props.id);
    }


    toggle() {
        this.setState({addClass: !this.state.addClass});
      }

    render(){
        let boxClass = ["redmoesss"];
        if(this.state.addClass) {
          boxClass.push('redmoesssqqq');
        }
        let allUsers;
        let mainData;
      
       
        allUsers = this.context.all_audiobooks.map(({audio_id,userid,title,written,narrated,bookcover,description,hrs,mins,price,purchaselink,websitelink,paymentbook,paymentamount,coupon_code,discount,user_name,profile_image,isEditing}) => {
              //const regex = /(<([^>]+)>)/ig;
              //const descriptionviews = description.replace(regex, '');
            return isEditing === true ? (
                   <div key={audio_id}>
                        
                           <div className="form-row">
                             <Audioeditpreviewlist written={written} narrated={narrated} hrs={hrs} mins={mins} desc={description} audio_id={audio_id} title={title} bookcover={bookcover} price={price} purchaselink={purchaselink} websitelink={websitelink}  loadingsssedits ={this.props.loadingsss}/>
                            
             {/* {this.props.desc} */}
             </div>
 
            </div>
            ) :  (
               
                <div key={audio_id}>
                <div className="col-md-12 newsssaddbooksss">

                <div className="central-metazzzmmm">

    {/* ------------------------------ Loader start ----------------------------------------------------- */}
    
                    {this.props.loadingsss ? 
                    <><Loader type="TailSpin" color="#fd6500" height={67} width={67} style={{textAlign:"center"}} /></>
                    : 
                    <>
                    {this.props.storageid === userid ?
               <>
                          <button className="zzz" onClick={() => this.context.editModeaudiopreview(audio_id)}>Edit</button>
                          </>
                  : null
             }
                          <h2 className="create-post">{title}</h2>




                          <div className="col-md-3">
                          <img src={BaseURL+bookcover}  alt="" style={{ maxWidth: '177px',marginBottom:'37px'}}/>
                          </div>
                          <div className="col-md-9">
                          <div className="col-md-9" style={{padding:'0px'}} >
                         <div className="zzz2">
                        
                         <h3> <FontAwesomeIcon icon={['fas','feather-alt']} className="iconsss2" />  Written By  : <span> {written}</span></h3>
                          <hr />
                          <h3><FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" />  Narrated By : <span> {narrated}</span></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','clock']} className="iconsss2" />   Length :     <span>
                          {hrs !=='' ? 
                          <>
                          <span class="price2"> {hrs}  </span> hrs and 
                          </>
                          : null
                           }


                           {mins !=='' ? 
                          <>
						  <span class="price2"> {mins} </span> mins
                          
                          </>
                          : null
                           }
                          </span>
                          </h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','money-bill']} className="iconsss2" /> Price : <span style={{color:"#ff4242"}}  > {price}</span></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','link']} className="iconsss2" />   Book Purchase Link : <a href={purchaselink} rel="noopener noreferrer" target="_blank"> {purchaselink} </a></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" /> Website Link  : <a href={websitelink} rel="noopener noreferrer" target="_blank"> {websitelink}</a></h3>


                          </div>
                          </div>

                          <div className="col-md-3" style={{padding:'0px'}}>
                                <div className="yyyview">
                                {profile_image === '' ?
                                <img src={userphotos} alt="books" />
                                :
                                <img src={BaseURL + profile_image} alt="" />
                                }
                                <h3> {user_name} </h3>
                                </div>
                            </div>

                          </div>
                          <div className="col-md-12">
                          <div  className={boxClass.join(' ')}>
		                  {/* {descriptionviews} */}
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                          </div>
                          {this.state.addClass ? 
                             <p className="redmoesssmmm" onClick={this.toggle.bind(this)}> Less </p>
                             :
                             <p className="redmoesssmmm" onClick={this.toggle.bind(this)}>  More </p>
                             }
                           
                            </div>



                                </>
                             }
                         
     {/* ------------------------------- Loader End ----------------------------------------------------- */}
                        
                        </div>
                        </div>
                    
                
                         </div>
                        
          
            );
        });

        if(this.context.all_audiobooks.length > 0){
            mainData = (
              <div>
                      {allUsers}
              </div>
                   
            );
        }
        else{
            mainData = (
                <div className="alert alert-light" role="alert">
                    <h4 className="alert-heading"> Please Wait </h4>
                    <hr/>
                    <ContentLoader  height={260} width={400} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
                </div>
            );
        }
        return (
            < >
           
                {/* <h3> Pass Value :{this.props.id} </h3> */}
                
                {mainData}
      
     
      
            
        </>  
        );
    }
}
export default Audioprevieeditsss;