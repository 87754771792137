import React, { Component } from 'react';
import { AppContext } from '../Context';
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import userphotos from '../Img/sss.png';
import facebook from '../Img/facebook.png';
import twitter from '../Img/twitter.png';
import linkedin from '../Img/linkedin.png';
import ContentLoader from "react-content-loader";
let BaseURL = 'https://reactbooks.com/api/';
class Package extends Component {
  static contextType = AppContext;
  componentDidMount() {
    this.context.booklistPackage();
    this.storedata();
  }
  storedata() {
    let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
    let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
    if (localStorage.getItem('tockenusername') && localStorage.getItem('tockenid')) {
      this.setState({
        username: userstoragename,
        userid: userstorageid,
      })
    } else { this.setState({ username: '', userid: '', }) }
  }
  render() {
    return (
      <>
        <div className="premiumsss top-seccc" >
          <div className="col-md-12">
            {/* Package */}
            <div className="col-md-6" style={{padding:'0px'}}>
            <h2 className="premiumsssa2">  Book Promotions - Worldwide : E-books & Paperback </h2>
            </div>
        <div className="col-md-6" style={{padding:'0px'}}>
       <h2 className="premiumsssa3">  Essential Reading: the Best Books of All Time in Each Genre  </h2>
       </div>
      <div className="clearfix topsss"></div>
            {this.context.all_package.map((item, index) => (
              <div key={item.bookspacks_id}>
                <div className=" col-md-2 ">
                  <Link to={`/614bc74b853095ed79ed30ba57b30b64/${item.bookspacks_id}`} className="" >
                  <span className="new">New</span>
                    <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL + item.imagefront} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                      </article>
                      {item.category === 'other' ?
                              <h6>{item.other}</h6>
                              :
                              <h6>{item.category}</h6>
                            }
                      <p className="book-wrappertitlss"> {item.title} </p>
                    </section>
                  </Link>
                </div>
              </div>
            ))}
            {/* Recommented */}
            {this.context.all_recommentedlist.map((item, index) => (
              <div key={item.books_id}>
                <div className=" col-md-2 ">
                <span className="new">New</span>
                  <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                    <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL + item.bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                      </article>
                      {item.category === 'other' ?
                              <h6>{item.other}</h6>
                              :
                              <h6>{item.category}</h6>
                            }
                      <p className="book-wrappertitlss"> {item.title} </p>
                    </section>
                  </Link>
                </div>
              </div>
            ))}
            {/* Featured */}
            {this.context.all_featuredlist.map((item, index) => (
              <div key={item.books_id}>
                <div className=" col-md-2 ">
                <span className="new">New</span>
                  <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                    <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL + item.bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                      </article>
                      {item.category === 'other' ?
                              <h6>{item.other}</h6>
                              :
                              <h6>{item.category}</h6>
                            }
                      <p className="book-wrappertitlss"> {item.title} </p>
                    </section>
                  </Link>
                </div>
              </div>
            ))} 
            {/* Standard */}
            {this.context.all_standardtoplist.map((item, index) => (
              <div key={item.books_id}>
                <div className=" col-md-2 ">
                <span className="new">New</span>
                  <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                    <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL + item.bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                      </article>
                      {item.category === 'other' ?
                              <h6>{item.other}</h6>
                              :
                              <h6>{item.category}</h6>
                            }
                      <p className="book-wrappertitlss"> {item.title} </p>
                    </section>
                  </Link>
                </div>
              </div>
            ))}
<div id="clouds">
              <div className="cloud"  ></div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          {/* Author Package */}
          <ul id="menu" className="nav__list">
            {this.context.all_authorpackage.map((item, index) => (
              <li className="nav__item selected ggg1" key={item.books_id}>
                <Link to={`/102140f165c4b280244b44ecb79e4f4d/${item.books_id}`} className="nav__link">
                  <p className="nav__label">
                  {item.profile_image ?
                           <img src={BaseURL + item.profile_image} alt="books" />
                          :
                          <img src={userphotos} alt="books" />
                        }
                    {item.user_name}
                  </p>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="clearfix"></div>
        {/* Package */}
        <div className="packagesec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-9 bestseller-content">
                  {this.props.storageid == this.props.packlistdetails.user_id ?
                    <>
                      <Link to={`/a02a60ce620c6b2cec2cd014dea74008/${this.props.packlistdetails.books_id}`} className="yyybuttons" > Add Details  </Link>
                    </>
                    : null
                  }
                  {this.props.storageid == this.props.packlistdetails.user_id ?
                    <>
                      <Link to={`/23e6caa400cf92ed683e4ed0e72a2f1e/${this.props.packlistdetails.books_id}`} className="yyybuttons2" > Add Additional Books  </Link>
                    </>
                    : null
                  }
                  <div className="clearfix"></div>
                  <div className="col-md-7">
                  <h1>Author best selling </h1>
                    <div className="col-md-12" style={{padding:0}}>
                      <div className="col-md-4 yyy" style={{padding:0}}>
                        {this.props.packlistdetails.profile_imagepacks === '' ?
                          <img src={userphotos} alt="books" />
                          :
                          <img src={BaseURL + this.props.packlistdetails.profile_imagepacks} alt="" />
                        }
                      </div>
                      <div className="col-md-8">
                        <h2> {this.props.packlistdetails.username} </h2>
                        <div className="social-author">
                          <ul id="buttons">
                            <li className="fb">
                              <a href={this.props.packlistdetails.facebook} title="Facebook">
                                <img src={facebook} alt="facebook" />
                              </a>
                            </li>
                            <li className="tw">
                              <a href={this.props.packlistdetails.twitter} title="Twitter">
                                <img src={twitter} alt="twitter" />
                              </a>
                            </li>
                            <li className="lin">
                              <a href={this.props.packlistdetails.linkedin} title="linkedin" target="_blank">
                                <img src={linkedin} alt="linkedin" />
                              </a>
                            </li>
                          </ul>
                          <ul>
                          </ul>
                        </div>
                      </div>
                      <div className="hhselss">
                        <div className="hhselsss">
                          <p>
                            {this.props.packlistdetails.authordetails ?
                            this.props.packlistdetails.authordetails
                          :
                          <ContentLoader  height={260} width={400} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                  <div className="packbooksss">
                    {this.context.all_authorpack.map((item, index) => (
                      <div key={item.bookspacks_id}>
                        <div className=" col-md-6 ">
                        <Link to={`/614bc74b853095ed79ed30ba57b30b64/${item.bookspacks_id}`} className="" >
                          <div className=" books2 ">
                            <img src={BaseURL + item.imagefront} alt="books" />
                            <div className="hover-btns">
                              View Book  
                            </div>
                          </div>
                          <h4> {item.title}  </h4>
                          </Link>
                        </div>
                      </div>
                    ))}
                    <div className="clearfix"></div>
                    {this.context.all_twitterpack.map((item, index) => (
                      <div key={item.books_id} style={{ padding: '5px', backgroundColor: '#f1f1f1'}}>
                        {item.twitter ?
                            <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName={item.twitter}
                            options={{ height: 300 }}
                          />
                        : null
                      }
                      </div>
                    ))}
                    <div className="clearfix"></div>
                    {this.context.all_videopack.map((item, index) => (
                      <div key={item.video_id}>
                        <div className="videossspagsss2 ">
                           <iframe id="ytplayer" type="text/html" className="videosss2"
                         src={`https://www.youtube.com/embed/`+item.video+`?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                         frameBorder="0" allowFullScreen></iframe> 
                      </div>
                      </div>
                    ))}
                  </div>
                  </div>
                </div>
                {/* --------------------------------------------------------------------------------------------------------------------------------------------------------Latest Books ---------------------------------------------------------------------------------- */}
                <div className="col-md-3 latestbooks ">
                  <div className="section-title text-center mb-50 section-title--bordered ">
                    <h2> Latest Books </h2>
                  </div>
                  {this.context.all_latestlist.map((item, index) => (
                    <div key={item.books_id}>
                      <div className="col-md-12 latestbooks2">
                        <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                          <div className="col-md-3" style={{ padding: 0 }} >
                            <div className=" books3list " style={{ padding: 0 }} >
                              <img src={BaseURL + item.bookcover} alt="books" />
                            </div>
                          </div>
                          <div className="col-md-9" style={{ padding: 0 }}>
                          {item.category === 'other' ?
                              <h6>{item.other}</h6>
                              :
                              <h6>{item.category}</h6>
                            }
                            <h4> {item.title} </h4>
                          {item.price != 0 ?
                            <p> $ {item.price} </p>
                            :
                            null
                          }
                          </div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="clearfix"></div>
        {/* -------------------------Recommented Books ----------------------------------------------*/}
        <div className="secreccc">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center mb-50 section-title--bordered">
                  <h2> Recommended books </h2>
                </div>
                {this.context.all_recommentedlistall.map((item, index) => (
                  <div key={item.books_id}>
                    <div className="col-md-6 reccc">
                      <div className="col-md-8" style={{ padding: 0 }} >
                        <div className="product-header" className="single-btn">
                          <span className="cccgggsss">{item.category} </span>
                          <h1 className=""> {item.title} </h1>
                          <div className="clearfix">
                          </div>
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </div>
                      </div>
                      <div className="col-md-4" style={{ padding: 0 }} >
                        <div className=" booksrec ">
                          <img src={BaseURL + item.bookcover} alt="books" />
                          
                        </div>
                        <div className="clearfix"></div>
                        <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="yyybuttons3" > View Book  </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="clearfix"></div>
        {/* ------------------------ Publisher ----------------------------------------------*/}
        <div className="secreccc2" >
          <div className="container">
            <div className="row">
              <div className="col-md-4">
              <div className="pubsss">
                <div className="section-title3 text-center mb-50 section-title--bordered2">
                 
                  <div className="col-md-9 " style={{ padding: 0 }} >
                  <h2> Publishers </h2>
                  </div>
                  <div className="col-md-3 " style={{ padding: 0 }} >
                  <Link to={'/1ec46a3c476af55e505c6885aa43baf8/'} className="listqqq" > View All </Link>
                  </div>
                </div>
                {this.context.all_publishersview.map((item, index) => (
                  <div key={item.publisher_id}>
                    <div className="col-md-12 " style={{ padding: 0 }} >
                      <div className="description " >
                        <Link to={`/d9a471426a3fd97b4c33998bb6dfb376/${item.publisher_id}`} className="" >
                          <h6 className=""> {item.name} </h6>
                                <div className="clearfix"> </div>
                            <div className="col-md-4" style={{ padding: 0 }} >
                            
                              <div className="booksrec2">
                                <img src={BaseURL + item.image} alt="books" />
                                <div className="hover-btns">
                                  View More   
                                </div>
                              </div>
                            
                            </div>
                            
                            <div className="col-md-8 " style={{ padding: 0 }} >
                              <div className="product-header" className="single-btn2">
                                
                                <div dangerouslySetInnerHTML={{ __html: item.about }} />
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </Link>
                        <a href={"mailto:" + item.email} rel="noopener noreferrer" target="_blank"> {item.email} </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              </div>
              {/* ------------------------- Book Trailers  ----------------------------------------------*/}
              <div className="col-md-8">
                <div className="section-title3 text-center mb-50 section-title--bordered">
                  <div className="col-md-10 " style={{ padding: 0 }} >
                  <h2> Book Trailers </h2>
                  </div>
                  <div className="col-md-2 " style={{ padding: 0 }} >
                  <Link to={'/8a6048fc3f9c43ece54e1321ecdc2cbf/'} className="listqqq" > View All </Link>
                  </div>
                </div>
                {this.context.all_trailorview.map((item, index) => (
                  <div key={item.video_id}>
                    <div className="col-md-6 " style={{ padding: 0 }} >
                      <iframe id="ytplayer" type="text/html" className="videosss2"
                        src={`https://www.youtube.com/embed/` + item.video + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                        frameBorder="0" allowFullScreen></iframe>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="clearfix"></div>
        {/* ------------------------ Featured books ----------------------------------------------*/}
        <div className="secreccc3qqq">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title2 text-center mb-50 section-title--bordered">
                  <div className="col-md-11 " style={{ padding: 0 }} >
                  <h2> Featured Books </h2>
                  </div>
                  <div className="col-md-1 " style={{ padding: 0 }} >
                  <Link to={'/87c28f786a96f6b77a4d25e074942a54/'} className="listqqq" > View All </Link>
                  </div>
                </div>
                <div style={{backgroundColor:'white', width: '100%', float: 'left'}}>
                {this.context.all_featuredviewall.map((item, index) => (
                  <div key={item.books_id}>
                    <div className="col-md-2" style={{ padding: 0 }} >
                    <div className="stansssmmm app">
                    <h4>{item.title}</h4>
                    <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                    <img src={BaseURL + item.bookcover} alt="books" />
                    <div className="hover-btns">
                             View Book  
                            </div>
                    </Link>
                
                    
                      
                      {item.category === 'other' ?
                        <h6>{item.other}</h6>
                        :
                        <h6>{item.category}</h6>
                      }
                      
                      {item.price != 0 ?
                        <p> $ {item.price} </p>
                        :
                       <p> &nbsp;</p>}
                      
                    </div>
                    </div>
                  </div>
                ))}
              </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="clearfix"></div>
        {/* ------------------------ Audio books ----------------------------------------------*/}
        <div className=" audiosssec" >
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="section-title3 text-center mb-50 section-title--bordered">
                 
                  <div className="col-md-9 " style={{ padding: 0 }} >
                  <h2> Audio Books </h2>
                  </div>
                  <div className="col-md-3 " style={{ padding: 0 }} >
                  <Link to={'/cea82ab39bc7e63d6111a9f01129078d/'} className="listqqq" > View All </Link>
                  </div>
                </div>
                {this.context.all_audiobooksall.map((item, index) => (
                  <div key={item.audio_id}>
                    <div className="col-md-4 audiosssbbb" >
                    <Link to={`/1ebfd8784d80307a0b3390fca61fba3b/${item.audio_id}`} className="" >
                      {item.written !== '' ?
                        <h6> By: {item.written}</h6>
                        :
                        <h6> &nbsp; </h6>
                      }
                       <h4>{item.title}</h4>
                      <div className="booksrec4">
                        <img src={BaseURL + item.imagefront} alt="books" />
                        <div className="hover-btns">
                         View Book 
                        </div>
                      </div>
                      <span className="cccgggsss2"> 
                          <b> Length : </b> 
                          {item.hrs == 0  &&  item.mins == 0 ?
                           <span> Not Yet Known </span> 
                        : 
                        <>
                       <span className="price2"> {item.hrs} </span>  hrs and  <span className="price2"> {item.mins} </span> mins
                        
                        
                        </>
                        }
                         
                        </span>   
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-3">
              <div className="section-title3 text-center mb-50 section-title--bordered">
                  <h2> What is New ? </h2>
                </div>
              {this.context.all_booksqqq.map((item, index) => (
                    <div key={item.books_id}>
                      <div className="col-md-12">
                      <div className="latestbooks2qqq">
                      <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                      {item.category === 'other' ?
                              <h6>{item.other}</h6>
                              :
                              <h6>{item.category}</h6>
                            }
                    <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL + item.bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                        <div className="hover-btns">
                             View Book  
                            </div>
                      </article>
                      <p className="book-wrappertitlss2"> {item.title} </p>
                    </section>
                  </Link>
                      </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
        <div className="clearfix"></div>
        {/* ------------------------ Standard books ----------------------------------------------*/}
        <div className="secreccc3 standardsss">
        <div className="bg-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title2 text-center mb-50 section-title--bordered">
                
                  <div className="col-md-11 " style={{ padding: 0 }} >
                  <h2> Books </h2>
                  </div>
                  <div className="col-md-1 " style={{ padding: 0,zIndex: '7' }} >
                  <Link to={'/4d6c54eec0adf53e101d08f85f86ff32/'} className="listqqq" > View All </Link>
                  </div>
                </div>
                {this.context.all_standardlist.map((item, index) => (
                  <div key={item.books_id} >
                    <div className="col-md-4 " style={{ padding: 0 }} >

                      <div className="stansss" >
                      
                        <div className="col-md-6 " style={{ padding: 0 }} >
                        <section className="app" id="app" data-current-media="book">
                          <article className="media-container">
                          <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" >
                            <div className="book-wrapper">
                              <div className="book">
                                <div className="book__front">
                                  <img src={BaseURL + item.bookcover} alt="books" />
                                </div>
                                <div className="book__paper"></div>
                                <div className="book__back"></div>
                              </div>
                              <div className="book-shadow"></div>
                            </div>
                            <div className="hover-btns">View Book  
                          </div>
                            </Link>
                          </article>
                        </section>
                        {item.price != 0 ?
                                    <p> $ {item.price} </p>
                                    :
                                    null}
                        </div>
                        <div className="col-md-6 " style={{ padding: 0 }} >
                        <h4>{item.title}</h4>
                        {item.category === 'other' ?
                          <h6>{item.other}</h6>
                          :
                          <h6>{item.category}</h6>
                        }
                         <div className="standesccc" dangerouslySetInnerHTML={{ __html: item.description }} />
                       
                      </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
 {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
 <div className="clearfix"></div>
{/* ------------------------- Book Trailers  ----------------------------------------------*/}
<div className="secreccc3">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-title2 text-center mb-50 section-title--bordered">
         
          <div className="col-md-11 " style={{ padding: 0 }} >
                  <h2> BOOK TRAILERS </h2>
                  </div>
                  <div className="col-md-1 " style={{ padding: 0 }} >
                  <Link to={'/8a6048fc3f9c43ece54e1321ecdc2cbf/'} className="listqqq" > View All </Link>
                  </div>
        </div>
        {this.context.all_trailorviewbasiclist.map((item, index) => (
          <div key={item.video_id}>
            <div className="col-md-4 " style={{ padding: 0 }} >
              <iframe id="ytplayer" type="text/html" className="videosss2"
                src={`https://www.youtube.com/embed/` + item.video + `?rel=0&showinfo=0&color=white&iv_load_policy=3`}
                frameBorder="0" allowFullScreen></iframe>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>
{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
<div className="clearfix"></div>
        {/* ------------------------ Basic books ----------------------------------------------*/}
        <div className="secreccc3 trailsssecqqq">
          
        <div className="bg-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title2 text-center mb-50 ">
                <div className="col-md-11 " style={{ padding: 0 }} >
                  <h2> Best Books </h2>
                  </div>
                  <div className="col-md-1 " style={{ padding: 0 }} >
                  <Link to={'/4657c4ce07e9a56c07559538097b54a4/'} className="listqqq" > View All </Link>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="section-title--bordered"></div>
               
                {this.context.all_basicbooks.map((item, index) => (
                  <div key={item.books_id} >
                    <div className="col-md-2 " style={{ padding: 0 }} >
                      <div className="stansss2" >
                      {item.category === 'other' ?
                          <h6>{item.other}</h6>
                          :
                          <h6>{item.category}</h6>
                        }
                      <section className="app" id="app" data-current-media="book">
                          <article className="media-container">
                            
                          <Link to={`/3d3f3ef7fc59cb9e12b308a5b1de8cca/${item.books_id}`} className="" > 
                            <div className="book-wrapper">
                              <div className="book">
                                <div className="book__front">
                                  <img src={BaseURL + item.bookcover} alt="books" />
                                </div>
                                <div className="book__paper"></div>
                                <div className="book__back"></div>
                              </div>
                              <div className="book-shadow"></div>
                            </div>
                            <div className="hover-btns">View Book  
                          </div>
                          </Link>
                          </article>
                        </section>
                        <div className="product-detail">  </div>
                        <h4>{item.title}</h4>
                        
                        {item.price != 0 ?
                          <p> $ {item.price} </p>
                          :
                          null}
                         
                         
                       
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
  {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
  <div className="clearfix"></div>
  <div className="aaabbbsss">
          <div className="container">
            <div className="row">
               <div className="col-md-12">
                <div className="wqssingaaaass">
                  
        <div className="bg-overlay"></div>
                  <div className="section-title3 text-center mb--30 section-title--bordered">
                    <h2> About React Books </h2>
                  </div>
                  <p>
                     ReactBooks is the best book marketing website that promote ebooks and paperbacks globally. Provide your book purchase link together with book cover image and book description on this website so that we promote your books worldwide. All our charges for author services are onetime and no recurring charges added to your account. Authors, Publishers, and writers can avail our services for maximum exposure for your books. We promote your books to our vast readership based in all English speaking countries through our social media platforms and email blasts. Youtube book video trailers, audiobooks, publisher profile, and books can be added to our website for promotion. You can reach us at 
                  <a href="mailto:website@wordlikes.com"> info@reactbooks.com </a>
                </p>
                  
                  
                  
                </div>
              </div>
              </div>
              </div>
              </div>
              <div className="clearfix"></div>
      </>
    );
  }
}
export default Package;