
import React, { Component } from 'react';
import {AppContext} from '../Context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Vistiorscount extends Component{

       static contextType = AppContext;
      
  
       componentDidMount(){
              this.context.vistiorsCountsfetch();
              
        }
     
    
    render(){
    
        return(
              
           <>

           {this.context.all_counts.map((item, index) => (
                   <div key={item.react_site_count}>
                   <div className="col-md-4 col-sm-4" >
                          <div className="total">
                              <div className="iconsss">
                                <FontAwesomeIcon icon={['fas','check']} className="iconsss2" />
                              </div>
                            <span>Registerd Users</span>
                            <em>{item.user_count}</em>
                          </div>
                      </div>
                      
                          <div className="col-md-4 col-sm-4">
                            <div className="total">
                              <div className="iconsss">
                                <FontAwesomeIcon icon={['fas','book']} className="iconsss2" />
                              </div>
                            <span>Book Posts</span>
                            <em>{item.Books_count}</em>
                          </div>
                      </div>
                      
                          <div className="col-md-4 col-sm-4">
                            <div className="total">
                              <div className="iconsss">
                                <FontAwesomeIcon icon={['fas','users']} className="iconsss2" />
                              </div>
                            <span> Subscribers (Readers) </span>
                            <em>{item.Vistors_count}</em>
                          </div>
                      </div>
                      </div>

    
            ))}

            </>  
                  
        );
    }
}
export default Vistiorscount;