import React, { Component } from 'react';

import {AppContext} from '../Context';
import Loader from 'react-loader-spinner'
class Changepassword extends Component {
       
    static contextType = AppContext;
    
       changePassword = (event) => {
              this.context.changePassword(event,this.newpassword.value,this.confirmpassword.value,this.props.id);
       }


    render(){
       const {  loadingchangepassword} = this.props;
        return(
              <form onSubmit={this.changePassword} className="c-form">
              <div className="set-title"> <h5>Change Password</h5></div>
              <div>
              <label> New Password </label>
              <input type="password" name="newpassword" ref={(val) => this.newpassword = val} className="form-control" placeholder=" New Password " required />
		</div>
              <div>
              <label> Confirm Password </label>
              <input type="password" name="confirmpassword" ref={(val) => this.confirmpassword = val} className="form-control" placeholder=" Confirm Password " required />
		</div>


            
                  <div className="form-group col-md-12 text-right">
                  {loadingchangepassword ?
                          <>
                                 <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                          </>
                       : 
                      <button type="submit" className="form-button"> Save </button>
                  } 
                  </div>
              
          </form>  
        );
    }
}
export default Changepassword;