import React,{Component} from 'react';
import Loader from 'react-loader-spinner'

import { toast } from "react-toastify";
import Axios from 'axios';

let BaseURL = 'https://reactbooks.com/api/';
class Authordetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
         file: null,
      };
    this.onChange = this.onChange.bind(this);
    this.resetFile = this.resetFile.bind(this);
    }
    //image preview       
    onChange(event) {
        this.setState({
              file: URL.createObjectURL(event.target.files[0])
        });
  }
  resetFile(event) {
         event.preventDefault();
         this.setState({ file: null });
       }  
       componentDidMount(){
        this.authorfetch();
        window.scrollTo(0, 0);
        }    
        authorfetch = () => {
            Axios.post(BaseURL+'authorfetchsss.php',{
                authordetailsId:this.props.match.params.authordetailsId,
            })
            .then(function ({data}) {
                console.log(data.authorsss[0].facebook);
                if(data.success === 1){
                    console.log(data.success);
                    this.setState({ 
                        facebooksss:data.authorsss[0].facebook,
                        twittersss:data.authorsss[0].twitter,
                        linkedinsss:data.authorsss[0].linkedin,
                        authordetailsss:data.authorsss[0].authordetails,
                     });
                }
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        }
       authorbooksAdd = (event) => {
        this.setState({ isLoading: true });
    event.preventDefault();event.persist();
    const formData = new FormData();
    formData.append('authordetailsId',this.props.match.params.authordetailsId)
    formData.append('avatar',this.file.files[0])
    formData.append('facebook',this.facebook.value)
    formData.append('twitter',this.twitter.value)
    formData.append('linkedin',this.linkedin.value)
    formData.append('author',this.author.value)
    return Axios.post(BaseURL+'authordetailsss.php',formData,{
        headers: {'content-type': 'multipart/form-data'}
    })
    .then(res => { 
        console.log(res.data);
        if(res.data === 'EmptyField' ){
            toast.error('Please fill all the required fields!');
            this.setState({ isLoading: false });
        } else if(res.data === 'UploadingField' ){
            toast.error('Sorry, there was an error uploading your file.');
            this.setState({ isLoading: false });
        } else if(res.data === 'Nofilewassent' ){
            toast.error('No file was sent!');
            this.setState({ isLoading: false });
        }else if(res.data === 'Allowed' ){
            toast.error('Sorry, only JPG, JPEG, PNG, and GIF files are allowed.');
            this.setState({ isLoading: false });
        }else if(res.data === 'successfully' ) {
            toast('update successfully')
            this.setState({ isLoading: false });
            event.target.reset();
            this.props.history.push('/')
        }
    })
    .catch(err => { toast.error(err.data);this.setState({ isLoading: false });})
          }
          render(){
              return (
                     <div className=" addbooksss gray-bg ">
                      <div className="container">
                       <div className="row">
                         <div className="col-md-1">&nbsp;</div>
                          <div className="col-md-10">
                              <div className="central-meta3">
                            <h3 className="aunasss" style={{float:'left'}}> Author Information </h3>
                  <form onSubmit={this.authorbooksAdd} className="central-meta">
                  <div className="form-row">
                  <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Author Photo  </label>
                          <input type="file" name="file" ref={(val) => this.file = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                      </div>
                      {this.state.file && (
                        <div style={{ textAlign: "center" }}>
                             <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                            <button onClick={this.resetFile} className="removeqqq">Remove File</button>
                        </div>
                        )}
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Facebook <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="facebook" ref={(val) => this.facebook = val} className="form-control" placeholder="Facebook"  defaultValue={this.state.facebooksss} />
                      </div>
                    <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Twitter <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="twitter" ref={(val) => this.twitter = val} className="form-control" placeholder=" https://twitter.com/name"  defaultValue={this.state.twittersss}/>
                      </div>
                     <div className="form-group col-md-12">
                          <label className="font-weight-bold"> LinkedIn <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="linkedin" ref={(val) => this.linkedin = val} className="form-control" placeholder="LinkedIn"  defaultValue={this.state.linkedinsss}/>
                      </div>
                    <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Author <span className="adverts-form-required"> * </span> </label>
                          <textarea className="form-control" id="author" name="author" rows="10" ref={(val) => this.author = val} required  defaultValue={this.state.authordetailsss}/>
                      </div>
                   {this.state.isLoading  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            :  
                  <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="btn btn-primary2"> Continue </button>
                      </div>
          }
                  </div>
              </form>  
              </div>
                  </div>
                  </div>
                  </div>
                  </div>
              );
          }
      }

export default Authordetails;
