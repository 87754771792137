import React from 'react';

import {AppContext} from '../Context';
class Logout extends React.Component {
    
    static contextType = AppContext;
    
    componentDidMount(){
        localStorage.clear();
        window.location.href = '/a8f2eec89c7b7c73866300abd1ee6bd2';


    }



    render(){
        return(
             null
        );
    }
}
export default Logout;