import React, { Component } from 'react'
import {AppContext} from '../Context';
let BaseURL = 'https://localhost:3000/';
let StripeURL = 'https://reactbooks.com/api/stripe/';

class Bookaddstripe extends Component {
       
    static contextType = AppContext;

    componentDidMount() {
        //window.location.reload();
    }

  render() {
    return (
    
    <div className="col-md-12 gray-bg stripesss">

<div className="col-md-1">&nbsp;</div>
    <div className="col-md-10">
    <div className="central-meta3">



 <form action={StripeURL+"paymentbookadd.php"} method="POST" id="paymentFrm">
			
			
			<input type="hidden" name="itemPrice" id="itemPrice" defaultValue={this.props.match.params.paymentAmount} />
			<input type="hidden" name="itemNumber" id="itemNumber" defaultValue={this.props.match.params.bookId} />
			<input type="hidden" name="itemName" id="itemName" defaultValue={this.props.match.params.title} />
			
            {this.props.match.params.bookadd === 'bookadd' ?
                <input type="hidden" name="payfirst" id="payfirst" defaultValue={this.props.match.params.bookId} />
                    :
                    null
           } 
           {this.props.match.params.pendingbookadd === 'pendingbookadd' ?
                <input type="hidden" name="paysecond" id="paysecond" defaultValue={this.props.match.params.bookId} />
                    :
                    null
           }
			
			<div className="form-group col-md-6">
                 <label className="font-weight-bold" htmlFor="input-firstname"> Name <span className="adverts-form-required"> * </span></label>
                 
                         <input type="text" name="name" id="name" placeholder="Enter name" required autoFocus="" className="form-control" />
                   
            </div>
			
			<div className="form-group col-md-6">
                 <label className="font-weight-bold" htmlFor="input-email"> Email <span className="adverts-form-required"> * </span></label>
                  
                         <input type="email" name="email" id="email" placeholder="Enter email" required autoFocus="" className="form-control" />
                   
            </div>
			
			
			<div className="form-group col-md-6">
                 <label className="font-weight-bold" htmlFor="input-city"> City <span className="adverts-form-required"> * </span></label>
                
                         <input type="text" name="city" id="city" placeholder="City" required autoFocus="" className="form-control" />
                   
            </div>
		    <div className="form-group col-md-6">
                 <label className="font-weight-bold" htmlFor="input-state"> State <span className="adverts-form-required"> * </span></label>
                 
                         <input type="text" name="state" id="state" placeholder="State" required autoFocus="" className="form-control" />
                  
            </div>
		
				<div className="form-group col-md-6">
					<label className="font-weight-bold" htmlFor="input-Country"> Country <span className="adverts-form-required"> * </span></label>
					
				
                           
				<select name="country" id="country" required className="form-control">
					
				
                                  <option value="IN">United States</option>
					<option value="AU">Australia</option>
					<option value="AT">Austria</option>
					<option value="BE">Belgium</option>
					<option value="CA">Canada</option>
					<option value="DK">Denmark</option>
					<option value="EE">Estonia</option>
					<option value="FI">Finland</option>
					<option value="FR">France</option>
					<option value="DE">Germany</option>
					<option value="GR">Greece</option>
					<option value="HK">Hong Kong</option>
					<option value="IE">Ireland</option>
					<option value="IT">Italy</option>
					<option value="JP">Japan</option>
					<option value="LV">Latvia</option>
					<option value="LT">Lithuania</option>
					<option value="LU">Luxembourg</option>
					<option value="MY">Malaysia</option>
					<option value="MX">Mexico</option>
					<option value="NL">Netherlands</option>
					<option value="NZ">New Zealand</option>
					<option value="NO">Norway</option>
					<option value="PL">Poland</option>
					<option value="PT">Portugal</option>
					<option value="SG">Singapore</option>
					<option value="SK">Slovakia</option>
					<option value="SI">Slovenia</option>
					<option value="ES">Spain</option>
					<option value="SE">Sweden</option>
					<option value="CH">Switzerland</option>
					<option value="GB">United Kingdom</option>
					<option value="US">United States</option>
                 </select>
				
				 </div>
				  <div className="form-group col-md-6">
                 <label className="font-weight-bold" htmlFor="input-state"> Postal Code <span className="adverts-form-required"> * </span></label>
                  
                         <input type="text" name="postal_code" id="postal_code" placeholder="postal_code" required autoFocus="" className="form-control" />
                  
                </div>
                <div className="form-group col-md-12">
                 <label className="font-weight-bold" htmlFor="input-Address"> Address <span className="adverts-form-required"> * </span></label>
                 
                         <input type="text" name="address" id="address" placeholder="Address line " required autoFocus="" className="form-control" />
                  
            </div>
			 	
<legend> Onetime Fee: Stripe Credit / Debit Card </legend>				
				
				 <div className="form-group col-md-12">
                 <label className="font-weight-bold" htmlFor="input-state"> Card Number <span className="adverts-form-required"> * </span></label>
                         <input type="text" name="card_number" id="card_number" placeholder="1234 1234 1234 1234" autoComplete="off" required className="form-control" />
                   
                </div>
				 <div className="form-group col-md-12">
				 <div className="col-sm-4">
                 <label className="font-weight-bold" htmlFor="input-state"> Expiry Date <span className="adverts-form-required"> * </span></label>
                  <div className="">
                         <input type="text" name="card_exp_month" id="card_exp_month" placeholder="MM" required className="form-control" />
                    </div>
                </div>
				<div className="col-sm-4">
                 <label className="font-weight-bold" htmlFor="input-state"> Expiry Year <span className="adverts-form-required"> * </span></label>
                  <div className="">
                         <input type="text" name="card_exp_year" id="card_exp_year" placeholder="YYYY" required className="form-control" />
                    </div>
                </div>
				<div className="col-sm-4">
                 <label className="font-weight-bold" htmlFor="input-state"> CVC Code <span className="adverts-form-required"> * </span></label>
                  <div className="">
                         <input type="text" name="card_cvc" id="card_cvc" placeholder="CVC" autoComplete="off" required className="form-control" />
                    </div>
                </div>
                </div>
				
				
					<div className="dividesss"></div>
					<div className="col-md-6">
				<button type="submit" className="btn btn-success" id="payBtn">Submit Payment</button>
				</div>
			<div className="col-md-6">
            {this.props.match.params.bookadd === 'bookadd' ?
              <a href={BaseURL+"Bookaddpreviewsection/"+this.props.match.params.bookId} className="btn btn-successqqq"> Cancel </a>
                    :
                    null
           } 
           {this.props.match.params.pendingbookadd === 'pendingbookadd' ?
                <a href={"Bookaddpreviewsection/"+this.props.match.params.bookId} className="btn btn-successqqq"> Cancel </a>
                    :
                    null
           }
				</div>
			
			</form>


                     <div className="card-errors"></div>

















    </div>
    </div>
    </div>
    
    )
  }
}

export default Bookaddstripe
