import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';

import {Redirect } from "react-router-dom"; 
import { ToastContainer,toast} from "react-toastify";
//include page
import Addbook from '../Components/Addbook';
class Addbooksection extends Actions {
  
       componentDidMount(){
          this.storedata();
          
          window.scrollTo(0, 0);
       }  
       
       
     storedata() {
              let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
              let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
              if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
              this.setState({
                     username: userstoragename,
                     userid: userstorageid,
              })} else { this.setState({ username: '',userid: '', }) }

      }


  render(){
       if (this.state.username === '') {
              return(
                <>
                   {toast("please login to access this page", {
                     position: "top-center" })}
                    <Redirect to='/a8f2eec89c7b7c73866300abd1ee6bd2/' />
                </>
      
              ) 
            }
       const contextValue = {
              booksAdd:this.booksAdd, //insert
              pricefetch:this.fetchprice, //get price list
              all_allprices:this.state.allprices, //get price list store
              
          }
    return (
       <Provider value={contextValue}>
             
              <div className=" addbooksss gray-bg ">
                     <div className="container">
                            <div className="row">
                            <div className="col-md-1">
                                   &nbsp;
                            </div>
                            <div className="col-md-10">
                                   <div className="central-meta3">
                                   <h2 className="aunasss"> Welcome : <span style={{color:'#fa6342'}}>  {this.state.username}  </span> </h2>
                                   <h3 style={{color: '#fd6500'}} > Book Information </h3> 
                                   <Addbook storageid={this.state.userid} loadingadsss ={this.state.isLoading}/>
                                   </div>
                            </div>

                            <div className="col-md-1">
                            &nbsp;
                            </div>
                            </div>
                     </div>
              </div>
       </Provider>       
      
    );
  }
}

export default Addbooksection;