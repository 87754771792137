
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';
//include page
import Userprofile from '../Components/Userprofile';
import Changepassword from '../Components/Changepassword';
import Coupentwo from '../Components/Coupentwo';
import Logout from '../Components/Logout';
import {Redirect, Link } from "react-router-dom"; 

import { ToastContainer,toast} from "react-toastify";
//import Loader from 'react-loader-spinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let BaseURL = 'https://reactbooks.com/api/';
class Myaccountsection extends Actions{

  

       componentDidMount(){
         this.storedata();
         window.scrollTo(0, 0);
       }  
       storedata() {
           let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
           let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
           if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
               this.setState({
                     username: userstoragename,
                     userid: userstorageid,
              })
              this.books(userstorageid);
              this.audiobooks(userstorageid);
              this.bookstrailor(userstorageid);
              this.publisher(userstorageid);
            
            } else { this.setState({ username: '',userid: '', }) }
             
          }

          books = async pageNumber => {
            const response = await fetch(BaseURL+`bookcheckpending.php?page=${pageNumber}`, { method: 'GET', headers: {  'Accept': 'application/json', 'Content-Type': 'application/json',},});
            const res = await response.json();
            if(res === 'Success' ){
              this.setState({ bookspending: true });
          }else if(res === 'Emptybooksss' ){
            this.setState({ bookspending: false });
          }}
          audiobooks = async pageNumber => {
              const response = await fetch(BaseURL+`bookcheckaudiopending.php?page=${pageNumber}`, { method: 'GET', headers: {  'Accept': 'application/json', 'Content-Type': 'application/json',},});
              const res = await response.json();
              if(res === 'Success' ){
                this.setState({ audiopending: true });
            }else if(res === 'Emptybooksss' ){
              this.setState({ audiopending: false });
            }}
          bookstrailor = async pageNumber => {
              const response = await fetch(BaseURL+`bookchecktrailorpending.php?page=${pageNumber}`, { method: 'GET', headers: {  'Accept': 'application/json', 'Content-Type': 'application/json',},});
              const res = await response.json();
              if(res === 'Success' ){
                this.setState({ trailerpending: true });
            }else if(res === 'Emptybooksss' ){
              this.setState({ trailerpending: false });
            }}
          publisher = async pageNumber => {
              const response = await fetch(BaseURL+`bookcheckpublisherpending.php?page=${pageNumber}`, { method: 'GET', headers: {  'Accept': 'application/json', 'Content-Type': 'application/json',},});
              const res = await response.json();
              if(res === 'Success' ){
                this.setState({ publisherpending: true });
            }else if(res === 'Emptybooksss' ){
              this.setState({ publisherpending: false });
            }}

             












          Dashboard =()=>{
            this.setState({ 
             isDashboard: false,
             isReceipts: false,
             isPending: false,
             isPassword: false,
             isLogOut: false,
             isadminpayment: false,
             isadmincoupen: false,
            });
          }
          Receipts =()=>{
            this.setState({ 
             isDashboard: true,
             isReceipts: true,
             isPending:false,
             isPassword: false,
             isLogOut: false,
             isadminpayment: false,
             isadmincoupen: false,
            });
          }
          Pending =()=>{
            this.setState({ 
             isDashboard: true,
             isReceipts: false,
             isPending: true,
             isPassword: false,
             isLogOut: false,
             isadminpayment: false,
             isadmincoupen: false,
            });
          }
          Password =()=>{
            this.setState({ 
             isDashboard: true,
             isReceipts: false,
             isPending: false,
             isPassword: true,
             isLogOut: false,
             isadminpayment: false,
             isadmincoupen: false,
            });
          }
          LogOut =()=>{
            this.setState({ 
             isDashboard: true,
             isReceipts: false,
             isPending: false,
             isPassword: false,
             isLogOut: true,
             isadminpayment: false,
             isadmincoupen: false,
            });
          }
          adminpayment =()=>{
            this.setState({ 
             isDashboard: true,
             isReceipts: false,
             isPending: false,
             isPassword: false,
             isLogOut: false,
             isadminpayment: true,
             isadmincoupen: false,
            });
          }
          admincoupen =()=>{
            this.setState({ 
             isDashboard: true,
             isReceipts: false,
             isPending: false,
             isPassword: false,
             isLogOut: false,
             isadminpayment: false,
             isadmincoupen: true,
            });
          }
          
          IncrementItem = () => {
            this.setState({ clicks: this.state.clicks + 1 });
          }
          DecreaseItem = () => {
            this.setState({ clicks: this.state.clicks - 1 });
          }
          ToggleClick = () => {
            this.setState({ show: !this.state.show });
          }

    render(){
       // const { isLoading} = this.state;
       // if (isLoading) {
       //   return <>
       //   <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} /></>;
       // } 

       if (this.state.username === '') {
        return(
                  <>
                    {toast("please login to access this page", {
                      position: "top-center" })}
                    <Redirect to='/a8f2eec89c7b7c73866300abd1ee6bd2/' />
                    
                  </>
                )
      }
      
        const contextValue = {
              userphotosAdd:this.userphotosAdd,

              all_isLoading:this.state.isLoading,
              all_profileimages:this.state.profileimages,
              profileimagesfetch:this.fetchprofileimages,


              changePassword:this.changePassword,

              CoupencodeAdd:this.CoupencodeAdd,
              Coupencodedelete:this.Coupencodedelete,
              coupenlist:this.fetchcoupenlist,
              all_coupensss:this.state.coulisss,

              
              AmountUpdate:this.AmountUpdate,
              amountlist:this.fetchamountlist,
              all_amoulisss:this.state.amoulisss,

              

        }

       

        return(
            <Provider value={contextValue}>
              
               <ToastContainer />
               <div className="myaccounts gray-bg">
                  <div className="container">
                     <div className="row">
                     <div className="pagsssqqq">
          <div className="container">
             <div className="row">
             <div className="col-md-12">
             <section className="breadcrumb-section">
      <h2 className="sr-only">Site Breadcrumb</h2>
      <div className="container">
        <div className="breadcrumb-contents">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
              <li className="breadcrumb-item active"> My Account </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
    </div>
    </div>
    </div>
    </div>
                       <div className="col-md-9">
                       <div className="central-meta">
                       <div className="col-md-3">
                       <ul className="nav-tabssss">
							<li onClick={this.Dashboard} className={ this.state.isDashboard? "nav-item": "active" } >
                            <FontAwesomeIcon icon={['fas','th-large']} className="iconsss2" /> Dashboard
							</li>
							{/* <li onClick={this.Receipts} className={ this.state.isReceipts? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','file-invoice-dollar']} className="iconsss2" /> Payment Receipts
							</li> */}
							<li onClick={this.Pending} className={ this.state.isPending? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" /> Payment Pending
							</li>
							<li onClick={this.Password} className={ this.state.isPassword? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" />  Change Password
							</li>
                  {this.state.userid === '3' ? 
              <li onClick={this.adminpayment} className={ this.state.isadminpayment ? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" /> Admin Payment
							</li>
              : null }

            {this.state.userid === '3' ? 
              <li onClick={this.admincoupen} className={ this.state.isadmincoupen ? "active": "nav-item" }>
                      <FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" /> Admin Coupen 
							</li>
              : null }


							<li onClick={this.LogOut} className={ this.state.isLogOut? "active": "nav-item" }>
                            <FontAwesomeIcon icon={['fas','sign-out-alt']} className="iconsss2" />  Log Out
							</li>
						</ul>
                        </div>
                        <div className="col-md-9">
                        {this.state.isDashboard !== true ?
                        <>
                            <div className="set-title">
                                <h5> Dashboard </h5>
                                <ul className="acqcss2">
                                    <li className="menu-item mega-menu">
                                        <Link to="/abe35d0f31ec0c0bd7a54de00d236f89" > Post Your Book </Link>
                                        
                                    </li>
                                    <li className="menu-item mega-menu">
                                    <Link to="/e1858682b461934eb88bbbe36f9c9773" > Add Book Trailer </Link>
                                       
                                    </li>
                                    <li className="menu-item mega-menu">
                                    <Link to="/b6e03f328feffa5a0ad181e7eb70e91d/" >   Add Publisher</Link>
                                    </li>
                                    <li className="menu-item mega-menu">
                                    <Link to="/8839644748c0ffe6e3c529161cb6c9f6" > Add Audio Book </Link>
                                       
                                    </li>
                                    </ul>
                                  
                                    
                            </div>
                            </>
                        : null }
                        {this.state.isReceipts === true ?
                            <div className="set-title">
                                <h5> Receipts </h5>
                             </div>
                        : null } 
                       
                        {this.state.isPending === true ?
                            <div className="set-title">
                                <h5> Payment Pending </h5>
                                                                        
                                <ul className="acqcss2">
                                {this.state.bookspending === false && this.state.audiopending === false && this.state.trailerpending === false && this.state.publisherpending === false ? 
                                 <h3 style={{color: 'darkgrey'}}> No Pending Payment</h3>
                                :
                                 null

                                 }
                                

                                    {this.state.bookspending ?
                                    <li className="menu-item mega-menu">
                                    <Link to="/0498ac26858e7605b2b05a27822d8dc7" > Book Payment Pending </Link>
                                    </li>
                                    : null }

                                     {this.state.audiopending ?                    
                                    <li className="menu-item mega-menu">
                                    <Link to="/a1cdcf914b45e998ca40eb06e936d209" > Audio Book Payment Pending </Link>
                                    </li>
                                    : null }
                              
                                {this.state.trailerpending ?               
                                    <li className="menu-item mega-menu">
                                    <Link to="/e03d69e8150cdc8be84ef2a84a3c4787" > Book Trailer Payment Pending </Link>
                                    </li>
                                    : null }
                                    {this.state.publisherpending ?                
                                    <li className="menu-item mega-menu">
                                    <Link to="/dda076c4518609b5ea9f8218bf3dbf0a" > Publisher Payment Pending </Link>
                                    </li>
                                    : null }
								</ul>
                             </div>
                        : null }


                        {this.state.isPassword === true ?
                          <Changepassword  id={this.state.userid} loadingchangepassword ={this.state.isLoading}/>
                          
                        : null }
                        {this.state.isLogOut === true ?
                          <Logout />
                        : null }
                      {this.state.isadminpayment === true ?
                          <div className="set-title">
                          <h5> Payment Pending </h5>
                                                                  
                          <ul className="acqcss2">
                          
                            
                              <li className="menu-item mega-menu">
                              <Link to="/2f15e32e91b58dcc91b4a803d130bc71" > Book Payment Pending </Link>
                              </li>
                             

                                               
                              <li className="menu-item mega-menu">
                              <Link to="/c8aa8806f7c39bf71ca89a2b3810671b" > Audio Book Payment Pending </Link>
                              </li>
                              
                        
                                       
                              <li className="menu-item mega-menu">
                              <Link to="/dca076032375d968e122ba528a2ffe64" > Book Trailer Payment Pending </Link>
                              </li>
                             
                                        
                              <li className="menu-item mega-menu">
                              <Link to="/a00da0a6babd20eb235359d1a71b9c25" > Publisher Payment Pending </Link>
                              </li>
                              
          </ul>
                       </div>

                        : null }


                   {this.state.isadmincoupen === true ?
                          <div className="set-title">
                          <h5> Coupen </h5>

                          <Coupentwo loadingcoupen ={this.state.isLoading}  />

                          



                          </div>

                        : null }











                        </div>


											
											
											


                       </div>
                       </div>
                     

                       <div className="col-md-3">
                       {/* {this.state.isLoading === true ?
                            <>
                                   <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
                            </>
                         : 
                              <Userprofile  loading ={this.state.isLoading}/> 
                      }  */}
                      <Userprofile  loadingprofile ={this.state.isLoading} /> 
                                     
                      </div>



                     </div>
                  </div>
            </div>
            </Provider>
        );
    }
}
export default Myaccountsection;