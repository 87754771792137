import React, { Component } from 'react';

import { Link } from "react-router-dom";

class Cancel extends Component {
  




  render(){
    return (
      <div className="successs">
      <div className="container">
        <div className="row">
           <div className="col-md-12">
    <div className="container-fluid">
             <h3>Your PayPal Transaction Has Been Canceled.</h3>
             <Link to={`/2383afa57c522277b6e31e96849610d5`} className="" > Payment Again </Link>
             </div>
              </div>
              </div>
              </div>
              </div>
      
    );
  }
}

export default Cancel;