// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';

import {Link } from "react-router-dom"; 


import Authorpackagetwo from '../Components/Authorpackagetwo';

class Authorpackage extends Actions{
       componentDidMount(){
              this.storedata();
              
          window.scrollTo(0, 0);
            }  
            storedata() {
                let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
                let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
                if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
                    this.setState({
                          username: userstoragename,
                          userid: userstorageid,
                   })} else { this.setState({ username: '',userid: '', }) }
                  
               }


            
              
    render(){
        const contextValue = {
              packPreviewsauthor:this.fetchpackPreviewsauthor, 
             all_books:this.state.packPreviewlistauthor,
             packPreviewsauthorvideo:this.fetchpackPreviewsvideo, 
             all_video:this.state.packbookslistvideo, 
        }

        return(
            <Provider value={contextValue}>
                
            <div className="addbookssspacks gray-bg ">
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">
                              <div className="pagsssqqq">
          <div className="container">
             <div className="row">

             <section className="breadcrumb-section">
      <h2 className="sr-only">Site Breadcrumb</h2>
      <div className="container">
        <div className="breadcrumb-contents">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"> <Link to={'/'} className="" >Home</Link></li>
              <li className="breadcrumb-item active"> AUTHOR BEST SELLING </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
    </div>
    </div>
    </div>  
                              
                      <Authorpackagetwo storageid ={this.state.userid} id={this.props.match.params.AuthorpackageId}  usernames={this.state.username} profileimages={this.state.profileimage}  useridsss={this.state.userids} bookspacksidsss={this.state.bookspacksid} />
                                  
                      
                              
                             </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Authorpackage;