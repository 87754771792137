
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';
//include page
import Loginpage from '../Components/Loginpage';
import Forgetpassword from '../Components/Forgetpassword';
import Registerpage from '../Components/Registerpage';
import Recentlogin from '../Components/Recentlogin';
import Vistiorscount from '../Components/Vistiorscount';
import {Redirect } from "react-router-dom"; 
import { ToastContainer,toast} from "react-toastify";
import './login.css';

//import bgimage from '../Img/bg-image.jpg';

class Loginsection extends Actions{

  componentDidMount(){
    this.storedata();
    window.scrollTo(0, 0);
  }  
  storedata() {
      let userstoragename = JSON.parse(localStorage.getItem('tockenusername'));
      let userstorageid = JSON.parse(localStorage.getItem('tockenid'));
      if (localStorage.getItem('tockenusername') &&  localStorage.getItem('tockenid')) {
          this.setState({
                username: userstoragename,
                userid: userstorageid,
         })} else { this.setState({ username: '',userid: '', }) }
        
     }

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //       isLogin: true,
    //       isRegister: false,
    //       isForget: false,
          
    //     }
    //   }
     


 RegisterMode = () => {
        this.setState({ 
          //isLogin: false,
          isLogin: true,
          isRegister: true,
          isForget: false, 
      
        });
      }
    
LoginMode = () => {
    this.setState({ 
      //isLogin: true,
      isLogin: false,
      isRegister: false,
      isForget: false, 
    });
  
  }
  ForgetMode = () => {
    this.setState({ 
      //isLogin: false,
      isLogin: true,
      isRegister: false,
      isForget: true, 
    });
  
  }  


    render(){

      if (this.state.username) {
        return(
          <>
             {toast("please logout to access this page", {
               position: "top-center" })}
             <Redirect to='/2383afa57c522277b6e31e96849610d5/' />
          </>

        ) 
      }

        const contextValue = {
            Register:this.Register,
            userLogin:this.userLogin,
            ForgetMail:this.ForgetMail,
            recentLogin:this.recentLogin,

          all_counts:this.state.counts,
          autoInsertdata:this.autoInsertdata,
          vistiorsCountsfetch:this.fetchVistiorsCounts,

          all_recentLogin:this.state.recentAuthorLogin,
          recentloginfetch:this.fetchrecentlogin,
          
        }
        return(
            <Provider value={contextValue}>
            {/* <div class="login-1" styles={{ backgroundImage:`url(${bgimage})` }}> */}
            <ToastContainer />
          
               <div className="login-1">
                  <div className="container">
                     <div className="row">
                       <div className="col-md-6 logsss2">

                         <Recentlogin Registermodechildsss={this.RegisterMode } />
                    
                       </div>
                       <div className="col-md-6 logsss3">
                          

                          {/* Login Mode */}
                          {this.state.isLogin !== true ?
                              <>
                              <Loginpage loadinglogin ={this.state.isLoading}/>
                                <h6 onClick={this.ForgetMode}> Forgot Password </h6>
                                <h6 onClick={this.RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Create an account </span>  </h6>
                             </>
                            : null }

                            {/* Register Mode */}
                              {this.state.isRegister === true ?
                                <>
                                <Registerpage loadingregister ={this.state.isLoading}/>
                                  <h6 onClick={this.LoginMode}> Already have an Account ? <span style={{color:'#fa6342'}}> Login here </span></h6>
                                  </>
                              : null }

                              {/* Forget Password */}
                              {this.state.isForget === true ? 
                              <>
                              <Forgetpassword loadingadsss ={this.state.isLoading} />  
                              <h6 onClick={this.RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Create an account </span>  </h6>
                              </>
                              : null  }



                       </div>

                       <div className="col-md-12 logsss">
                         <Vistiorscount />
                         
                      
								</div>



                     </div>
                  </div>
            </div>
            </Provider>
        );
    }
}
export default Loginsection;