
import React,{Component} from 'react';
import {AppContext} from '../Context';

import Viewpackedit from '../Components/Viewpackedit';

import ContentLoader from "react-content-loader";
import Loader from 'react-loader-spinner'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import userphotos from '../Img/sss.png';

let BaseURL = 'https://reactbooks.com/api/';

class Packedit extends Component {
    static contextType = AppContext;

    constructor(props) {
       super(props);
       this.state = {
          
            isEditing:false,
            addClass: false,
         };
         
     }

      componentDidMount(){
          this.packPreviews();
          
    }
    packPreviews = () => {
        this.context.packPreviews(this.props.id);
    }

    toggle() {
        this.setState({addClass: !this.state.addClass});
      }



    render(){

        let boxClass = ["redmoesss"];
        if(this.state.addClass) {
          boxClass.push('redmoesssqqq');
        }

        let allUsers;
        let mainData;
      
       
        allUsers = this.context.all_books.map(({bookspacks_id,books_id,userid,title,bookcover,description,price,purchaselink,category,other,websitelink,user_name,profile_image,isEditing}) => {
            return isEditing === true ? (
                   <div key={books_id}>
                        
                           <div className="form-row">
                             <Viewpackedit desc={description} bookspacks_id={bookspacks_id} books_id={books_id} title={title} bookcover={bookcover} price={price} purchaselink={purchaselink} category={category} other={other} websitelink={websitelink} loadingsssedits ={this.props.loadingsss}/>
                            
             {/* {this.props.desc} */}
             </div>
 
            </div>
            ) :  (
               
                <div key={bookspacks_id}>
                <div className="col-md-12 newsssaddbooksss">

                <div className="central-metazzzmmm">

    {/* ------------------------------ Loader start ----------------------------------------------------- */}
    
                    {this.props.loadingsss ? 
                    <><Loader type="TailSpin" color="#fd6500" height={67} width={67} style={{textAlign:"center"}} /></>
                    : 
                    <>
                    {this.props.storageid === userid ?
               <>
                  <button className="zzz" onClick={() => this.context.editpackpreview(bookspacks_id)}>Edit</button>
                </>
                  : null
             }
                      
                          <h2 className="create-post">{title}</h2>




                          <div className="col-md-3">
                          <section className="app" id="app" data-current-media="book">
                      <article className="media-container">
                        <div className="book-wrapper">
                          <div className="book">
                            <div className="book__front">
                              <img src={BaseURL+bookcover} alt="books" />
                            </div>
                            <div className="book__paper"></div>
                            <div className="book__back"></div>
                          </div>
                          <div className="book-shadow"></div>
                        </div>
                       
                      </article>
                    
                    </section>
                         
                          </div>
                          <div className="col-md-9">
                          <div className="col-md-9" style={{padding:'0px'}} >

                         <div className="zzz2">
                          <h3> <FontAwesomeIcon icon={['fas','tags']} className="iconsss2" />  Category : <span>
                             {category === 'other' ?
                                <>{other}</>
                              :  <>{category}  </>}
                               </span></h3>
                               <hr />
                          <h3> <FontAwesomeIcon icon={['fas','money-bill']} className="iconsss2" />  Price : <span style={{color:"#ff4242"}} > {price}</span></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','link']} className="iconsss2" />  Book Purchase Link : <a href={purchaselink} rel="noopener noreferrer" target="_blank"> {purchaselink} </a></h3>
                          <hr />
                          <h3> <FontAwesomeIcon icon={['fas','globe-americas']} className="iconsss2" />    Website Link  : <a href={websitelink} rel="noopener noreferrer" target="_blank"> {websitelink}</a></h3>

                          </div>
                          </div>


                          <div className="col-md-3" style={{padding:'0px'}}>
                                <div className="yyyview">
                                {profile_image === '' ?
                                <img src={userphotos} alt="books" />
                                :
                                <img src={BaseURL + profile_image} alt="" />
                                }
                                <h3> {user_name} </h3>
                                </div>
                            </div>









                          </div>






                          <div className="col-md-12">
                          <div  className={boxClass.join(' ')}>
		                  {/* {descriptionviews} */}
                          <div dangerouslySetInnerHTML={{ __html: description }} />
                            </div>
                            {this.state.addClass ? 
                             <p className="redmoesssmmm" onClick={this.toggle.bind(this)}> Hide </p>
                             :
                             <p className="redmoesssmmm" onClick={this.toggle.bind(this)}>  More </p>
                             }
                            </div>



                                </>
                             }
                         
     {/* ------------------------------- Loader End ----------------------------------------------------- */}
                        
                        </div>
                        </div>
                        
                
                         </div>
                        
          
            );
        });

        if(this.context.all_books.length > 0){
            mainData = (
              <div>
                      {allUsers}
              </div>
                   
            );
        }
        else{
            mainData = (
                <div className="alert alert-light" role="alert">
                    <h4 className="alert-heading"> Please Wait </h4>
                    <hr/>
                    <ContentLoader  height={260} width={400} speed={2} primarycolor="#f3f3f3" secondarycolor="#ecebeb"  >
                            <rect x="0" y="13" rx="4" ry="4" width="400" height="9" />
                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                            <rect x="0" y="50" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="65" rx="4" ry="4" width="400" height="10" />
                            <rect x="0" y="79" rx="4" ry="4" width="100" height="10" />
                            <rect x="0" y="99" rx="5" ry="5" width="400" height="300" />
                        </ContentLoader>
                </div>
            );
        }
        return (
            < >
           
                
                {mainData}
      
     
      
            
        </>  
        );
    }
}
export default Packedit;