import React, { Component } from 'react'
import { EditorState,  convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
import {AppContext} from '../Context';


import Loader from 'react-loader-spinner'
class Publishereditpreviewall extends Component {
       
    static contextType = AppContext;

  constructor(props) {
    super(props)
    this.state = {
       file: null,
       showResults: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.desc)
        )
      ),
    }
    this.onChange = this.onChange.bind(this);
       this.resetFile = this.resetFile.bind(this);
  }

//image preview       
onChange(event) {
       this.setState({
             file: URL.createObjectURL(event.target.files[0])
       });
 }
 resetFile(event) {
        event.preventDefault();
        this.setState({ file: null });
      }
 
  //text editior
    onEditorStateChange = (editorState) => {
      this.setState({
        editorState
      });
    }
 
  

    previewpublishereditlist = (books_id) => {
        this.context.previewpublishereditlist(books_id,this.title.value,this.bookcover.files[0],this.description.value,this.email.value,this.phone.value,this.websitelink.value);
   }

  render() {
       const { editorState } = this.state;
    return (
    
    <div className="col-md-12">
      <div className="col-md-1">&nbsp;</div>
    <div className="col-md-10">
    <div className="central-meta3">

                    <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Publisher Title <span className="adverts-form-required"> * </span> </label>
                     <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Publisher Title" required defaultValue={this.props.title}/>
                 </div>
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Publisher logo or image  </label>
                     <input type="file" name="file" ref={(val) => this.bookcover = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                 </div>
                 {this.state.file && (
                   <div style={{ textAlign: "center" }}>
                        <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                       <button onClick={this.resetFile}>Remove File</button>
                   </div>
                   ) }
                    
                   <div className="form-group col-md-12 editor">
                   <label className="font-weight-bold"> About publisher <span className="adverts-form-required"> * </span> </label>
                       <Editor
                           editorState={editorState}
                           toolbarClassName="toolbarClassName"
                           wrapperClassName="wrapperClassName"
                           editorClassName="editorClassName"
                           onEditorStateChange={this.onEditorStateChange}
                           defaultEditorState={editorState}
                           
                       />
                      <textarea style={{display:'none'}}
     disabled ref={(val) => this.description = val}
     value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
   />
                   </div> 
 
                   <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher Email <span className="adverts-form-required"> * </span>  </label>
                          <input type="text" name="email" ref={(val) => this.email = val} className="form-control" placeholder=" Publisher Email " required defaultValue={this.props.email} />
                      </div>
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Publisher phone(optional) </label>
                          <input type="text" name="phone" ref={(val) => this.phone = val} className="form-control" placeholder="Book Purchase Link" defaultValue={this.props.phone} />
                      </div>
               
                 <div className="form-group col-md-12">
                     <label className="font-weight-bold"> Author Website  </label>
                     <input type="text" name="author" ref={(val) => this.websitelink = val} className="form-control" placeholder="Author Website" defaultValue={this.props.websitelink}/>
                 </div>     
               







                
          {this.props.loadingsssedits  ? 
            <><Loader type="ThreeDots" className="loaderbuttonsss2"/> </>
            : 
                 <div className="form-group col-sm-12 text-right">
                     <button type="submit" className="btn btn-primary2" onClick={() => this.previewpublishereditlist(this.props.books_id)}> Continue </button>
                     <button onClick={() => this.context.cancelPublisherEdit(this.props.books_id)} className="btn btn-light canqqq">Cancel</button>
                 </div>

                }


    </div>
    </div>
    </div>
    
    )
  }
}

export default Publishereditpreviewall
