// this component for about page
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';


import Trailorpreviewpage from '../Components/Trailorpreviewpage';

class Trailorpreviewsection extends Actions{

    
    render(){
        const contextValue = {
              viewtrailorPreview:this.viewtrailorPreview, //perms select value from database
             all_trailors:this.state.trailorAddPreview, // get data 

             editModetrailerpreview:this.editModetrailerpreview,
             previewtrailerUpdate:this.previewtrailerUpdate,
             coupenActivatedtrailor:this.coupenActivatedtrailor,
             cancelTrailorEdit:this.cancelTrailorEdit,

             
             pricefetch:this.fetchprice,
             all_allprices:this.state.allprices,
        }

        return(
            <Provider value={contextValue}>
            <div className="addbooksss gray-bg ">
                <div className="container">
                     <div className="row">
     
                              <div className="col-md-12">
              <Trailorpreviewpage id={this.props.match.params.trailorPreviewId}  loadingsss ={this.state.isLoading} loadingedits ={this.state.isLoading} />
                                  
                        
                             </div>
            </div>
            </div>
            </div>
            </Provider>
        );
    }
}
export default Trailorpreviewsection;